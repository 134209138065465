import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  Typography, Button,
  FormGroup, FormHelperText,
  TextField,
  withStyles,
} from '@material-ui/core';
import { Add, GroupAdd } from '@material-ui/icons';
import { ProgressBar } from '../ProgressBar';
import {postContactsToLists} from '../../lib/api';
import { AdminCheckbox } from '../AdminCheckbox';
import { AddContactDialog } from '../AddContactDialog';
import useContacts from '../../lib/hooks/useContacts';
import useContactLists from "../../lib/hooks/useContactLists";
import AddContactListDialog from "../AddContactListDialog/AddContactListDialog";

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    },
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addcontactList:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    width:214,
    height:50,
    marginTop:-25,
    marginRight:25,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.light,
    }
  },
  shareSwitch:{
    color: theme.palette.primary.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
  listBorder:{
    borderWidth:0,
    borderBottomWidth:2,
    borderStyle:'solid'
  }
}));

const AddContactsToListsDialog = ({ forceOpen = false, setForceOpen, contact, refetch, size, invisible, viewingContact = undefined, viewingList = undefined}) => {
  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const [listIds, setListIds] = useState([]);
  const [open, setOpen] = useState(invisible);
  
  const {isLoading, isRefetching, data, refetch:refetchInternal } = useContacts(viewingList, true);
  const {isLoading:isLoadingLists, isRefetching:isRefetchingLists, data:dataLists, refetch:refetchInternalLists } = useContactLists();
  const contactListData = useMemo(()=>dataLists && !dataLists.error ? dataLists : [],[dataLists]);
  const tableData = useMemo(()=>
     viewingContact ?
        contactListData && !contactListData.error ? contactListData : [] :
        data && !data.error ? data : []
     ,[data, contactListData, viewingContact]);
  
  useEffect(()=>{
    setOpen(forceOpen)
  },[forceOpen])
  
  const handleToggleOpen = useCallback(() => {
    setOpen(!open);
    if(forceOpen && open){
      setForceOpen(false)
    }
  },[open,forceOpen]);
  
  useEffect(()=>{
    if(listIds === undefined && tableData && tableData.metric && tableData.metric.value){
      setListIds(tableData.metric.value.map((item)=>item.id))
    }
  },[tableData, listIds]);
  
  const handleChange = useCallback(event => {
      const changingId = event.target.name;
    if(changingId){
      //alert(event.target.checked ? [...listIds, changingId] : 'no')
      setListIds(event.target.checked ? [...listIds, changingId] : listIds.filter(item=>item !== changingId))
    }
  },[listIds]);
  
  const handleSave = useCallback(() => {
    if(listIds){
      setSaving(true);
      postContactsToLists(viewingContact ? listIds : [viewingList], viewingContact ? [viewingContact] : listIds).then(()=>{
        setSaving(false);
        refetchInternal();
        if(refetch !== undefined){
          refetch();
        }
        handleToggleOpen();
      })
    }
  },[listIds, viewingContact, viewingList]);
  
  return (
     <>
       {!invisible && <Button style={{ height:size === 'small' ? 40 : 50}} variant={'contained'} color={'primary'}
                              onClick={handleToggleOpen} disabled={open || saving}>
         {size != 'text' && <Add/>} {size == 'text' && <Typography>{'Add to List'}</Typography>}
       </Button>}
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        open={open}
        onClose={handleToggleOpen}
        aria-labelledby="add-contacts-to-lists-dialog" className={classes.form}
      >
        <DialogTitle id="add-contacts-to-lists-dialog-title"><GroupAdd/> {viewingContact ? 'Add Contact to Lists' : 'Choose Contacts to Add to this List'} </DialogTitle>
        <DialogContent>
          {
            saving || isLoading || isLoadingLists ?
              <ProgressBar style={{margin:'50px auto 50px'}}/>
              :
              <>
                <DialogContentText>
                  Organize your Contacts into Lists to easily drop them into Campaigns.
                </DialogContentText>
                <form noValidate>
                  <FormGroup style={{margin:'20px auto 10px', width:'90%', maxHeight:320, overflowY:'auto', display:'grid'}}>
                    {tableData && tableData.map((list, index) => {
                      return (
                      <FormControlLabel className={classes.listBorder}
                                        control={<AdminCheckbox checked={listIds && listIds.includes(list.id)} onChange={handleChange} name={String(list.id)} />}
                                        label={viewingContact ?
                                           <>
                                             {(list.list_name ?? '')}
                                        </> : <>
                                          {(list.first_name ?? '')} {(list.last_name ?? '')}
                                          <br/>
                                          {(list.email ?? '')}
                                          {(list.email !== null && list.phone_number !== null && ' | ')}
                                          {(list.phone_number !== null ? list.phone_number : '')}
                                        </>}
                                        key={index}
                      />
                      )
                    })
                    }
                  </FormGroup>
                  {viewingContact ? <AddContactListDialog size={'small'} refetch={refetchInternal} title={''}/> : <AddContactDialog size={'small'} refetch={refetchInternal} title={''}/>}
                  <FormHelperText style={{marginTop:5,textAlign:'center'}}>Not seeing a {viewingContact ? 'List' : 'Contact'}? Add One.</FormHelperText>
                </form>
              </>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleOpen}
                  className={classes.cancelButton}
                  disabled={saving}>
            Cancel
          </Button>
          <Button onClick={handleSave}
                  className={classes.addButton}
                  disabled={saving}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default AddContactsToListsDialog;
