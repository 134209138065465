import React, { useCallback, useContext, useMemo } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
  Button, Divider,
  Grid, Paper, Slider, Typography,
} from '@material-ui/core';
import { AdminButton } from '../../../components/AdminButton';
import { toCapitalize } from '../../../lib/helpers/helpers';
import { BillingContext } from '../../../lib/contexts/BillingContext';
import { postCheckoutFlow } from '../../../lib/api';
import { CreditCardDialog } from './index';

const useStyles = makeStyles(theme => ({
  container:{
    margin:10
  },
  patientChartCard: {
    ...theme.globalClasses.patientChartCard,
    '& p':{
      marginTop:10,
    }
  },
  title:{
    fontSize:'24px !important',
    textAlign: 'center',
    margin: 'auto'
  },
  list:{
    borderStyle:'solid',
    borderWidth:2,
    borderColor:theme.palette.admin.main
  },
  listItemAvatar:{
    display:'block',
    borderRadius:6,
    width:'25px',
    height:25,
    backgroundColor:theme.palette.common.increase,
    marginRight:15,
    '& svg':{
      fill:theme.palette.admin.main
    }
  },
  listItemAvatarDecrease:{
    display:'block',
    borderRadius:6,
    width:'25px',
    height:25,
    backgroundColor:theme.palette.common.decrease,
    marginRight:15,
    '& svg':{
      fill:theme.palette.error.dark
    }
  },
  listItem:{
    margin:0,
    '& span':{
      margin:'0px !important',
      color:theme.palette.admin.main,
      fontSize:14,
    },
    '& p':{
      margin:'0px !important',
      color:theme.typography.helper.main,
      fontSize:'12px !important',
    }
  },
  listItemSecondary:{
  '& svg':{
    fill:theme.typography.helper.light,
  }
  },
  itemBorder:{
    paddingTop:10,
    paddingBottom:10,
    borderWidth:'0px 1px 0px 0px',
    borderStyle:'solid',
    borderColor:theme.palette.admin.background
  },
  item:{
    paddingLeft:15,
    paddingRight:15,
    textAlign:'center',
    '& h6':{
      textTransform:'uppercase',
    },
    '& p':{
      fontSize:14,
      marginBottom:50,
    }
  },
  adminSeatSlider:{
    marginTop:20
  },
  priceColumn:{
    textAlign: 'right',
    '& p:nth-of-type(1)':{
     marginTop:-20
    }
  }
}));

const PaymentOverview = () => {
  const classes = useStyles();
  const {
    state: { chosenPlan, chosenSchedule, adminSeats, planPrice, planDesc, pricePerAdminSeat },
    actions: { setAdminSeats },
  } = useContext(BillingContext);
  
  const handleCheckout = useCallback(()=>{
    if (chosenPlan == 'enterprise' || 1==1){ // temp until checkout resolved
      window.open('tel:8556108753', '_self')
    }else{
      const result = postCheckoutFlow();
    }
  },[chosenPlan]);
  
  const price = useMemo(()=> chosenPlan === 'enterprise' ? 'TBD' : "$" + planPrice,[chosenPlan, planPrice]);
  
  return (
    <Grid item xs={4}>
      <Paper className={classes.patientChartCard}>
        <Grid item container xs={12} justifyContent={'space-around'} >
          <Typography variant={'h1'} className={classes.title}>Payment Overview</Typography>
          <Grid item container xs={12} justifyContent={'space-between'} spacing={1}>
          <Grid item xs={12} container justifyContent={'space-between'} spacing={1}>
          <Grid item xs={9}>
            <Typography variant={'h2'}>Plan: {toCapitalize(chosenPlan)}</Typography>
            <Typography variant={'body2'}>{planDesc}</Typography>
          </Grid>
            <Grid item xs={3} className={classes.priceColumn}>
              <Typography variant={'h2'}>{price}</Typography>
              <Typography variant={'body2'}>per {chosenSchedule}</Typography>
            </Grid>
          </Grid>
  
            {chosenPlan !== 'enterprise' && <Grid item xs={12} container justifyContent={'space-around'} alignItems={'flex-end'} spacing={1}>
              <Grid item xs={12}>
                <Typography variant={'h2'}>Admin Seats:</Typography>
              </Grid>
              <Grid item xs={10}>
                <Slider
                  className={classes.adminSeatSlider}
                  onChange={(e, v) => setAdminSeats(v)}
                  aria-label='Number of Admin Seats'
                  defaultValue={1}
                  step={1}
                  min={1}
                  max={100}
                  valueLabelDisplay='on'
                />
              </Grid>
            </Grid>}
            
<Divider/>
  
            {chosenPlan !== 'enterprise' && <Grid item xs={12} container justifyContent={'space-between'} spacing={1}>
              <Grid item xs={9}>
                <Typography variant={'h2'}>Total Cost:</Typography>
              </Grid>
              <Grid item xs={3} className={classes.priceColumn}>
                <Typography
                  variant={'h2'}>{(adminSeats > 1 ? price + (pricePerAdminSeat * (adminSeats - 1)) : price).toLocaleString()}</Typography>
                <Typography variant={'body2'}>per {chosenSchedule}</Typography>
              </Grid>
            </Grid>}
            
              <Grid item xs={12} container>
                <CreditCardDialog/>
              </Grid>
              <Grid item xs={12}>
                <Button style={{marginBottom:20, display:'none'}} fullWidth>Cancel</Button>
              </Grid>
            
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default PaymentOverview;
