import React, { useContext, useMemo } from 'react';

import { Grid, Typography, ImageList } from "@material-ui/core";
import { Screen, TemplateListing } from '../../components';
import { makeStyles } from "@material-ui/core/styles";

import { UserContext } from '../../lib/contexts';
import useTemplates from '../../lib/hooks/useTemplates.js';
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    '& h2:nth-of-type(1)':{
      marginTop:20,
    },
  },
  imageList:{
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflowY: 'auto',
    maxHeight: 570,
  }
}));

const TemplatesScreen = () => {
  const classes = useStyles();
  const {
    state: { first_name, user_id },
  } = useContext(UserContext)
  const { isLoading, data, refetch } = useTemplates();
  const tableData = useMemo(()=>data && !data.error ? data : [],[data]);
  const userTemplates = tableData ? tableData.filter(item=>item.creator === user_id && !item.public) : [];
  const publicTemplates = tableData ? tableData.filter(item=>item.public) : [];
  const premiumTemplates = publicTemplates ? publicTemplates.filter(item=>!item.basic).sort((a, b) => {
    return a.name < b.name
  }) : [];
  const basicTemplates = publicTemplates ? publicTemplates.filter(item=>item.basic).sort((a, b) => {
    return a.name > b.name
  }) : [];
  
  return (
    <Screen title={'SMS & Email Templates | SendBoth'} showTopNav={true} showFooter={true}>
          <Grid
            container item
            alignItems="center"
            spacing={0}
            className={classes.screen}
          >
            <Typography variant={'subtitle1'}>
              SMS & Email Templates
            </Typography>
            <Grid
              container item
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={0}
              className={classes.container}
            >
              {userTemplates && <>
              <Typography variant={'h2'} color={'primary'}>
                Your Templates
              </Typography>
              <Grid
                container item
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={0}
                className={classes.imageList}>
                {userTemplates.map((item, index)=>{
                  return <TemplateListing item={item} key={index}/>
                })}
              </Grid>
              </>}
              <Typography variant={'h2'} color={'primary'}>
                Browse All Templates
              </Typography>
              <Typography variant={'h5'} color={'primary'}>
                Premium Templates
              </Typography>
              <Grid
                container item
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0} className={classes.imageList}>
                {premiumTemplates.map((item, index)=>{
                  return <TemplateListing item={item} key={index}/>
                })}
              </Grid>
              <Typography variant={'h5'} color={'primary'}>
                Basic Templates
              </Typography>
              <Grid
                container item
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0} className={classes.imageList}>
                {basicTemplates.map((item, index)=>{
                  return <TemplateListing item={item} key={index}/>
                })}
              </Grid>
              
            </Grid>
          </Grid>
    </Screen>
  );
};
export default TemplatesScreen;
