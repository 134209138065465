import React, { useContext, useCallback, useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router';

import {Box, Button, Paper, Grid, Typography, TextField} from "@material-ui/core";
import { API_HOST } from '../../../lib/constants/constants';
import { Screen, ProgressBar } from '../../../components';
import { TEMPLATES_PAGE_ROUTE } from '../../../lib/constants/routes';
import { makeStyles } from "@material-ui/core/styles";
import 'grapesjs-preset-newsletter';
import { GrapesjsReact } from 'grapesjs-react';
import 'grapesjs/dist/css/grapes.min.css';
import { UserContext } from '../../../lib/contexts';
import useTemplates from '../../../lib/hooks/useTemplates';
import {duplicateTemplate, updateTemplate} from '../../../lib/api';
import {useNavigate} from "react-router-dom";
import useMergeTags from "../../../lib/hooks/useMergeTags";

const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'30px auto auto',
    padding:20,
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
  },
  subtitle1:{
    marginBottom:20
  },
  editor:{
    marginTop:20
  },
}));

const EditTemplate = () => {
  const classes = useStyles();
  const {templateId} = useParams();
  const navigate = useNavigate();
  const { isLoading, data, refetch } = useTemplates(templateId);
  const { isLoading:isLoadingMergeTags, data:dataMergeTags, refetch:refetchMergeTags } = useMergeTags(templateId);
  const templateSlug = useMemo(()=>`SB_template_${templateId}`, [templateId]);
  const currentTemplate = useMemo(()=>data && !data.error ? data : [],[data]);
  const currentTemplateUnescaped = useMemo(()=> data && !data.error ? JSON.parse(String(data.raw_template).replace(/&quot;/g, '"')) : [],[data]);
//  const [currentTemplateUnescaped, setCurrentTemplateUnescaped] = useState(null);
  //const templateEndpoint = useMemo(()=>`${API_HOST}/templates/${templateId}`, [API_HOST, templateId]);
  
  useEffect(()=>{
   // setCurrentTemplateUnescaped(data && !data.error ? JSON.parse(String(data.raw_template).replace(/&quot;/g, '"')) : [])
    setTemplateName(currentTemplate.name)
  // setSmsSummary()
  },[currentTemplate])
  
  const [templateName, setTemplateName] = useState();
  const [saving, setSaving] = useState(false);
  const [smsSummary, setSmsSummary] = useState("Welcome to SendBoth!\n\rClick the link below to get your 10% off Coupon!:\n\rhttps://both.ly/ABC123");
  const {
    state: { first_name },
  } = useContext(UserContext);
  
  const resetTemplate = useCallback(() => {
    const editedContent = localStorage.setItem(templateSlug, {});
    if(editedContent){
    //  setCurrentTemplateUnescaped({});
    }
  },[templateSlug])
  
  
  const saveTemplate = useCallback(() => {
    const editedContent = localStorage.getItem(templateSlug);
    if(editedContent && templateId && templateName){
      setSaving(true);
      const options = {
        template:{
          name: templateName,
          sms_template: smsSummary,
          raw_template: editedContent,
        }
      }
      updateTemplate(templateId, options).then(()=>{
        setSaving(false);
     })
    }
  },[templateSlug, templateId, templateName])
  
  const handleDuplicateTemplate = useCallback(() => {
      setSaving(true);
      duplicateTemplate(templateId).then(response=>{
        if(response.success){
          navigate("../template/"+response.id, { replace: true })
        }else{
          //
        }
      })
    setSaving(false);
  },[templateId])
  
  return (
    <Screen title={(currentTemplate.public ? 'Viewing' : 'Editing')+' Template '+currentTemplate.name+' | SendBoth'} showTopNav={true} showFooter={true} showBreadcrumbs={[
      { name: 'Templates', path: '/templates' },
      { name: (currentTemplate.public ? 'Viewing' : 'Editing'), path: '' },
      { name: '"'+currentTemplate.name+'"', path: '' },
    ]}>
      <Grid
        container
        alignItems="center"
        spacing={0}
        className={classes.screen}
      >
        <Paper
           className={classes.container}>
  
          <Grid
             container
             direction="row"
             justifyContent="space-evenly"
             alignItems="center"
             spacing={2}
             item xs={12}
          >
            <Grid item xs={12}>
              {!currentTemplate.public && <>
                <Typography variant={'h2'}>Name</Typography>
                <TextField
                 id="template_name"
                 defaultValue={currentTemplate.name}
                 value={templateName}
                 onChange={(e) => setTemplateName(e.target.value)}
              /></>}
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'h2'}>SMS Template</Typography>
              <TextField
                 fullWidth={true}
                multiline={true}
                id={'sms_summary'}
                value={smsSummary}
                defaultValue={'A SMS-friendly summary of your Email Template.'}
                />
            </Grid>
            <Grid item xs={4}></Grid>
            {currentTemplate.public ?
               <>
                 <Grid item xs={2} style={{margin:"auto",textAlign:'center'}}>
                   <Button disabled={isLoading || saving || (!templateId)} variant={'contained'} color={'primary'} onClick={handleDuplicateTemplate}>Start Editing</Button>
                 </Grid>
               </>
               :
            <>
              <Grid item xs={1}>
                <Button disabled={isLoading || saving || (!templateId)} variant={'contained'} color={'primary'} onClick={saveTemplate}>Save</Button>
              </Grid>
              <Grid item xs={1}>
                <Button disabled={isLoading || saving} variant={'contained'} color={'default'}>Reset</Button>
              </Grid>
            </>
            }
            
            
            <Grid item xs={12} className={classes.editor}>
              {isLoading || saving && <ProgressBar style={{margin:20}}/>}
              <Typography variant={'h2'}>Email Template</Typography>
              {!isLoading && currentTemplateUnescaped && <GrapesjsReact
                 id='grapesjs-react'
                 plugins={[
                   'gjs-preset-newsletter',
                   'gjs-blocks-basic'
                 ]}
                 projectData={currentTemplateUnescaped}
                 storageManager={{
                   type: 'local',
                   autosave: true,
                   stepsBeforeSave: 1,
                   autoload: true,
                   
                   //type: 'remote',
                  // stepsBeforeSave: 3,
                   options: {
                     local:{
                       key: templateSlug,
                     },
                   remote: {
                  // urlLoad: templateEndpoint,
                   //urlStore: templateEndpoint,
                     contentTypeJson: true,
                   // The `remote` storage uses the POST method when stores data but the json-server API requires PATCH.
                   fetchOptions: opts => (opts.method === 'POST' ?  { method: 'PATCH' } : {}),
                   // As the API stores projects in this format `{id: 1, data: projectData }`,
                   // we have to properly update the body before the store and extract the
                   // project data from the response result.
                   onStore: data => ({ id: templateId, raw_template: data }),
                   onLoad: result => result.raw_template,
                 }
                 }
                 }
                 }
              />}
            </Grid>
  
  
            <Grid item xs={12}>
              {!currentTemplate.public && <>
                <Typography variant={'h2'}>Merge Tags</Typography>
  
                {isLoadingMergeTags ?
                <ProgressBar style={{margin:40}}/>
                   :
                   <>
                     {Boolean(dataMergeTags) && dataMergeTags.map(item=>{
                       return (
                          <>
                            <TextField
                               id="template_name"
                               defaultValue={currentTemplate.name}
                               value={templateName}
                               onChange={(e) => setTemplateName(e.target.value)}
                            />
                          </>
                       )
                     })}
                   </>
                }
                
                </>}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Screen>
  );
};
export default EditTemplate;
