import React, { useCallback, useState} from 'react';
import {
  Box,
  List,
  ListItem,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
  ListItemText, ListItemSecondaryAction, Button, Switch, Divider
} from "@material-ui/core";
import {AddAPIKey, AddOrganizationInviteDialog, CopyAPIKey, ProgressBar} from '../../../components'
import useOrganizationInvitations from "../../../lib/hooks/useOrganizationInvitations";
import {updateOrganizationInvitation} from "../../../lib/api";
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    margin:'auto auto -10px',
    paddingBottom:20
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    '& ul':{
      width:'100%',
    },
    '& li':{
      textAlign: 'center',
      display: 'block'
    },
    '& a':{
      color: theme.palette.primary.main,
      textDecoration: 'none'
    },
  },
  patientLists:{
    marginTop:20,
  },
  title:{
    marginTop:20,
    textAlign: 'center',
    display: 'block'
  }
}));
const OrganizationInvitations = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const [apiKey, setApiKey] = useState(undefined);
  const [saving, setSaving] = useState(false);
  const {isLoading, isRefetching, data: invitations, refetch} = useOrganizationInvitations();
  
  const handleInviteUpdate = useCallback((invite) => {
    if(invite){
      setSaving(true);
      const options = {
        active: false,
      }
      updateOrganizationInvitation(invite.id, options).then(()=>{
        setSaving(false);
        refetch()
      })
    }
  },[refetch])
  
  
  return (
       <Box className={classes.container}>
         <Typography className={classes.title}>Easily invite your team members and manage their permissions.</Typography>
         {
           isLoading || isRefetching || saving ?
              <ProgressBar style={{margin:150}}/>
              :
              invitations.length ?
                 <List>
                   {invitations.map(item=>{
                     return <ListItem key={item.id}>
                       <ListItemText primary={item.name} secondary={item.access_code}/>
                       <ListItemSecondaryAction>
                         {item.active ? <>
                              <Button variant={'contained'} color={item.status === 'pending' ? "primary" : 'secondary'}>{item.status}</Button>
                              {item.status === 'pending' && <Button variant={'contained'} style={{marginLeft:10}}>Resend Invite</Button>}
                              {item.status === 'pending' && <Button variant={'contained'} style={{marginLeft:10}} onClick={()=>handleInviteUpdate(item)}>Cancel</Button>}
                            </>
                            :
                            <Button variant={'contained'} disabled={true}>Canceled</Button>}
                       </ListItemSecondaryAction>
                     </ListItem>
                   })}
                   
                   <ListItem>
                     <ListItemText primary={'Automatically add users who sign up using a Verified Domain?'} secondary={''}/>
                     <ListItemSecondaryAction>
                       <Switch checked={true} color={"secondary"}/>
                     </ListItemSecondaryAction>
                   </ListItem>
                   <Divider style={{marginTop:20,marginBottom:20}}/>
                   <ListItem>
                     <ListItemText primary={''} secondary={''}/>
                     <ListItemSecondaryAction>
                       <AddOrganizationInviteDialog refetch={refetch}/>
                     </ListItemSecondaryAction>
                   </ListItem>
                 </List>
                 :
                 <>
                   <Typography className={classes.title}>Let&apos;s send your first invite now.</Typography>
                   {apiKey == undefined ? <AddAPIKey setApiKey={setApiKey} /> : <CopyAPIKey api={apiKey} />}
                 </>
         }
       </Box>
    );
}

export default OrganizationInvitations;
