import React, { useCallback, useContext, useState } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Grid, InputBase, Paper, Tooltip, Typography,
} from '@material-ui/core';
import { PatientTabsContext } from '../../../lib/contexts';
import { ProgressBar } from '../../../components/ProgressBar';
import { postPatientNote } from '../../../lib/api/postPatientNote';

const useStyles = makeStyles(theme => ({

  patientChartCard: theme.globalClasses.patientChartCard,
  textArea:{
    marginTop:20,
    width:'100%',
    height:180,
    '& textarea':{
      padding:10,
      height: 150,
      width: '100%',
      color: theme.palette.admin.main,
      backgroundColor:theme.palette.admin.background,
    },
    color: theme.palette.admin.main,
    fontSize: 12,
  },
  buttons:{
    marginTop:-25,
    display:'block',
    float:'right'
  }
}));

const PatientAddNotesBox = () => {
  const classes = useStyles();
  const {
    state: { currentTab },
  } = useContext(PatientTabsContext);
  const [newNoteContent, setNewNoteContent] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  
  const throttleAutoSave = useCallback((event) => {
    const note = event.target.value;
    setNewNoteContent(note)
  },[])
  
  const handleNotePublish = useCallback(() => {
    setIsSaving(true);
    postPatientNote(newNoteContent, currentTab.id).then(()=>{
      setNewNoteContent('');
      setIsSaving(false);
    })
  },[newNoteContent, currentTab])
  
  return (
    <Grid item xs={12}>
      <Paper className={classes.patientChartCard}>
        <Grid item container xs={12}>
          <Grid item xs={10}>
            <Typography variant={'h2'}>Add Notes</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant={'h3'}>View All</Typography>
          </Grid>
        </Grid>
        <form className={classes.textArea} ><InputBase value={newNoteContent} multiline minRows={7} maxRows={7} fullWidth={true} onChange={throttleAutoSave}/>
        </form>
        {isSaving ? <ProgressBar style={{margin:'-10px auto 5px'}} /> : <>
          {newNoteContent && <Box className={classes.buttons}>
            <Button variant={'text'} onClick={() => setNewNoteContent('')}>Cancel</Button>
            <Tooltip title={newNoteContent.length < 100 ? 'Minimum length: 100 characters' : ''}>
              <span>
                <Button variant={'text'} onClick={handleNotePublish} disabled={newNoteContent.length < 100}>
                  Publish
                </Button>
              </span>
            </Tooltip>
          </Box>}
        </>}
    </Paper>
    </Grid>
  );
};
export default PatientAddNotesBox;
