import React from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
   CardContent, Grid, Paper, Typography,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container:{
    margin:10
  },
  patientChartCard: {
    ...theme.globalClasses.patientChartCard,
    '& p':{
      marginTop:10,
    }
  },
  title:{
    fontSize:'24px !important',
    textAlign: 'left',
    margin: 'auto'
  },
}));

const GettingStarted = () => {
  const classes = useStyles();
  return (
    <Grid item xs={12}><a id={'getting_started'}/>
      <Paper className={classes.patientChartCard}>
        <CardContent>
          <Typography variant={'h1'} className={classes.title}>Getting Started</Typography>
          <p>SendBoth provides digital nutrition tools to assess and track current nutritional intake, develop custom nutrition plans based on health goals, and guide individuals to their goals through personalized digital daily coaching. As a partner, you can use our stand-alone web-based tools with your population.</p>
          <p>If you use another digital platform to manage your population and want to embed SendBoth’s modules there, SendBoth can support integration to help you build a seamless experience.</p>
          <p>SendBoth has 3 different modules: An assessment module, a goal setting module, and a daily actions module. Depending on your needs, the SendBoth team will configure your instance with the right combination of modules. Our team will also customize your instance of SendBoth with the correct branding, color palette, copy, custom questions, and custom content display. Your custom instance of SendBoth will be accessible via <a href="https://your-subdomain.sendboth.com" rel="nofollow">https://your-subdomain.sendboth.com</a>. A unique subdomain can be provided by the SendBoth team.</p>
          <p>There are a few ways to integrate SendBoth into your experience. They generally address two concerns: <em>how the user interacts</em> with SendBoth’s modules and <em>how you retrieve the results</em> of those interactions.</p>
        </CardContent>
      </Paper>
    </Grid>
  );
};
export default GettingStarted;
