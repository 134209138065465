import React, { useContext, useEffect, useState, useMemo } from 'react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import packageJson from '../../../package.json';

import {
  Drawer,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Tooltip,
  Box, useTheme, useMediaQuery,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  AccountCircle,
  CheckBoxOutlined,
  EmailOutlined,
  HelpOutline,
  HomeOutlined,
  People,
  TableChart,
  PollOutlined,
  Widgets,
  Assignment, Search,
} from '@material-ui/icons';
import { PartnerContext, UserContext } from '../../lib/contexts';
import { makeStyles } from '@material-ui/core/styles';
import { Logo } from '../Logo';
import cn from 'classnames';
import { pageAccess } from '../../lib/constants/routes';
import { ShareLinkDialog } from '../ShareLinkDialog';
import { toCapitalizeFromUnderScore } from '../../lib/helpers/helpers';
import { getClientHost } from '../../lib/constants/constants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& a':{
      color:'inherit'
    },
    '& a:visited':{
      color:'inherit'
    }
  },
  container:{
    overflowX:'auto',
    margin:'auto',
    width:'100%',
    maxWidth:'94vw',
    padding: 0,
    display: 'flex',
    justifyContent:'space-between',
    color: theme.palette.primary.contrastText,
    '& *': {
      color: theme.palette.primary.contrastText,
    },
    
  },
  navList:{
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  },
  listItem: {
  
  },
  listItemActive: {
    borderBottomWidth:1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.contrastText,
  },
  listItemText: {
    height:30,
    display: 'block',
    margin: 'auto',
    padding: '0px',
    fontWeight: 'bold',
    '& *': {
      verticalAlign:'-webkit-baseline-middle',
      color: theme.palette.primary.contrastText + ' !important',
    },
  },
  ListItemIcon:{
    verticalAlign:'-webkit-baseline-middle',
    marginRight:-25,
    marginTop:0,
    height:30
  },
}));

const TopBarNavigation = () => {
  const {
    state: { code: partnerCode, name: partnerName, userRole },
  } = useContext(PartnerContext);
  const {
    actions: { logout, setShowingSearch, setShowingHelp },
  } = useContext(UserContext);
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);
  const active = useMemo(()=>window.document.location.pathname, [window.document.location]);
  let page_access = pageAccess(userRole);
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const matchLG = useMediaQuery(theme.breakpoints.only('xl'));
  
  
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleSearch = () => {
    setAnchorEl(null);
    setShowingSearch(true);
  };
  
  const handleHelp = () => {
    setAnchorEl(null);
    setShowingHelp(true);
  };
  
  
  return (<>
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar className={classes.container}>
          <Logo variant={'logo'}/>
          <List className={classes.navList}>
            {page_access.map((page_obj, index) => {
              const { name, path, onclick } = page_obj;
              return (
                  <ListItem
                    selected={active === name ? true : false}
                    button
                    key={index}
                    className={cn(classes.listItem, (active === path) || (path === '/dashboard' && active === '/') ? classes.listItemActive : '')}
                  >
                    <Link to={path}>
                      <Tooltip placement="bottom" title={name} aria-label={name}>
                        <ListItemIcon className={classes.ListItemIcon}>
                          {(() => {
                            switch (path) {
                              default:
                              case '/dashboard':
                                return <HomeOutlined />;
                              case '/contacts':
                                return <People />;
                              case '/lists':
                                return <Assignment />;
                              case '/campaigns':
                                return <EmailOutlined />;
                              case '/reports':
                                return <PollOutlined />;
                              case '/templates':
                                return <TableChart />;
                              case '/widgets':
                                return <Widgets />;
                            }
                          })()}
                        </ListItemIcon>
                      </Tooltip>
                      {!matchSM && <ListItemText
                        className={classes.listItemText}
                        primary={name}
                      />}
                    </Link>
                  </ListItem>
              );
            })}
          </List>
          <div>
          {matchLG &&
            <IconButton
               aria-label="universal search"
               aria-controls="search"
               aria-haspopup="true"
               onClick={handleSearch}
               color="inherit"
            >
              <Search/>
            </IconButton>}
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleSearch}>Search</MenuItem>
              <MenuItem onClick={handleHelp}>Help</MenuItem>
              {/*<MenuItem><Link to={'/docs'}>API Docs</Link></MenuItem>*/}
              <MenuItem><Link to={'/settings'}>Account Settings</Link></MenuItem>
              <MenuItem onClick={logout}>Log Out</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </div>
      {/*
    <Drawer
      className={isOpen ? classes.drawer + ' drawerParent' : classes.drawerClosed + ' drawerParent'}
      variant="permanent"
      classes={{
        paper: isOpen ? classes.drawerPaper : classes.drawerClosed,
      }}
      
    >
      <Box className={classes.topBox}>
        
        <Logo variant={'admin'} />
        <Menu
          style={{
            padding: 0,
            margin: '0 5px 0 0',
            top: -3,
            position: 'relative'
          }}
          className={'burger'}
          buttonWidth={20}
          barColor="white"
          isActive={isOpen}
          toggleButton={() => setOpen(!isOpen)}
        />
      </Box>
      <Typography className={classes.partnerName}>{partnerName}</Typography>
      <div className={classes.toolbar} style={{ display: 'none' }} />

      {page_access.map((page_obj, index) => {
        const { name, path, onclick, disabled, url } = page_obj;
        return (
          <List className={classes.sideNavList} key={`${path}_${index}`}>
            <ListItem
              selected={active === name ? true : false}
              button
              key={index}
              className={cn(classes.listItem, active === name ? classes.listItemActive : '')}
            >
              <Link to={url ? { pathname: getClientHost(partnerCode)+'/'+url } : '/'+path} target={url ? '_blank' : 'inherit'}>
                <Tooltip placement="bottom" title={name} aria-label={name}>
                  <ListItemIcon className={classes.ListItemIcon}>
                    {(() => {
                      switch (path) {
                        default:
                        case 'dashboard':
                          return <HomeOutlined />;
                        case 'patients':
                          return <PollOutlined />;
                        case 'resources':
                          return <MenuBookOutlined />;
                        case 'messages':
                          return <EmailOutlined />;
                        case 'settings':
                          return <SettingsOutlined />;
                        case 'help':
                          return <HelpOutline />;
                        case 'billing':
                          return <Payment />;
                        case 'settings#billing':
                          return <CheckBoxOutlined />;
                      }
                    })()}
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  className={classes.listItemText}
                  primary={name}
                  secondary={disabled ? <span className={classes.comingSoonBadge}>{'Coming Soon'}</span> : ''}
                />
              </Link>
            </ListItem>
          </List>
        );
      })}
  
      <List className={'SideNavList'}>
        <ListItem button key="ShareURL" className={classes.listItem}>
          <a target="#" rel="noopener noreferrer" onClick={() => setShowShareLink(true)}>
            <Tooltip placement="right" title={'Log Out'} aria-label={'Share Widgets'}>
              <ListItemIcon className={classes.ListItemIcon}>
                <ShareOutlined />
              </ListItemIcon>
            </Tooltip>
            <ListItemText className={classes.listItemText} primary={matchXL || matchLG ? "Share Widgets" : "Share Widgets"} />
          </a>
        </ListItem>
      </List>
      <Divider />
      <List className={'SideNavList'}>
        <ListItem button key="LogOut" className={classes.listItem}>
          <a target="#" rel="noopener noreferrer" onClick={logout}>
            <Tooltip placement="right" title={'Log Out'} aria-label={'Log Out'}>
              <ListItemIcon className={classes.ListItemIcon}>
                <ExitToAppIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText className={classes.listItemText} primary="Log Out" />
          </a>
        </ListItem>
      </List>
      <div
        className={'bottom'}
        style={{
          margin: 'auto 0 20px auto',
          textAlign: 'left',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Typography className={classes.internalInfo}>
          Build: v{packageJson.version}
        </Typography>
        {userRole && <Typography className={classes.internalInfo}>
          Role: {toCapitalizeFromUnderScore(userRole)}
        </Typography>}
      </div>
      {showShareLink && <ShareLinkDialog handleClose={() => setShowShareLink(false)}/>}
    </Drawer>*/}
  </>
  );
};

export default TopBarNavigation;
