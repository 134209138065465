export const replaceTime = (date, time) => {
  let newDate = new Date(date).toString().split(' ');
  newDate[4] = new Date(time).toString().split(' ')[4];
  newDate = new Date(newDate.join(' '));
  return newDate;
};
export const prettyDateTime = (time) => {
  let date = new Date(time);
  return (
    date.toLocaleDateString('en', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
    }) +
    ' at ' +
    date.toLocaleTimeString('en', { hour: '2-digit', minute: '2-digit' }).replace(/^0(?:0:0?)?/, '')
  );
};
export const dateToUnix = (dateObj) => {
   const date = new Date(dateObj);
   return Math.floor(date.getTime() / 1000);
};
export const minusMonth = (dateObj, num = 1) => {
   const date = new Date(dateObj);
   return prettyDate(date.setMonth(date.getMonth() - num))
};
export const plusMonth = (dateObj, num = 1) => {
   const date = new Date(dateObj);
   return prettyDate(date.setMonth(date.getMonth() + num))
};
export const prettyDate = (time) => new Date(time).toLocaleDateString('en', {});
export const prettyDateLong = (time) => new Date(time).toLocaleDateString('en', {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
});
export const prettyTime = (time) => new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
export const prettyHour = (time) =>
  new Date(time).toLocaleTimeString('en', { hour: '2-digit', minute: '2-digit' }).replace(/^0(?:0:0?)?/, '');
export const prettyPhone = (number) =>
  number.substr(2).replace(/(\d{1,2})(\d{1})?(\d{1,3})?(\d{1,4})?/, function (_, p1, p2, p3, p4) {
    let output = '';
    if (p1) output = `${p1}`;
    if (p2) output += `${p2}-`;
    if (p3) output += `${p3}-`;
    if (p4) output += `${p4}`;
    return output;
  });
export const toCapitalize = (sentence) => sentence.length ? sentence.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) : ''
export const toCapitalizeFromUnderScore = (name) =>  name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
export const toUnderScoreSlug = (name) =>  name.split(' ').map(word => word.toLowerCase()).join('_');

export const groupBy = (list, key) => {
  return list.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const keyBy = (array, key) => (array || []).reduce((r, x) => ({ ...r, [key ? x[key] : x]: x }), {});

export const keyByCollection = (collection, key) => {
  const c = collection || {};
  return c.isArray() ? keyBy(c, key) : Object.values(keyBy(c, key));
}
export const compactArray = (array) => array.filter(Boolean)

export const getCookie = (n) => {
  let a = `; ${document.cookie}`.match(`;\\s*${n}=([^;]+)`);
  return a ? a[1] : '';
}
export const setCookie = (name,value,days = 365) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
export const eraseCookie = (name) => {
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export const roundHalf = (n) => {
  return (Math.round(n*2)/2).toFixed(1);
}

export const cmToInFt = (cm, inches = Math.round(cm / 2.54)) => {
  return Math.floor(inches / 12)+"'"+inches % 12+'"'
}

export const kgToLbs = (kg) => {
  return Math.floor(kg * 2.20462262185)
}

export const truncate = (string) => {
  return string
}

export const readFileAsString = (file) => {
  var request = new XMLHttpRequest();
  request.open("GET", file, false);
  request.send(null);
  var returnValue = request.responseText;
  return returnValue;
}
export const formatMoney = (amount, stripeMode) => {
  let number = stripeMode && amount ? String(amount).slice(0, -2) : amount;
  return Number(number).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}
