import React, { useCallback, useState } from 'react';

import {
  Card,
  CardContent, Grid, IconButton,  Menu, MenuItem,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { toCapitalize } from '../../../lib/helpers/helpers';
import { MoreVert } from '@material-ui/icons';
import { deleteContactList } from '../../../lib/api/deleteContactList';
import { useNavigate } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
  patientListCard:{
    cursor: 'pointer',
    color: theme.palette.admin.main,
    fontWeight: 'bold',
    marginRight:25,
    marginBottom:25,
    width:214,
    height:50,
    padding:0
  },
  patientListCardMoreButton:{
    marginTop:-5,
    marginRight:-10,
    padding:0
  }
}));

const IndividualCard = ({listItem, refetch}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { id, list_name } = listItem;
  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const managePatientList = useCallback(event => {
    setAnchorEl(event.currentTarget);
  },[])
  
  const deleteList = useCallback(event => {
    const confirm = window.confirm('Confirm Deletion: '+list_name)
    if(confirm){
      deleteContactList(id).then(()=>{
        setAnchorEl(null);
        refetch();
      })
    }
  },[id])
  
  return (
          <>
            <Card key={'patient_list_'+id} className={classes.patientListCard}>
              <CardContent>
                <Grid
                  container
                  justifyContent={'space-between'}>
                  <Grid item onClick={()=>navigate("../list/"+id, { replace: true })}>
                    {toCapitalize(list_name)}
                  </Grid>
                  <Grid item className={classes.patientListCardMoreButton}>
                    <IconButton
                      onClick={managePatientList}
                      size={'small'}
                    >
                      <MoreVert/>
                    </IconButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
  
            <Menu
              id="card-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={()=>navigate("../list/"+id, { replace: true })}>Manage</MenuItem>
              <MenuItem onClick={deleteList}>Delete</MenuItem>
            </Menu>
          </>
  );
};

export default IndividualCard;
