import axios from 'axios';
import { API_HOST } from '../constants/constants';
import useCurrentUser from '../hooks/useCurrentUser';
export const getPartner = async partnerCode => {
  const apiURL = API_HOST + '/partners/load?code=' + partnerCode;
  const { token, userID } = useCurrentUser();
  try {
    const { data } = await axios.get(apiURL,{
      headers: {
        'Authorization': 'Bearer '+token,
        'x-sb-user-id': userID
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
