import React from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  outlinedPrimary:{
    color: theme.palette.admin.main,
    marginRight:10,
    marginBottom:10,
    display:'inline-flex',
    borderColor: theme.palette.admin.background,
  },
  outlinedPrimaryClickable:{
    color: theme.palette.admin.main,
    marginRight:10,
    marginBottom:10,
    display:'inline-flex',
    borderColor: theme.palette.admin.main,
  },
  root:{
    margin:'10px 10px 10px 0px',
    '& :hover':{
      color: theme.palette.admin.dark,
      borderColor: theme.palette.admin.dark,
    }
  },
}));
const AdminChip = ({style, label, onClick, clickable, disabled}) => {
  const classes = useStyles();
  return (
    <Chip color="primary" disabled={disabled} clickable={clickable} onClick={onClick} variant={'outlined'} label={label} classes={{root:classes.root, outlinedPrimary: clickable ? classes.outlinedPrimaryClickable : classes.outlinedPrimary}} style={style} />
  );
};

export default AdminChip;
