import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Routes, Route, BrowserRouter as Router, useParams } from 'react-router-dom';
import './App.css';
import { CssBaseline, CircularProgress } from '@material-ui/core/';
import { ThemeProvider } from '@material-ui/styles';
import { LightTheme } from './lib/themes';
//import { ADMIN_PAGE_ROUTE, HOME_PAGE_ROUTE, SETTINGS_PAGE_ROUTE, PUBLIC_URL } from './lib/constants/routes';
import { AdminScreen, LoginScreen, NotFoundScreenInternal } from './screens';
import { CenteredContent, Screen, PageLoadError, UniversalSearch } from './components';
//import CacheBuster from './lib/helpers/cacheBuster';
import { QueryClient, QueryClientProvider } from 'react-query';
import { DashboardScreen, ContactsScreen, ListManagementScreen, ListSpecificScreen, CampaignsScreen, SettingsScreen, ReportsScreen, WidgetsScreen, TemplatesScreen, EditTemplate } from './screens/';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { PatientsScreen } from './screens/PatientsScreen';
import { ProgressBar } from './components/ProgressBar';
import { BillingScreen } from './screens/BillingScreen';
import { UserContext, UserContextProvider, PartnerContext, PartnerContextProvider, BillingContext, BillingContextProvider, CampaignContextProvider } from './lib/contexts/';
import SetupPlanDialog from './screens/BillingScreen/components/SetupPlanDialog';
import OnboardingDialog from './components/OnboardingDialog/OnboardingDialog';
import { getBetaClientHost, getClientHost } from './lib/constants/constants';
import useSession from './lib/hooks/useSession';
import { APIDocScreen } from './screens/APIDocScreen';

import { useNavigate } from 'react-router-dom';
import ImportContacts from "./components/OnboardingDialog/ImportContacts";
const App = () => {
  const {
    state: {user_id, plan, showingSearch, showingHelp},
    actions: { forceLogout, logout, setShowingSearch, setShowingHelp },
  } = useContext(UserContext);
  const { isLoading: sessionLoading, data: sessionDataRaw, refetch: sessionRefetch } = useSession();
  const sessionData = useMemo(()=>sessionDataRaw && !sessionDataRaw.error ? sessionDataRaw.user : {},[sessionDataRaw]);
  
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showPlanRequiredDialog, setShowPlanRequiredDialog] = useState(Boolean(!plan));
  const [pageAccess, setPageAccess] = useState(['login', 'dashboard', 'patients']);
  const pageSlug = useMemo(()=> window.location.href.substring(window.location.href.lastIndexOf('/') + 1),[window.location])
  //console.log('userObject', userState)
  const {
    state: { code: userRole },
  } = useContext(PartnerContext);
  
  useEffect(() => {
    if(!plan){
      setShowPlanRequiredDialog(true);
    }
  }, [plan, pageSlug]);
  
  useEffect(() => {
    if(sessionData.id || sessionLoading){
      // logged in or loading
    }else{
      forceLogout();
      //logout()
    }
  }, [sessionData]);
  
  useEffect(()=>{
    if(userRole === '' && pageSlug && pageSlug !== 'docs'){
     // logout()
    }
  },[userRole])
  
  //alert(user_id);
  useEffect(() => {
    setError(false);
    setLoading(false);
  }, []);
  
  useEffect(() => {
    const callback = (event) => {
      // event.metaKey - pressed Command key on Macs
      // event.ctrlKey - pressed Control key on Linux or Windows
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'KeyF') {
        event.preventDefault();
        setShowingSearch(true)
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'KeyH') {
        event.preventDefault();
        setShowingHelp(true)
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit1') {
        event.preventDefault();
        window.location.href = getClientHost()+'/';
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit2') {
        event.preventDefault();
        window.location.href = getClientHost()+'/contacts';
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit3') {
        event.preventDefault();
        window.location.href = getClientHost()+'/lists';
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit4') {
        event.preventDefault();
        window.location.href = getClientHost()+'/campaigns';
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit5') {
        event.preventDefault();
        window.location.href = getClientHost()+'/reports';
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit6') {
        event.preventDefault();
        window.location.href = getClientHost()+'/templates';
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit7') {
        event.preventDefault();
        window.location.href = getClientHost()+'/widgets';
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit8') {
        event.preventDefault();
        window.location.href = getClientHost()+'/settings';
      }
      if (event.code === 'Escape') {
      //  setShowingSearch(false)
      }
  
    };
    document.addEventListener('keydown', callback);
    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, []);
  
  const switchComponent = useCallback(slug => {
    switch (slug) {
      case 'login':
        return <LoginScreen />;
      case 'office_admin':
        return <AdminScreen />;
      case 'settings':
        return <></>;//<SettingsScreen />;
      case 'dashboard':
        return <DashboardScreen />;
      case 'homepage':
      case '/':
      default:
            return <DashboardScreen />;
  }}, []);

  const renderRoute = useCallback(
    () => {
      //const { slug } = useParams(); // - not working
      const slug = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)

      if (!user_id) {
        return <LoginScreen />;
      } else {
        return pageAccess.includes(slug) ? <>{(switchComponent(slug))}</> : <NotFoundScreenInternal/>;
      }
    },
    [user_id, pageAccess, useParams],
  );

  if (loading || error) {
    return (
      <Screen>
        <CenteredContent>
          {loading ? <CircularProgress color={'primary'} /> : <PageLoadError message={'Page Load Error'} />}
        </CenteredContent>
      </Screen>
    );
  }
  
  const wrappedDashboardScreen = <React.Suspense fallback={<ProgressBar/>}><DashboardScreen/></React.Suspense>
  const wrappedContactsScreen = <React.Suspense fallback={<ProgressBar/>}><ContactsScreen/></React.Suspense>
  const wrappedListManagementScreen = <React.Suspense fallback={<ProgressBar/>}><ListManagementScreen/></React.Suspense>
  const wrappedCampaignsScreen = <React.Suspense fallback={<ProgressBar/>}><CampaignsScreen/></React.Suspense>
  const wrappedReportsScreen = <React.Suspense fallback={<ProgressBar/>}><ReportsScreen/></React.Suspense>
  const wrappedTemplatesScreen = <React.Suspense fallback={<ProgressBar/>}><TemplatesScreen/></React.Suspense>
  const wrappedTemplateEditScreen = <React.Suspense fallback={<ProgressBar/>}><EditTemplate/></React.Suspense>
  const wrappedWidgetsScreen = <React.Suspense fallback={<ProgressBar/>}><WidgetsScreen/></React.Suspense>
  const wrappedSettingsScreen = <React.Suspense fallback={<ProgressBar/>}><SettingsScreen/></React.Suspense>
  
//  const wrappedPatientsScreen = <React.Suspense fallback={<ProgressBar/>}><PatientsScreen/></React.Suspense>
//  const wrappedBillingScreen = <React.Suspense fallback={<ProgressBar/>}><BillingScreen/></React.Suspense>
//  const wrappedAPIDocScreen = <React.Suspense fallback={<ProgressBar/>}><APIDocScreen/></React.Suspense>
  

  return (<>
    <Router basename={'/'}>
      <Routes>
        {
          user_id ?
            <>
              <Route path="/test" element={<ImportContacts/>} />
              <Route path="/" element={wrappedDashboardScreen} />
              <Route path="/login" element={wrappedDashboardScreen} />
              
              <Route path="/dashboard" element={wrappedDashboardScreen} />
              <Route path="/help" element={wrappedDashboardScreen} />
              <Route path="/contacts" element={wrappedContactsScreen} />
              {<Route path="/contact" element={wrappedContactsScreen}/>}
              <Route path="/contact/:contactId" element={wrappedContactsScreen} />
              <Route path="/lists" element={wrappedListManagementScreen} />
              <Route path="/lists/:listId" element={wrappedListManagementScreen} />
              <Route path="/list/" element={wrappedListManagementScreen} />
              <Route path="/list/:listId" element={wrappedListManagementScreen} />
              <Route path="/campaigns" element={wrappedCampaignsScreen} />
              <Route path="/campaigns/:campaignId" element={wrappedCampaignsScreen} />
              <Route path="/campaign/" element={wrappedCampaignsScreen} />
              <Route path="/campaign/:campaignId" element={wrappedCampaignsScreen} />
              <Route path="/reports" element={wrappedReportsScreen} />
              <Route path="/templates" element={wrappedTemplatesScreen} />
              <Route path="/templates/:templateId" element={wrappedTemplateEditScreen} />
              <Route path="/template/:templateId" element={wrappedTemplateEditScreen} />
              <Route path="/widgets" element={wrappedWidgetsScreen} />
              <Route path="/settings" element={wrappedSettingsScreen} />
              <Route path="/settings/:pageSlug" element={wrappedSettingsScreen} />
            </>
            :
            <>
              <Route path="*" element={<LoginScreen/>} />
            </>
        }
        <Route path="/privacy" element={<NotFoundScreenInternal/>} />
        <Route path="/terms" element={<NotFoundScreenInternal/>} />
        {/*
        <Route path=":slug" element={renderRoute()} />
        <Route path=":slug/:subSlug" element={renderRoute()} />
        
        <Route exact path="/:slug?/:sub_slug?" element={renderRoute} />*/}
      </Routes>
      { /*showPlanRequiredDialog && pageSlug !== 'billing' && <SetupPlanDialog setShowPlanRequiredDialog={setShowPlanRequiredDialog}/>*/}
      { showPlanRequiredDialog && pageSlug !== 'billing' && user_id && <OnboardingDialog setShowPlanRequiredDialog={setShowPlanRequiredDialog}/>}
      { (showingSearch || showingHelp) && <UniversalSearch />}
    </Router>
    </>
  );
};
const ThemeContainer = () => {
  const {
    state: { primary_color, secondary_color },
  } = useContext(PartnerContext);
  return (
    <ThemeProvider theme={LightTheme(primary_color, secondary_color)}>
      {/*<CacheBuster />*/}
      <CssBaseline />
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </ThemeProvider>
  );
};

const AppContainer = () => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <PartnerContextProvider>
        <UserContextProvider>
          <BillingContextProvider>
            <CampaignContextProvider>
          <ThemeContainer />
            </CampaignContextProvider>
          </BillingContextProvider>
        </UserContextProvider>
      </PartnerContextProvider>
    </QueryClientProvider>
  );
};

export default AppContainer;
