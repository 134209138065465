import React, { useContext } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid, Paper, Typography, useMediaQuery, useTheme,
} from '@material-ui/core';
import { PatientTabsContext } from '../../../lib/contexts';
import useMetric from '../../../lib/hooks/useMetric';
import { ProgressBar } from '../../../components/ProgressBar';
import Chart from 'react-apexcharts'

const useStyles = makeStyles(theme => ({
  patientChartCard: theme.globalClasses.patientChartCard,
  container:{
    flex:1,
    minWidth: 300,
    minHeight: 250,
  },
  topBar:{
    marginBottom:10
  },
  addList:{
    fontSize:18,
    marginBottom:-5,
    fontWeight:'bold'
  },
  chart:{
    marginTop: 20,
    marginBottom: 0,
    borderRadius:5,
    backgroundColor: theme.palette.admin.trendChart
  }
}));

const PatientDietQualityTrendBox = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.up('sm'));
  const matchMD = useMediaQuery(theme.breakpoints.up('md'));
  const matchLG = useMediaQuery(theme.breakpoints.up('lg'));
  const matchXL = useMediaQuery(theme.breakpoints.up('xl'));
  const {
    state: { currentTab },
  } = useContext(PatientTabsContext);
  const {isLoading, data } = useMetric('diet_quality_trend', currentTab.id);
  return (
    <Grid item xs={(matchXL || matchLG) ? 2 : 12} className={classes.container}>
      <Paper className={classes.patientChartCard}>
        <Typography variant={'h2'} className={classes.topBar}>Diet Quality Trend</Typography>
        {isLoading ?
          <ProgressBar /> :
          <Box>
            <Grid>
              {data && data.metric && data.metric.value && <>
                  <Chart className={classes.chart} options={{
                    stroke: {
                      show: true,
                      curve: 'smooth',
                      lineCap: 'butt',
                      colors: theme.palette.admin.main,
                      width: 4,
                      dashArray: 0,
                    },
                    chart: {
                      toolbar: {
                        show: false,
                      },
                      id: 'quality_trend'
                    },
                    xaxis: {
                      categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                      labels: {
                        show: false,
                      }
                    },
                    yaxis: {
                      show: false
                    },
                  }}
                         series={[{
                           name: 'Diet-Quality-Trend',
                            data: data.metric.value
                          }]}
                         type="line"
                         height={228} />

              </>
              }
            </Grid>
          </Box>
        }
      </Paper>
    </Grid>
  );
};

export default PatientDietQualityTrendBox;
