import React, { useContext, useMemo } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { PatientTabsContext } from '../../../lib/contexts';
import useMetric from '../../../lib/hooks/useMetric';
import { ProgressBar } from '../../../components/ProgressBar';
import {
   ArrowDropDown, ArrowDropUp,
  EmojiObjects,
  FlashOn, InfoRounded,
  Print,
} from '@material-ui/icons';
import { AdminButton } from '../../../components/AdminButton';
import { roundHalf } from '../../../lib/helpers/helpers';

const useStyles = makeStyles(theme => ({
  container:{
    margin:10
  },
  patientChartCard: {
    ...theme.globalClasses.patientChartCard,
    '& p':{
      marginTop:10,
    }
  },
  title:{
    fontSize:10,
    '& svg':{
      fill:theme.palette.admin.main
    }
  },
  list:{
    borderStyle:'solid',
    borderWidth:2,
    borderColor:theme.palette.admin.main
  },
  listItemAvatar:{
    display:'block',
    borderRadius:6,
    width:'25px',
    height:25,
    backgroundColor:theme.palette.common.increase,
    marginRight:15,
    '& svg':{
      fill:theme.palette.admin.main
    }
  },
  listItemAvatarDecrease:{
    display:'block',
    borderRadius:6,
    width:'25px',
    height:25,
    backgroundColor:theme.palette.common.decrease,
    marginRight:15,
    '& svg':{
      fill:theme.palette.error.dark
    }
  },
  listItem:{
    margin:0,
    '& span':{
      margin:'0px !important',
      color:theme.palette.admin.main,
      fontSize:14,
    },
    '& p':{
      margin:'0px !important',
      color:theme.typography.helper.main,
      fontSize:'12px !important',
    }
  },
  listItemSecondary:{
  '& svg':{
    fill:theme.typography.helper.light,
  }
  },
  itemBorder:{
    paddingTop:10,
    paddingBottom:10,
    borderWidth:'0px 1px 0px 0px',
    borderStyle:'solid',
    borderColor:theme.palette.admin.background
  },
  item:{
    paddingLeft:15,
    paddingRight:15,
    textAlign:'center',
    '& h6':{
      textTransform:'uppercase',
    },
    '& p':{
      fontSize:14,
      marginBottom:50,
    }
  }
}));

const PatientPrioritiesBox = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.up('sm'));
  const matchMD = useMediaQuery(theme.breakpoints.up('md'));
  const matchLG = useMediaQuery(theme.breakpoints.up('lg'));
  const matchXL = useMediaQuery(theme.breakpoints.up('xl'));
  const {
    state: { currentTab },
  } = useContext(PatientTabsContext);
  const {isLoading, data } = useMetric('priorities', currentTab.id);
  return (
    <Grid item xs={12}>
      <Paper className={classes.patientChartCard}>
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={10}>
            <Typography variant={'h1'}>Today&apos;s Priorities for {currentTab.firstName}</Typography>
            <Typography variant={'body1'}>Top food changes to reach Patient&apos;s goal{data && data.metric.value.ideal_diet ? <> diet of <b>&quot;{data.metric.value.ideal_diet}&quot;</b></> : ''}</Typography>
          </Grid>
          <Grid item xs={2} container justifyContent={'flex-end'} style={{textAlign:'right', height: 44}}>
                <AdminButton variant={'outlined'} onClick={() => window.print()}><Print/>&nbsp;Print</AdminButton>
          </Grid>
        </Grid>
        {isLoading ?
          <ProgressBar /> :
          <>
            {data && data.metric && <Grid xs={12} spacing={1}
                   container item className={classes.container}>
              <Grid item
                    xs={(matchXL || matchLG) ? 8 : 12} className={`${classes.item} ${classes.itemBorder}`}>
                {data &&
                data.metric &&
                data.metric.value &&
                data.metric.value.food_changes &&
                data.metric.value.food_changes.increases &&
                data.metric.value.food_changes.decreases &&
                <Grid container item xs={12} spacing={(matchXL) ? 2 : 1} justifyContent={'space-between'}>
                  <Grid item xs={(matchXL || matchLG) ? 6 : 12}>
                    <Typography variant={'subtitle2'} className={classes.title}>Increase</Typography>
                    <List dense={true} className={classes.list}>
                      {data.metric.value.food_changes.increases.map((item, index) => {
                        return <ListItem key={index} className={classes.listItem}>
                          <Avatar className={classes.listItemAvatar}><ArrowDropUp /></Avatar>
                          <ListItemText primary={item.label}
                                        secondary={'by ' + roundHalf(item.change.difference) + ' servings'} />
                          <ListItemSecondaryAction className={classes.listItemSecondary}>
                            {item.change.children &&
                            <Tooltip title={item.change.children.map((item) => item.label + ' ')}>
                              <InfoRounded />
                            </Tooltip>}
                          </ListItemSecondaryAction>
                        </ListItem>;
                      })}
                    </List>
                  </Grid>
                  <Grid item xs={(matchXL || matchLG) ? 6 : 12}>
                    <Typography variant={'subtitle2'} className={classes.title}>Decrease</Typography>
                    <List dense={true} className={classes.list}>
                      {data.metric.value.food_changes.decreases.map((item, index) => {
                        return <ListItem key={index} className={classes.listItem}>
                          <Avatar className={classes.listItemAvatarDecrease}><ArrowDropDown /></Avatar>
                          <ListItemText primary={item.label}
                                        secondary={'by ' + roundHalf(Math.abs(item.change.difference)) + ' servings'} />
                          <ListItemSecondaryAction className={classes.listItemSecondary}>
                            {item.change.children &&
                            <Tooltip title={item.change.children.map((item) => item.label + ' ')}>
                              <InfoRounded />
                            </Tooltip>}
                          </ListItemSecondaryAction>
                        </ListItem>;
                      })}
                    </List>
                  </Grid>
                </Grid>}
              </Grid>
              <Grid item
                    xs={(matchXL || matchLG) ? 4 : 12} className={classes.item}>
                <Typography variant={'subtitle2'} className={classes.title}><FlashOn /><br />This Week`&apos;s
                  Actions</Typography>
                <Tooltip title={data.metric.value.this_week_action_description}>
                <Typography>{data.metric.value.this_week_action}</Typography>
                </Tooltip>
                <Typography variant={'subtitle2'} className={classes.title}><EmojiObjects /><br />Today`&apos;s
                  Tip</Typography>
                <Tooltip title={data.metric.value.todays_tip_description}>
                <Typography>{data.metric.value.todays_tip}</Typography>
                </Tooltip>
              </Grid>
            </Grid>}
          </>}
      </Paper>
    </Grid>
  );
};

export default PatientPrioritiesBox;
