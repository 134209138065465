import React from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
   CardContent, Grid, Paper, Typography,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container:{
    margin:10
  },
  patientChartCard: {
    ...theme.globalClasses.patientChartCard,
    '& p':{
      marginTop:10,
    }
  },
  title:{
    fontSize:'24px !important',
    textAlign: 'left',
    margin: 'auto'
  },
}));

const RetrievingResults = () => {
  const classes = useStyles();
  return (
    <Grid item xs={12}><a id={'retrieving_results'}/>
      <Paper className={classes.patientChartCard}>
        <CardContent>
          <Typography variant={'h1'} className={classes.title}>Retrieving Results</Typography>
          <p>To retrieve results in real time, data can be manually accessed by you, pushed to you via webhooks, or pulled by you via our API. Depending on your relationship with your users, you may have different types of access to data. For example, if you are a HIPAA compliant health care provider, you are eligible to access our full data set.</p>
          <h3>1.1 Use our Admin Portal (no technical integration required)</h3>
          <p>If you want to manually access you users&#39; diet assessment data and goal setting results, we can give you access to an admin portal where you&#39;ll receive user results in real time. Data can also be exported in CSV and JSON directly from that reporting tool. Using this admin portal does not require any additional technical integration.</p>
          <p>This is the simplest and fastest way to access your population’s data. Even if you have your own patient or client management system like an EHR, many of our partners choose this solution because you can get up and running in as little as 1 day.</p>
          <h3>1.2 Webhook (some integration required)</h3>
          <p>If you want to automatically receive a user&#39;s data such that it is appended to your own patient or client management system, we can set up a webhook with your system. Whenever a user completes a SendBoth assessment, their results will be POST&#39;ed to the configured URL you share with us. If users are created via SSO, the data we share via the webhook will include the user id you passed to us for easy mapping with existing users.</p>
        </CardContent>
      </Paper>
    </Grid>
  );
};
export default RetrievingResults;
