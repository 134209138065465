import React, {useEffect, useMemo, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { MoreVert, Delete, People } from '@material-ui/icons/';
import { ListItemRow } from './index';
import { AddContactListDialog } from '../../../components';
import useContactLists from '../../../lib/hooks/useContactLists.js';
import ProgressBar from '../../../components/ProgressBar/ProgressBar.js';

const useStyles = makeStyles((theme) => ({
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    padding:10
  },
  content:{
    margin:'-20px auto auto',
    width:'100%',
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  noneFound:{
    padding:40,
    margin: 'auto',
    textAlign:'center',
    '& *':{
      display:'block',
      margin: 'auto',
      textAlign:'center',
    }
  }
}));

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}

export default function ListofLists() {
  const classes = useStyles();
  const { isLoading, data, refetch } = useContactLists();
  const tableData = useMemo(()=>data && !data.error ? data : [],[data]);
  const [forceOpen, setForceOpen] = useState(false);
  useEffect(() => {
    const callback = (event) => {
      // event.metaKey - pressed Command key on Macs
      // event.ctrlKey - pressed Control key on Linux or Windows
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'KeyN') {
        event.preventDefault();
        setForceOpen(true)
      }
      if (event.code === 'Escape') {
        setForceOpen(false)
      }
      
    };
    document.addEventListener('keydown', callback);
    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, [forceOpen]);
  return (
     <div className={classes.content}>
     <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={1}
        className={classes.container}
     >
            {isLoading ?
               <ProgressBar /> :
               <>
                 {tableData ? <>
                   <Grid item xs={9}>
                     <Typography variant="h6" className={classes.title}>
                       Total Lists: {tableData ? tableData.length : 0}
                     </Typography>
                   </Grid>
                   <Grid item xs={3}>
                     <AddContactListDialog forceOpen={forceOpen} setForceOpen={setForceOpen} size={'text'} title={'Add List'} refetch={refetch}/>
                   </Grid>
                   <Grid item xs={12}>
                     <List>
                       {tableData.map((value) => <ListItemRow key={value.id} value={value} refetch={refetch}/>
                       )}
                     </List>
                   </Grid>
                 </>
                    :
                    <>
                      <Typography variant="h6" className={classes.noneFound}>
                        No Contact Lists were found.
                        <AddContactListDialog forceOpen={forceOpen} setForceOpen={setForceOpen} size={'text'} title={'Add Your First List'} refetch={refetch}/>
                      </Typography>
                    </>
                 }
                 </>
            }
            </Grid>
     </div>
  );
}
