import { useQuery } from 'react-query';
import { getPartner } from '../api/getPartner';

const usePartner = partnerCode => {
  const result = useQuery('current_partner', () => getPartner(partnerCode), {
    refetchOnWindowFocus: false,
    staleTime: 50000,
  });
  return result.data;
};

export default usePartner;
