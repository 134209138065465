import React, { useCallback, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box, Button, Grid, Select, MenuItem } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {
  TextField, Typography,
  withStyles,
} from '@material-ui/core';
import { Add, Tune, Create, GroupAdd, Delete } from '@material-ui/icons';
import { postContactList } from '../../lib/api/postContactList';
import { ProgressBar } from '../ProgressBar';
import {postApiKey, postVerifiedDomain} from "../../lib/api";

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    /* width: 'fit-content',*/
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientListSmall:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:180,
    height:44,
    margin:'20px auto 0px',
    fontSize:13,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    '& *':{
      color: theme.palette.primary.contrastText,
      display:'inline',
    }
  },
  addPatientListText:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    display:'block',
    margin:'40px auto 0px !important',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    marginTop:-25,
    marginRight:25,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  shareSwitch:{
    color: theme.palette.primary.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
  container:{
    maxHeight:250,
    overflowY: 'auto',
    margin:'20px auto 20px',
    textAlign:'center',
    '& *':{
      textAlign:'center',
    }
  },
  dynamicVarListItem:{
    marginBottom:20
  }
}));

const AdminGreenSwitch = withStyles(theme =>({
  switchBase: {
    color: theme.palette.primary.dark,
    '&$checked': {
      color: theme.palette.primary.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  checked: {},
  track: {},
}))(Switch);
const AdminGreenTextField = withStyles(theme =>({
  root: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
}))(TextField);

const AddVerifiedDomainDialog = ({forceOpen = false, setForceOpen, refetch, size, invisible, title = undefined}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(invisible);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [email, setEmail] = useState('');
  
  useEffect(()=>{
    setOpen(forceOpen)
  },[forceOpen])
  
  const handleToggleOpen = useCallback(() => {
    setOpen(!open);
    if(forceOpen && open){
      setForceOpen(false)
    }
  },[open, forceOpen, setForceOpen]);
  
  const handleInputChange = useCallback((event) => {
    setEmail(event.target.value);
  },[]);
  
  const handleSave = useCallback(() => {
    const cleanEmail = email.trim();
    if(cleanEmail.length > 3){
      setSaving(true);
      postVerifiedDomain(cleanEmail).then(()=>{
        setSaved(true);
        setSaving(false);
        setOpen(false);
        setEmail('')
        refetch();
      })
    }
  },[email]);
  
  
  return (
    <>
      {!invisible && <Button variant={'contained'} color={'primary'} onClick={handleToggleOpen} disabled={open || saving}>
        {title ?? 'New Domain'}
      </Button>}
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        open={open}
        onClose={handleToggleOpen}
        aria-labelledby="add-verified-domain-dialog-form" className={classes.form}
      >
        <DialogTitle id="add-verified-domain-dialog-title"><GroupAdd/>Add a new Verified Domain</DialogTitle>
        <DialogContent>
          {
            saving ?
              <ProgressBar style={{margin:'50px auto 50px'}}/>
              :
              <>
                <DialogContentText>
                  Please enter a valid Email Address of a Domain that you own. We will send you a verification link.
                </DialogContentText>
                <form noValidate>
                  <AdminGreenTextField
                    autoFocus={true}
                    value={email}
                    onChange={handleInputChange}
                    variant={'outlined'}
                    fullWidth={true}
                    label={<><Create/> Email Address</>} placeholder={'e.g., hello@Example.com'}
                  />
                </form>
              </>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleOpen}
                  className={classes.cancelButton}
                  disabled={saving}>
            Cancel
          </Button>
          <Button onClick={handleSave}
                  className={classes.addButton}
                  disabled={saving}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      
    </>
  );
}

export default AddVerifiedDomainDialog;
