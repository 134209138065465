import React, { useMemo } from 'react';

import {
  TableCell, TableRow
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { AdminButton } from '../../../components';
import { cmToInFt, kgToLbs, prettyDateTime, toCapitalizeFromUnderScore } from '../../../lib/helpers/helpers';

const useStyles = makeStyles(theme => ({
  viewReportButton:{
    borderRadius: 25,
    minWidth: 80
  },
  tableRow: theme.globalClasses.adminTableRow
}));

const PatientLatestAssessmentRow = ({row, summary}) => {
  const classes = useStyles();
  
  const modifiedWorkflowURL = useMemo(()=>{
    if(row.workflowShareUrl){
      const url = row.workflowShareUrl;
      return url.slice(0, url.indexOf('&aggregate=1')) + url.slice(url.indexOf('#'));
    }
  },[row.workflowShareUrl]);
  
  return (
      <TableRow
        hover
        tabIndex={-1}
        id={row.id}
        key={row.id}
        className={classes.tableRow}
      >
        <TableCell align="left">{prettyDateTime(row.createdAt)}</TableCell>
        <TableCell align="left">{row.dietQuality ? row.dietName + " " + row.dietQuality : 'N/A'}</TableCell>
        <TableCell align="left">{row.idealQuality ? row.idealName + " " + row.idealQuality : 'N/A'}</TableCell>
        <TableCell align="left">{row.weightTrend ? toCapitalizeFromUnderScore(row.weightTrend) : 'N/A'}</TableCell>
        {!summary && <>
          <TableCell align="left">{row.dietHEI ?? 'N/A'}</TableCell>
          <TableCell align="left">{row.age ?? 'N/A'}</TableCell>
          <TableCell align="left">{row.height_cm ? cmToInFt(row.height_cm) : 'N/A'}</TableCell>
          <TableCell align="left">{row.weight_kg ? kgToLbs(row.weight_kg)+' lbs' : 'N/A'}</TableCell>
          <TableCell align="left">{row.gender ? toCapitalizeFromUnderScore(row.gender) : 'N/A'}</TableCell>
          
          <TableCell align="left">{row.preAndPost ? row.preAndPost.preType + " / " + row.preAndPost.postType : 'N/A'}</TableCell>
          <TableCell align="left">{row.preAndPost ? row.preAndPost.preQuality + " / " + row.preAndPost.postQuality : 'N/A'}</TableCell>
          <TableCell align="left">{row.preAndPost ? row.preAndPost.postQualityChange : 'N/A'}</TableCell>
          <TableCell align="left">{row.preAndPost ? row.preAndPost.prePostDays : 'N/A'}</TableCell>
          <TableCell align="left">{row.preAndPost ? row.preAndPost.postBmiChange : 'N/A'}</TableCell>
          <TableCell align="left">{row.preAndPost ? row.preAndPost.postWeightChange : 'N/A'}</TableCell>
          
        </>
        }
        <TableCell
          component="th"
          id={'cell' + row.id} align="center"
        >
          <AdminButton className={classes.viewReportButton} disabled={!row.workflowShareUrl} variant={'outlined'} onClick={() => window.open(modifiedWorkflowURL)}>{row.workflowShareUrl ? 'View' : 'N/A'}</AdminButton>
        </TableCell>
      </TableRow>
  );
};

export default PatientLatestAssessmentRow;
