import React, { useCallback } from 'react';
import { Card, CardContent, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import useMetric from '../../lib/hooks/useMetric';
import { ProgressBar } from '../ProgressBar';
import { toCapitalizeFromUnderScore } from '../../lib/helpers/helpers';
import {useParams} from "react-router";

const useStyles = makeStyles(theme => ({
  cardMobile: {
    cursor: 'pointer',
    width: '40%',
    height: 100,
    margin: 'auto 5% 25px',
    '& h3':{
      fontSize:28,
      textAlign:'center',
      fontWeight:'bold',
      color: theme.palette.primary.main
    },
    '& h5':{
      fontSize:13,
      textAlign:'center',
      display:'block',
      fontWeight:'bold',
      color: theme.palette.common.grey['500']
    },
  },
  card: {
    cursor: 'pointer',
    width: '20%',
    height: 100,
    margin: 'auto auto 25px',
    '& h3':{
      fontSize:28,
      textAlign:'center',
      fontWeight:'bold',
      color: theme.palette.primary.main
    },
    '& h5':{
      fontSize:13,
      textAlign:'center',
      display:'block',
      fontWeight:'bold',
      color: theme.palette.common.grey['500']
    },
  },
}));
const MetricCard = ({ slug}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const {campaignId} = useParams();
  
  const {isLoading, isRefetching, data, refetch} = slug ? useMetric(slug, null, campaignId) : false;
  const {title, value} = data ?? '';
  const refreshMetric = useCallback(() => refetch(),[])
  
  return (
        <Card className={matchSM ? classes.cardMobile : classes.card} onClick={refreshMetric}>
          <CardContent>
            {isLoading || isRefetching ?
            <ProgressBar style={{marginTop:33}}/>
              :
            <>
              <Typography variant={'h3'}>
                {value || 'N/A'}
              </Typography>
              <Typography variant={'h5'}>
                {title || toCapitalizeFromUnderScore(slug)}
              </Typography>
            </>
            }
          </CardContent>
        </Card>
  );
};

export default MetricCard;
