import React from 'react';
import {Grid, List, ListItem, Typography, makeStyles, useTheme, useMediaQuery, Button} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import cn from "classnames";

const useStyles = makeStyles(theme => ({
  title:{
    float:"right",
    textAlign:'right',
    '& span':{
      marginBottom:10,
      display:'block',
      fontStyle:'italic',
      fontSize:14,
      color: theme.palette.primary.light
    }
  },
  lastButton:{
    marginLeft:15
  },
  buttons:{
    '& *':{
    float:"right",
  }
  }
}));
const AccountCredits = ({expanded}) => {
  const classes = useStyles();
    return (
        <Grid container item xs={12} justifyContent={'space-between'}
              style={{margin:'70px auto auto',textAlign:'center'}}
        >
          <Grid item xs={12}>
            <Typography color="primary" className={expanded ? '' : classes.title}>Credits Remaining: {expanded && <br/>}<span>(Renews on: 11/15/22)</span></Typography><br/>
          </Grid>
           {!expanded && <Grid item xs={3}></Grid>}
          <Grid item xs={expanded ? 6 : 3} className={expanded ? '' : classes.buttons}>
            <Button size={'small'} variant={'contained'} color={'primary'}>Email: 41.5k</Button>
          </Grid>
          <Grid item xs={expanded ? 6 : 3} className={expanded ? '' : classes.buttons}>
            <Button size={'small'} variant={'contained'} color={'primary'}>SMS: 1,234</Button>
          </Grid>
          <Grid item xs={expanded ? 12 : 2} className={expanded ? '' : cn(classes.buttons, classes.lastButton)}>
            <Button size={expanded ? 'large' : 'small'} variant={'contained'} color={'primary'}><Add style={{fontSize:22}}/></Button>
          </Grid>
        </Grid>
    );
  };

export default AccountCredits;
