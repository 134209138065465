import React, { useState } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion, AccordionDetails, AccordionSummary,
  CardContent, Grid, Paper, Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import schena_webhook_phi_json from '../../../lib/constants/schema_webhook_phi.json'
import schena_webhook_phi_xml from '../../../lib/constants/schema_webhook_phi.xml'
import schena_webhook_non_phi_json from '../../../lib/constants/schema_webhook_non_phi.json'
import schena_webhook_non_phi_xml from '../../../lib/constants/schema_webhook_non_phi.xml'
import { readFileAsString } from '../../../lib/helpers/helpers';

const useStyles = makeStyles(theme => ({
  container:{
    margin:10
  },
  patientChartCard: {
    ...theme.globalClasses.patientChartCard,
    '& p':{
      marginTop:10,
    }
  },
    title:{
    fontSize:'24px !important',
    textAlign: 'left',
    margin: 'auto auto 20px'
  },
  root: {
  width: '100%',
},
  pre:{
    whiteSpace:'pre-wrap'
  },
  childAccordion:{
    display:'block'
  },
heading: {
  fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
},
headingGet: {
  fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  color: theme.palette.info.main,
},
  headingPost: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: theme.palette.success.main,
},
  headingPut: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: theme.palette.warning.main,
},
  headingDelete: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: theme.palette.error.main,
},
secondaryHeading: {
  fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
},
}));

const Schemas = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [subExpanded, setSubExpanded] = useState(false);
  
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleSubChange = (panel) => (event, isExpanded) => {
    setSubExpanded(isExpanded ? panel : false);
  };
  return (
    <Grid item xs={12}><a id={'schemas'}/>
      <Paper className={classes.patientChartCard}>
        <CardContent>
          <Typography variant={'h1'} className={classes.title}>Schemas</Typography>
          
  
          <Accordion expanded={expanded === 'webhook_phi'} onChange={handleChange('webhook_phi')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>Assessment Webhook</Typography>
              <Typography className={classes.secondaryHeading}>Webhook Schema including latest Personal Healthcare Information.</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.childAccordion}>
              <Accordion className={classes.childAccordion} expanded={subExpanded === 'webhook1'} onChange={handleSubChange('webhook1')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.headingPut}>JSON</Typography>
                  <Typography className={classes.secondaryHeading}></Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.childAccordion}>
                  <Grid container item xs={12} spacing={2} justifyContent={'space-between'}>
                    <Grid item xs={12}>
                      <textarea rows={25} cols={150}>
                      {JSON.stringify(schena_webhook_phi_json)}
                      </textarea>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={subExpanded === 'webhook2'} onChange={handleSubChange('webhook2')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.headingPut}>XML</Typography>
                  <Typography className={classes.secondaryHeading}></Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.childAccordion}>
                    <Grid container item xs={12} spacing={2} justifyContent={'space-between'}>
                      <Grid item xs={12}>
                      <textarea rows={25} cols={150}>
                        {readFileAsString(schena_webhook_phi_xml)}
                      </textarea>
                      </Grid>
                    </Grid>
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'webhook_non_phi'} onChange={handleChange('webhook_non_phi')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>Assessment Webhook (non-phi)</Typography>
              <Typography className={classes.secondaryHeading}>Webhook Schema for Organizations without access to Personal Healthcare Information.</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.childAccordion}>
              <Accordion className={classes.childAccordion} expanded={subExpanded === 'webhook1'} onChange={handleSubChange('webhook1')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.headingPut}>JSON</Typography>
                  <Typography className={classes.secondaryHeading}></Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.childAccordion}>
                  <Grid container item xs={12} spacing={2} justifyContent={'space-between'}>
                    <Grid item xs={12}>
                      <textarea rows={25} cols={150}>
                      {JSON.stringify(schena_webhook_non_phi_json)}
                      </textarea>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={subExpanded === 'webhook2'} onChange={handleSubChange('webhook2')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.headingPut}>XML</Typography>
                  <Typography className={classes.secondaryHeading}></Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.childAccordion}>
                  <Grid container item xs={12} spacing={2} justifyContent={'space-between'}>
                    <Grid item xs={12}>
                      <textarea rows={25} cols={150}>
                      {readFileAsString(schena_webhook_non_phi_xml)}
                      </textarea>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Paper>
    </Grid>
  );
};
export default Schemas;
