import React, { useContext } from 'react';

import { Grid, Typography } from "@material-ui/core";
import { Screen } from '../../components';
import { makeStyles } from "@material-ui/core/styles";
import { UserContext } from '../../lib/contexts';
import { WidgetTabs } from './components/index.js';
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
  },
}));

const WidgetsScreen = () => {
  const classes = useStyles();
  const {
    state: { first_name },
  } = useContext(UserContext)
  
  return (
    <Screen title={'Campaigns | SendBoth'} showTopNav={true} showFooter={true}>
          <Grid
            container
            alignItems="center"
            spacing={0}
            className={classes.screen}
          >
            <Typography variant={'subtitle1'}>
              Growth Widgets
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={0}
              className={classes.container}
            >
              <Typography component={'h2'} style={{textAlign:'center'}}>
                Take advantage of our embeddable widgets to grow your community!
              </Typography>
              <WidgetTabs/>
            </Grid>
          </Grid>
    </Screen>
  );
};
export default WidgetsScreen;
