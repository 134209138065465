import React, {useContext, useEffect, useState} from 'react';

import {Grid, Paper, Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { UserContext } from '../../lib/contexts';
import {
  AboutOrganization,
  AboutYou,
  APIKeys, BillingSettings,
  Design, NotificationSettings,
  OrganizationInvitations,
  OrganizationMembers,
  Sidebar, Support,
  VerifiedDomains
} from "./components";
import Screen from "../../components/Screen/Screen";
import {useParams} from "react-router";
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
  },
}));

const SettingsScreen = () => {
  const classes = useStyles();
  let { pageSlug } = useParams();
  const {
    state: { first_name },
  } = useContext(UserContext)
  
  const [selectedIndex, setSelectedIndex] = useState(0);
  useEffect(()=>{
    (() => {
      switch (pageSlug) {
        case 'account':
          setSelectedIndex(0);
          break;
        case 'design':
          setSelectedIndex(1);
          break;
        case 'organization':
          setSelectedIndex(2);
          break;
        case 'team':
          setSelectedIndex(3);
          break;
        case 'invitations':
          setSelectedIndex(4);
          break;
        case 'api_keys':
          setSelectedIndex(5);
          break;
        case 'domains':
          setSelectedIndex(6);
          break;
        case 'billing':
          setSelectedIndex(7);
          break;
        case 'notifications':
          setSelectedIndex(8);
          break;
        case 'support':
          setSelectedIndex(9);
          break;
        default:
          return true
      }
    })()
  },[pageSlug])
  return (
    <Screen title={'Settings | SendBoth'} showTopNav={true} showFooter={true}>
          <Grid
            container
            alignItems="center"
            spacing={0}
            className={classes.screen}
          >
            <Typography variant={'subtitle1'}>
              Settings
            </Typography>
            <Grid
              container
              item xs={12}
              direction="row"
              justifyContent="space-evenly"
              alignItems="flex-start"
              spacing={0}
              className={classes.container}
            >
              <Grid item xs={4}>
                <Sidebar selectedIndex={selectedIndex} />
              </Grid>
              <Grid item xs={8}>
                <Paper style={{padding:20}}>
                {
                  (() => {
                    switch (selectedIndex) {
                      case 0:
                        return <AboutYou/>
                      case 1:
                        return <Design/>
                      case 2:
                        return <AboutOrganization/>
                      case 3:
                        return <OrganizationMembers/>
                      case 4:
                        return <OrganizationInvitations/>
                      case 5:
                        return <APIKeys/>
                      case 6:
                        return <VerifiedDomains/>
                      case 7:
                        return <BillingSettings/>
                      case 8:
                        return <NotificationSettings/>
                      case 9:
                        return <Support/>
                      default:
                        return <AboutYou/>
                    }
    
                  })()
                }
                </Paper>
              </Grid>
              
            </Grid>
          </Grid>
    </Screen>
  );
};
export default SettingsScreen;
