import React, { useCallback, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box, Button, Grid, Select, MenuItem } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {
  FormHelperText,
  TextField, Typography,
  withStyles,
} from '@material-ui/core';
import { Add, Tune, Create, GroupAdd, Delete } from '@material-ui/icons';
import { postContactList } from '../../lib/api/postContactList';
import { ProgressBar } from '../ProgressBar';
import {postOrganizationInvitation} from "../../lib/api";

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    /* width: 'fit-content',*/
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientListSmall:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:180,
    height:44,
    margin:'20px auto 0px',
    fontSize:13,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    '& *':{
      color: theme.palette.primary.contrastText,
      display:'inline',
    }
  },
  addPatientListText:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    display:'block',
    margin:'40px auto 0px !important',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    marginTop:-25,
    marginRight:25,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  shareSwitch:{
    color: theme.palette.primary.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
  container:{
    maxHeight:250,
    overflowY: 'auto',
    margin:'20px auto 20px',
    textAlign:'center',
    '& *':{
      textAlign:'center',
    }
  },
  dynamicVarListItem:{
    marginBottom:20
  }
}));

const AdminGreenSwitch = withStyles(theme =>({
  switchBase: {
    color: theme.palette.primary.dark,
    '&$checked': {
      color: theme.palette.primary.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  checked: {},
  track: {},
}))(Switch);
const AdminGreenTextField = withStyles(theme =>({
  root: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
}))(TextField);

const AddOrganizationInviteDialog = ({forceOpen = false, setForceOpen, refetch, size, invisible, title = undefined}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(invisible);
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  
  useEffect(()=>{
    setOpen(forceOpen)
  },[forceOpen])
  
  const handleToggleOpen = useCallback(() => {
    setOpen(!open);
    if(forceOpen && open){
      setForceOpen(false)
    }
  },[open, forceOpen, setForceOpen]);
  
  
  const handleSave = useCallback(() => {
    const cleanName = name.trim();
    if(cleanName.length > 3){
      setSaving(true);
      const options = {
        name: cleanName,
        email,
        phone_number:phone
      }
      postOrganizationInvitation(options).then(()=>{
        setSaving(false);
        setOpen(false);
        setName('')
        refetch();
      })
    }
  },[name, email, phone]);
  
  
  return (
    <>
      {!invisible && <Button variant={'contained'} color={'primary'} onClick={handleToggleOpen} disabled={open || saving}>
        {title ?? 'New Invite'}
      </Button>}
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        open={open}
        onClose={handleToggleOpen}
        aria-labelledby="add-organization-invite-dialog-form" className={classes.form}
      >
        <DialogTitle id="add-organization-invite-dialog-title"><GroupAdd/>Invite a Teammate to your Organization</DialogTitle>
        <DialogContent>
          {
            saving ?
              <ProgressBar style={{margin:'50px auto 50px'}}/>
              :
              <>
                <DialogContentText>
                  Please enter your teammate&apos;s details. They will receive an invite to create an account and will then be able to join your organization via their Invite Code.
                </DialogContentText>
                <Grid container item xs={12} justifyContent={'space-between'} spacing={2}>
                <Grid item xs={12}>
                  <AdminGreenTextField
                     autoFocus={true}
                     value={name}
                     onChange={(event) => setName(event.target.value)}
                     variant={'outlined'}
                     fullWidth={true}
                     label={<><Create/> Name</>} placeholder={'e.g., Name'}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AdminGreenTextField
                     autoFocus={true}
                     value={email}
                     onChange={(event) => setEmail(event.target.value)}
                     variant={'outlined'}
                     fullWidth={true}
                     label={<><Create/> Email</>} placeholder={'e.g., Email'}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AdminGreenTextField
                     autoFocus={true}
                     value={phone}
                     onChange={(event) => setPhone(event.target.value)}
                     variant={'outlined'}
                     fullWidth={true}
                     label={<><Create/> Phone Number</>} placeholder={'e.g., Phone Number'}
                  />
                </Grid>
                  <Grid item xs={12}>
                    <FormHelperText style={{textAlign:'center'}}>Provide at least one form of contact above.</FormHelperText>
                  </Grid>
                </Grid>
              </>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleOpen}
                  className={classes.cancelButton}
                  disabled={saving}>
            Cancel
          </Button>
          <Button onClick={handleSave}
                  className={classes.addButton}
                  disabled={saving}>
            Send Invite
          </Button>
        </DialogActions>
      </Dialog>
      
    </>
  );
}

export default AddOrganizationInviteDialog;
