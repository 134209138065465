export const PUBLIC_URL = '/';
export const HOME_PAGE_ROUTE = '/dashboard';
export const CONTACTS_PAGE_ROUTE = '/contacts';
export const LISTS_PAGE_ROUTE = '/lists';
export const CAMPAIGNS_PAGE_ROUTE = '/campaigns';
export const REPORTS_PAGE_ROUTE = '/reports';
export const TEMPLATES_PAGE_ROUTE = '/templates';
export const WIDGETS_PAGE_ROUTE = '/widgets';
export const SETTINGS_PAGE_ROUTE = '/settings';
export const LOGIN_PAGE_ROUTE = '/login';
export const LOGOUT_PAGE_ROUTE = '/logout';

export const pageAccess = (userRole) => {
  let page_access = [];
  switch (userRole) {
    case 'admin':
    default:
      page_access = [
        { name: 'Dashboard', path: HOME_PAGE_ROUTE },
        { name: 'Contacts', path: CONTACTS_PAGE_ROUTE },
        { name: 'Lists', path: LISTS_PAGE_ROUTE },
        { name: 'Campaigns', path: CAMPAIGNS_PAGE_ROUTE },
        { name: 'Reports', path: REPORTS_PAGE_ROUTE },
        { name: 'Templates', path: TEMPLATES_PAGE_ROUTE },
        { name: 'Widgets', path: WIDGETS_PAGE_ROUTE },
      ];
      break;
  }
  return page_access;
}