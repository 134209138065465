import React, { useContext, useMemo, useState } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,  Paper, Tab,  Typography,
} from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import SelectPlanCard from './SelectPlanCard';
import { BillingContext } from '../../../lib/contexts/BillingContext';

const useStyles = makeStyles(theme => ({
  container:{
    margin:10
  },
  patientChartCard: {
    ...theme.globalClasses.patientChartCard,
    '& p':{
      marginTop:10,
    }
  },
  title:{
    fontSize:'24px !important',
    textAlign: 'center',
    margin: 'auto'
  },
}));

const SelectPlan = () => {
  const classes = useStyles();
  
  const {
    state: { chosenPlan, chosenSchedule },
    actions: { setChosenSchedule },
  } = useContext(BillingContext);
  
  const tab = useMemo(()=>chosenSchedule == 'year' ? 1 : 0,[chosenSchedule]);
  const handleTabChange = (event, newValue) => {
    setChosenSchedule(newValue == 0 ? 'monthly' : 'year')
  };
  const a11yProps = (index) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }
  return (
    <Grid item xs={8}>
      <Paper className={classes.patientChartCard}>
        <Grid item container xs={12} justifyContent={'space-around'}>
          <Typography variant={'h1'} className={classes.title}>Select a Plan</Typography>
          <Grid item container xs={12} justifyContent={'space-around'}>
            <TabContext value={tab}>
          <TabList
            value={tab}
            onChange={handleTabChange}
            aria-label="billing tabs"
            centered
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Billed Monthly" {...a11yProps(0)} />
            <Tab label="Billed Annually (Save 25%)" {...a11yProps(1)} />
          </TabList>
            </TabContext>
              <Grid item container xs={12} spacing={1} justifyContent={'space-between'} >
                {['starter', 'premium', 'enterprise'].map((type, index) => <SelectPlanCard key={index} type={type} />)}
              </Grid>
        </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default SelectPlan;
