import React, { useCallback } from 'react';
import {
  Card,
  Grid,
  CardContent,
  Typography,
  useTheme,
  useMediaQuery,
  ImageListItem,
  ListSubheader,
  IconButton,
  ImageListItemBar,
  Tooltip
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Info } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import useMetric from '../../lib/hooks/useMetric';
import { ProgressBar } from '../ProgressBar';
import {prettyDateTime, toCapitalizeFromUnderScore} from '../../lib/helpers/helpers';

const useStyles = makeStyles(theme => ({
  templateItem:{
    borderWidth:4,
    borderColor: 'transparent',
    borderStyle:'solid',
    cursor: 'pointer',
    width: 280,
    height: 150,
    display: 'flex',
    marginBottom:0,
  },
  templateItemActive:{
    borderWidth:4,
    borderColor: theme.palette.primary.light,
    borderStyle:'solid',
    cursor: 'pointer',
    width: 280,
    height: 150,
    display: 'flex',
    marginBottom:0,
  },
}));
const TemplateListing = ({ item, mode, onClick, campaignTemplate }) => {
  const { id, thumbnail, name, public:publicListing, updated_at:updatedAt, description } = item ?? {};
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  //const {isLoading, isRefetching, data, refetch} = slug ? useTemplate(slug) : false;
  //const refreshMetric = useCallback(() => refetch(),[])
  
  return (
    <Grid item onClick={onClick}>
      <Link to={mode === 'choosing' ? false : ''+id}>
  <ImageListItem key={id} className={item === campaignTemplate ? classes.templateItemActive : classes.templateItem}>
    <img src={thumbnail} alt={name} />
    <ImageListItemBar
      title={name}
      subtitle={publicListing ? <span>Featured</span> : <span>last edited: {prettyDateTime(updatedAt)}</span>}
      actionIcon={
        <IconButton aria-label={`info about ${name}`} className={classes.icon}>
          <Tooltip title={description || name}>
          <Info />
          </Tooltip>
        </IconButton>
      }
    />
  </ImageListItem>
      </Link>
    </Grid>
  );
};

export default TemplateListing;
