import React, { Component } from 'react';
import './Footer.css';
import { Grid, List, ListItem, Typography, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    margin:'auto auto -10px',
    paddingBottom:20
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    '& ul':{
      width:'100%',
    },
    '& li':{
      textAlign: 'center',
      display: 'block'
    },
  },
  patientLists:{
    marginTop:20,
  }
}));
const Footer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
    return (
      <footer className={classes.screen}>
        <Grid container item xs={12} className={classes.container} justifyContent={'space-between'}>
          <Grid item xs={matchSM ? 12 : 3}>
            <List>
              <ListItem>SendBoth vs Mailchimp</ListItem>
              <ListItem>Pricing</ListItem>
              <ListItem>API & Developers</ListItem>
            </List>
          </Grid>
          <Grid item xs={matchSM ? 12 : 3}>
            <List>
              <ListItem>FAQs</ListItem>
              <ListItem>Help</ListItem>
              <ListItem>Careers</ListItem>
            </List>
          </Grid>
          
          <Grid item xs={matchSM ? 12 : 3}>
            <List>
              <ListItem>Email marketing</ListItem>
              <ListItem>SMS marketing</ListItem>
              <ListItem>Free Email Templates</ListItem>
              <ListItem>Free SMS Templates</ListItem>
            </List>
          </Grid>
          
          <Grid item xs={matchSM ? 12 : 3}>
            <List>
              <ListItem>Transactional Emails</ListItem>
              <ListItem>Abandoned Cart</ListItem>
              <ListItem>Text Blast</ListItem>
              <ListItem>Mass Text Messaging</ListItem>
            </List>
          </Grid>
        </Grid>
        <p>
          Need help or have feedback? Email us at <a href="mailto:hello@SendBoth.com">hello@SendBoth.com</a>.
          <br />© 2022 SendBoth. All Rights Reserved.
          <br/><a href="#">privacy</a>&nbsp;|&nbsp;<a href="#">terms</a>&nbsp;|&nbsp;<a href="#">anti-spam</a>
          <br/>Message & data rates may apply in the US. Standard message and data rates apply in Canada.
          <br/>To unsubscribe from a SendBoth client&apos;s list, simply text &apos;STOP&apos; to the originating short code or <a href="mailto:hello@SendBoth.com">contact support</a>.
          <br/>SendBoth neither provides lists of phone numbers nor do we access our clients&apos; contact lists.
        </p>
      </footer>
    );
  };
export class AltFooter extends Component {
  render() {
    return (
      <footer>
        <p>© 2022 SendBoth. All Rights Reserved.</p>
      </footer>
    );
  }
}

export default Footer;
