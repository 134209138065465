import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import PaymentForm from 'elt-react-credit-cards';
import 'elt-react-credit-cards/es/styles-compiled.css';
import {
  FormControl, Grid,
  TextField,
  withStyles,
} from '@material-ui/core';
import { Payment } from '@material-ui/icons';
import { postPaymentMethod } from '../../../lib/api/postPaymentMethod';
import { AdminButton } from '../../../components';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { stripePromise } from './Stripe';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.admin.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientListSmall:{
    backgroundColor: theme.palette.admin.main,
    color: theme.palette.admin.light,
    width:180,
    height:44,
    margin:'20px auto 0px',
    fontSize:13,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.admin.dark,
      color: theme.palette.admin.light,
    },
    '& *':{
      color: theme.palette.admin.light,
      display:'inline',
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.admin.main,
    color: theme.palette.admin.light,
    width:214,
    height:50,
    marginTop:-25,
    marginRight:25,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.admin.dark,
      color: theme.palette.admin.light,
    }
  },
  shareSwitch:{
    color: theme.palette.admin.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.admin.main,
  },
}));

const AdminGreenSwitch = withStyles(theme =>({
  switchBase: {
    color: theme.palette.admin.dark,
    '&$checked': {
      color: theme.palette.admin.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.admin.main,
    },
  },
  checked: {},
  track: {},
}))(Switch);
const AdminGreenTextField = withStyles(theme =>({
  root: {
    borderColor: theme.palette.admin.main,
    color: theme.palette.admin.main,
    '&:hover': {
      borderColor: theme.palette.admin.main,
      color: theme.palette.admin.main,
    },
  },
}))(TextField);

const CreditCardDialogInner = () => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const [open, setOpen] = useState(false);
  const [cvc, setCVC] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [focused, setFocused] = useState(null);
  
  const [validName, setValidName] = useState(!!name.length || null)
  const [validEmail, setValidEmail] = useState(!!email.length || null)
  const [cardError, setCardError] = useState(null)
  
  const [submitting, setSubmitting] = useState(false)
  
  const handleToggleOpen = useCallback(() => {
    setOpen(!open);
  },[open]);
  
  const handleSave = useCallback(() => {
    if(cvc.length > 3 && expiryDate.length > 3 && cardNumber.length > 15 && name.length > 3){
      setSubmitting(true);
      postPaymentMethod(name,cardNumber,expiryDate,cvc).then(()=>{
        alert('Success!');
        window.location.reload();
      })
    }
  },[name,cardNumber,expiryDate,cvc]);
  
  const onSubmitButtonClick = async ({billing}) => {
  
    const isIncompleteExpired = (billing.subscription || {}).status === 'incomplete_expired'
    
    if (!validName) {
      setValidName(false)
      return
    }
    
    if (!validEmail) {
      setValidEmail(false)
      return
    }
    
    setCardError(null)
    setSubmitting(true)
    
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }
    
  //  setStripe(stripe)
    
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement)
    
    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    })
    
    if (error) {
      setCardError(
        error.message || 'An error occurred while processing your card. You have not been charged. Please try again.'
      )
    } else {/*
      const { success, result, message } = await createSubscription({
        paymentMethodId: paymentMethod.id,
        priceId: selectedPlan.id,
        name,
        email,
        isIncompleteExpired
      })*/
      let success = false
      let message = "false"
      if (success) {
        window.location.reload()
      } else {
        setCardError(message)
        setSubmitting(false)
      }
    }
  }
  
  const handleInputFocus = (e) => {
    setFocused(e.target.name);
  }
  
  const handleInputChangeExpireDate = (e) => {
    var  val="";
    if(e.target.value.length==3){
      val= e.target.value.slice(0, 2) + "/";
    }else{
      val= e.target.value;
    }
    setExpiryDate(val);
  }
  return (
    <>
      <AdminButton
        style={{marginTop:70}}
        fullWidth
        variant={'contained'} onClick={handleToggleOpen} disabled={open || submitting}
      >Check Out</AdminButton>
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        open={open}
        onClose={handleToggleOpen}
        aria-labelledby="add-patient-list-dialog-title" className={classes.form}
      >
        <DialogTitle id="add-patient-list-dialog-title"><Payment/> Billing Information</DialogTitle>
        <DialogContent>

            <PaymentForm
              cvc={cvc}
              expiry={expiryDate}
              focused={focused}
              name={name}
              number={cardNumber}
            />
            <form
              style={{margin:'auto',textAlign:'center'}}
            >
              <FormControl className={'patient_search_box'} style={{maxWidth:500,margin:'auto',display:'block'}}>
                <TextField
                  label="Debit/Credit Card Number"
                  placeholder="XXXX-XXXX-XXXX-XXXX"
                  margin="normal"
                  variant="outlined"
                  name="number"
                  type="text"
                  autocomplete="cc-number"
                  inputProps={{ maxLength: 16 }}
                  onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                  fullWidth={true}
                  onChange={event=>setCardNumber(event.target.value)}
                  value={cardNumber}
                  onFocus={handleInputFocus}
                  required
                />
              </FormControl>
              <FormControl className={'patient_search_box'} style={{maxWidth:500,margin:'auto',display:'block'}}>
                <TextField
                  label="Name on Card"
                  placeholder="John Smith"
                  margin="normal"
                  variant="outlined"
                  type="text"
                  autocomplete="cc-name"
                  onInput={(e) => { e.target.value = e.target.value.replace(/[^a-zA-Z ]/g, '') }}
                  name="name"
                  fullWidth={true}
                  onChange={event=>setName(event.target.value)}
                  value={name}
                  onFocus={handleInputFocus}
                  required
                />
              </FormControl>
              <FormControl className={'patient_search_box'} style={{maxWidth:500,margin:'auto'}}>
                <Grid container
                      direction="row"
                      justify="space-between"
                      alignItems="left"
                      spacing={0}>
                  <Grid item xs={5}>
                    <TextField
                      label="Expiration Date"
                      placeholder="12/12"
                      margin="normal"
                      variant="outlined"
                      type="text"
                      autocomplete="cc-exp"
                      inputProps={{ maxLength: 5 }}
                      name="expiry_date"
                      value={expiryDate}
                      fullWidth={true}
                      onChange={handleInputChangeExpireDate}
                      onFocus={handleInputFocus}
                      required/>
          
          
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      label="CVC"
                      placeholder="CVC"
                      margin="normal"
                      variant="outlined"
                      type="text"
                      autocomplete="cc-csc"
                      inputProps={{ maxLength: 4 }}
                      name="cvc"
                      fullWidth={true}
                      onChange={event=>setCVC(event.target.value)}
                      value={cvc}
                      onFocus={handleInputFocus}
                      required
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </form>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleOpen}
                  className={classes.cancelButton}
                  disabled={submitting}>
            Cancel
          </Button>
          <Button onClick={onSubmitButtonClick}
                  className={classes.addButton}
                  disabled={submitting}>
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
const CreditCardDialog = props => (
  <Elements stripe={stripePromise}>
    <CreditCardDialogInner {...props} />
  </Elements>
)

export default CreditCardDialog;