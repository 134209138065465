import React, { Component } from 'react';
import { Typography, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { PlanPicker } from './index'

const useStyles = makeStyles(theme => ({
  title:{
    marginTop:20,
    textAlign: 'center',
    display: 'block'
  }
}));
const ChoosePlan = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
    return (
      <>
        <Typography className={classes.title}>Last step! Choose the plan that fits your needs best. You may change or cancel your plan at anytime from the Account page.</Typography>
        <Typography className={classes.title}>60-Day Refund, No Questions Asked!*</Typography>
        <PlanPicker/>
      </>
    );
}

export default ChoosePlan;
