import React, {Component, useEffect, useMemo, useState} from 'react';
import {
  Select,
  MenuItem,
  InputLabel,
  Grid,
  List,
  ListItem,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
  FormControl,
  TextField,
  Divider, ListItemText, ListItemSecondaryAction, Button, Avatar
} from "@material-ui/core";
import { INDUSTRY_SECTORS } from '../../../lib/constants/constants'
import timezones from "../../../lib/constants/timezones";
import Autocomplete from "@material-ui/lab/Autocomplete";
import locales from "../../../lib/constants/locales";
import useUser from "../../../lib/hooks/useUser";
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    margin:'auto auto -10px',
    paddingBottom:20
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    '& ul':{
      width:'100%',
    },
    '& li':{
      textAlign: 'center',
      display: 'block'
    },
  },
  patientLists:{
    marginTop:20,
  },
  title:{
    marginTop:20,
    textAlign: 'center',
    display: 'block'
  }
}));
const AboutYou = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const {isLoading, isRefetching, data, refetch} = useUser();
  const user = useMemo(()=>data ? data.user : [],[data])
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [locale, setLocale] = useState('');
  const [timezone, setTimezone] = useState('');
  const [avatar, setAvatar] = useState('');
  
  useEffect(()=>{
    if(user){
      setFirstName(user.first_name)
      setLastName(user.last_name)
      setLocale(user.locale)
      setTimezone(user.timezone)
      setAvatar(user.profile_photo)
    }
  },[user])
 
    return (
      <>
      <Grid container spacing={1}>
        <Grid item xs={2} style={{textAlign:'center', alignItems:'center'}}>
          <FormControl variant="outlined">
            <Typography htmlFor="profile_pic">Profile Picture</Typography>
            <Avatar id="profile_pic" style={{width:80, height:80, margin:'50px auto auto'}} src={avatar}/>
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <TextField
            required={true}
            key='first_name'
            id='first_name'
            label='First Name'
            value={firstName}
            margin='normal'
            variant='outlined'
            fullWidth={true}
            type={'text'}
            onChange={()=>setFirstName(event.target.value)}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            required={true}
            key='last_name'
            id='last_name'
            label='Last Name'
            value={lastName}
            margin='normal'
            variant='outlined'
            fullWidth={true}
            type={'text'}
            onChange={()=>setLastName(event.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
        </Grid>
        <Grid item xs={5}>
          <Autocomplete
             id="locale-autocomplete"
             onChange={(e,v)=>setLocale(v)}
             options={locales}
             defaultValue={'en_US'}
             value={locale}
             getOptionLabel={(option) => option.name + " / " + (option.value || '').toUpperCase()}
             filterSelectedOptions
             groupBy={(option) => option ? option.name[0].toUpperCase() : undefined}
             renderInput={(params) => (
                <TextField
                   {...params}
                   variant="outlined"
                   label="Select Locale:"
                   placeholder="EN_US, FR_CA, etc"
                />
             )}
          />
        </Grid>
        <Grid item xs={5}>
          <Autocomplete
             id="timezone-autocomplete"
             onChange={(e,v)=>setTimezone(v)}
             options={timezones}
             defaultValue={'Eastern Standard Time'}
             value={timezone}
             getOptionLabel={(option) => option.text}
             filterSelectedOptions
             groupBy={(option) => option ? option.abbr[0].toUpperCase() : option.offset}
             renderInput={(params) => (
                <TextField
                   {...params}
                   variant="outlined"
                   label="Select Timezone:"
                   placeholder="ET, CT, etc"
                />
             )}
          />
        </Grid>
        <Grid item xs={12}>
          <List>
            <Divider style={{marginTop:20,marginBottom:20}}/>
            <ListItem>
              <ListItemText primary={''} secondary={''}/>
              <ListItemSecondaryAction>
                <Button variant={'contained'} color={'primary'}>Save</Button>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>

      </Grid>
      </>
    );
}

export default AboutYou;
