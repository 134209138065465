import React from 'react';
import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    margin:'10px 10px 10px 0px',
    color: theme.palette.admin.dark,
    '&$checked': {
      color: theme.palette.admin.dark,
    },
  },
}));
const AdminCheckbox = ({style, label, onChange, checked, name, clickable, disabled}) => {
  const classes = useStyles();
  return (
    <Checkbox color="default" disabled={disabled} clickable={clickable} onChange={onChange} checked={checked} name={name} label={label} className={classes.root} style={style} />
  );
};

export default AdminCheckbox;
