import React, { useState } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails, AccordionSummary,
   Box,
  Grid, Paper, Typography,
} from '@material-ui/core';
import {
  ArrowDropDown, ArrowDropUp, Check, Close,
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  container:{
    margin:10
  },
  patientChartCard: {
    ...theme.globalClasses.patientChartCard,
    '& p':{
      marginTop:10,
    },
    '& svg':{
      fontSize: '26px !important',
    }
  },
  accordion:{
    backgroundColor: 'transparent',
    borderBottom: 'transparent',
    textAlign: 'center',
    boxShadow: 'none',
    '& svg': {
      fontSize: 45,
      marginBottom: -14,
    },
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  
  starter:{marginTop:10, backgroundColor:theme.palette.admin.starter,},
  premium:{marginTop:10, backgroundColor:theme.palette.admin.premium},
  enterprise:{marginTop:10, backgroundColor:theme.palette.admin.enterprise},
  details:{
    marginTop: 96,
    fontWeight: 'bold !important',
    textAlign: 'left'
  },
  left:{
    fontWeight: 'bold',
    textAlign: 'left'
  },
  noBorder:{
    borderTopColor: 'transparent !important',
    borderWidth:'0px !important',
    borderTopStyle:'solid',
  },
  row:{
    borderRadius:5,
    '& p':{
      borderTopColor: theme.palette.admin.background,
      borderWidth:2,
      borderTopStyle:'solid',
      lineHeight:'16px',
      paddingTop:25,
      paddingBottom:15
    },
  },
  greenBar:{
    backgroundColor: theme.palette.admin.main,
    borderRadius: 5,
    height: 25,
    lineHeight: '5px',
    '& *':{
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: .03,
      marginLeft: 20,
      color: theme.palette.common.white
    }
  },
  price:{
    textAlign: 'center',
    '& h1':{
      marginTop: 20,
      fontSize: 22
    },
    '& p':{
      fontWeight: 'bold',
      marginTop: -15,
      marginBottom: 10,
      fontSize: '16px !important',
      borderTopColor: 'transparent',
      borderWidth:0,
      borderTopStyle:'solid',
    }
  }
}));

const SelectPlanMoreDetails = () => {
  const classes = useStyles();
  const [showMore, setShowMore] = useState(false);
  return (
    <Grid item xs={7}>
      <Accordion expanded={showMore} onChange={()=>setShowMore(!showMore)} className={classes.accordion}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant={'h2'}>More Details { showMore ? <ArrowDropUp/> : <ArrowDropDown/> }</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper className={classes.patientChartCard}>
            <Grid item container xs={12} spacing={1} justifyContent={'space-between'}>
              
              <Grid item xs={2}></Grid>
              <Grid item xs={3}><Typography variant={'h1'}>Starter</Typography></Grid>
              <Grid item xs={3}><Typography variant={'h1'}>Premium</Typography></Grid>
              <Grid item xs={3}><Typography variant={'h1'}>Enterprise</Typography></Grid>
              
              <Grid item xs={2} className={`${classes.row} ${classes.details}`}>
                <Typography variant={'body1'}>Dietary Assessment</Typography>
                <Typography variant={'body1'}>Goal Setting</Typography>
                <Typography variant={'body1'}>Daily Behavior Change Module</Typography>
                <Typography variant={'body1'}>Providers</Typography>
                <Typography variant={'body1'}>HIPAA Compliance</Typography>
              </Grid>
              
              <Grid item xs={3} className={`${classes.row} ${classes.starter}`}>
                <Box className={classes.price}>
                  <Typography variant={'h1'}>100</Typography>
                  <Typography>patients</Typography>
                </Box>
  
                <Typography><Check/></Typography>
                <Typography><Check/></Typography>
                <Typography><Close color={'disabled'}/></Typography>
                <Typography variant={'body1'}><b>1</b></Typography>
                <Typography><Check/></Typography>

              </Grid>
              
              <Grid item xs={3} className={`${classes.row} ${classes.premium}`}>
                <Box className={classes.price}>
                  <Typography variant={'h1'}>250</Typography>
                  <Typography>patients per provider</Typography>
                </Box>
                <Typography><Check/></Typography>
                <Typography><Check/></Typography>
                <Typography><Check/></Typography>
                <Typography variant={'body1'}><b>1st included</b><br/>Each additional admin $19 per month</Typography>
                <Typography><Check/></Typography>

              </Grid>
              
              <Grid item xs={3} className={`${classes.row} ${classes.enterprise}`}>
                <Box className={classes.price}>
                  <Typography variant={'h1'}>Custom</Typography>
                  <Typography>&nbsp;</Typography>
                </Box>
                <Typography><Check/></Typography>
                <Typography><Check/></Typography>
                <Typography><Check/></Typography>
                <Typography variant={'body1'}><b>Unlimited</b></Typography>
                <Typography><Check/></Typography>
              </Grid>
  
              <Grid item xs={12} container className={classes.greenBar}>
                <Typography>Branding</Typography>
              </Grid>
              <Grid item xs={12} container spacing={1} justifyContent={'space-between'}>
  
                <Grid item xs={2} className={`${classes.row} ${classes.left}`}>
                  <Typography variant={'body1'} className={classes.noBorder}>Basic</Typography>
                  <Typography variant={'body1'}>Advanced</Typography>
                  <Typography variant={'body1'}>Custom Signout Experience</Typography>
                </Grid>
                <Grid item xs={3} className={`${classes.row} ${classes.starter}`}>
                  <Typography className={classes.noBorder}><Check/></Typography>
                  <Typography><Close color={'disabled'}/></Typography>
                  <Typography><Close color={'disabled'}/></Typography>
                </Grid>
  
                <Grid item xs={3} className={`${classes.row} ${classes.premium}`}>
                  <Typography className={classes.noBorder}><Check/></Typography>
                  <Typography><Check/></Typography>
                  <Typography><Check/></Typography>
                </Grid>
  
                <Grid item xs={3} className={`${classes.row} ${classes.enterprise}`}>
                  <Typography className={classes.noBorder}><Check/></Typography>
                  <Typography><Check/></Typography>
                  <Typography><Check/></Typography>
                </Grid>
                
              </Grid>
              
            </Grid>
          </Paper>
        </AccordionDetails>
      </Accordion>
      
      
      
    </Grid>
  );
};

export default SelectPlanMoreDetails;
