import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.admin.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientListSmall:{
    backgroundColor: theme.palette.admin.main,
    color: theme.palette.admin.light,
    width:180,
    height:44,
    margin:'20px auto 0px',
    fontSize:13,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.admin.dark,
      color: theme.palette.admin.light,
    },
    '& *':{
      color: theme.palette.admin.light,
      display:'inline',
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.admin.main,
    color: theme.palette.admin.light,
    width:214,
    height:50,
    marginTop:-25,
    marginRight:25,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.admin.dark,
      color: theme.palette.admin.light,
    }
  },
  shareSwitch:{
    color: theme.palette.admin.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.admin.main,
  },
}));

const SetupPlanDialog = ({ setShowPlanRequiredDialog }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  
  const handleToggleOpen = useCallback(() => {
    setShowPlanRequiredDialog(false);
  },[setShowPlanRequiredDialog]);
  
  
  const navigateToBilling = useCallback(() => {
    navigate("../billing", {replace: true});
    setShowPlanRequiredDialog(false);
  },[setShowPlanRequiredDialog]);
  
  return (
    <>
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        open={true}
        onClose={handleToggleOpen}
        aria-labelledby="setup-plan-dialog-title" className={classes.form}
      >
        <DialogTitle id="setup-plan-list-dialog-title">Oops!</DialogTitle>
        <DialogContent>
              <>
                <DialogContentText>
                  It looks like you need to select a subscription plan and/or add a payment method to continue. Please visit the billing page.
                </DialogContentText>
              </>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={navigateToBilling}
                  className={classes.addButton}
          >
            Set Up Plan
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default SetupPlanDialog;