import React, { useCallback, useState } from 'react';

import {
  IconButton, Menu, MenuItem,
  TableCell, TableRow, TextField,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { prettyDateTime } from '../../../lib/helpers/helpers';
import useCurrentUser from '../../../lib/hooks/useCurrentUser';
import { MoreVert } from '@material-ui/icons';
import { deletePatientNote, updateTemplate } from '../../../lib/api';
import { AdminButton, ProgressBar } from '../../../components';

const useStyles = makeStyles(theme => ({
  viewReportButton:{
    borderRadius: 25,
    minWidth: 80
  },
  tableRow: theme.globalClasses.adminTableRow
}));

const PatientNotesRow = ({row, refetch}) => {
  const classes = useStyles();
  const { userID } = useCurrentUser()
  const [editMode, setEditMode] = useState(false)
  const [editedContent, setEditedContent] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const moreMenu = Boolean(anchorEl);
  
  const handleMenu = useCallback((event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  },[anchorEl]);
  
  const handleEdit = useCallback(() => {
    setEditMode(!editMode);
    setAnchorEl(null);
  },[editMode]);
  
  
  const deleteNote = useCallback(noteID => {
    const confirm = window.confirm('Confirm Note Deletion?')
    if(confirm){
      deletePatientNote(noteID).then(()=>{
        setAnchorEl(null);
        refetch();
      })
    }
  },[])
  
  const editNote = useCallback(noteID => {
    if(editedContent){
      setIsSaving(true);
      updateTemplate(noteID, editedContent).then(()=>{
        setAnchorEl(null);
        setIsSaving(false);
        setEditMode(false);
        refetch();
      })
    }
  },[editedContent])
  
  return (<>
      <TableRow
        hover
        tabIndex={-1}
        id={row.id}
        key={row.id}
        className={classes.tableRow}
      >
        <TableCell align="left">{prettyDateTime(row.createdAt)}</TableCell>
        <TableCell align="left">{row.ownerName ?? 'N/A'}</TableCell>
        <TableCell align="left">{editMode ? <TextField
          onChange={event => setEditedContent(event.target.value)}
          style={{height:100, width:'100%'}}
          multiline={true}
          defaultValue={row.content}
          value={editedContent}
        /> : row.content ?? 'N/A'}</TableCell>
        <TableCell align="left">{userID == row.owner ? !editMode ? <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleMenu}>
          <MoreVert/></IconButton> : <>
          {isSaving ?
            <ProgressBar style={{ width: '100%', margin: '20px auto 20px' }} /> :
            <AdminButton fullWidth
              style={{ marginBottom: 20 }}
              onClick={() => editNote(row.id)}
              disabled={!editedContent}
          >Save</AdminButton>}
        <AdminButton fullWidth onClick={()=>setEditMode(!editMode)} disabled={isSaving}>Cancel</AdminButton>
        </> : ''}
        </TableCell>
      </TableRow>
      <Menu
        id={'moreMenu_'+row.id}
        anchorEl={anchorEl}
        keepMounted
        open={moreMenu}
        onClose={handleMenu}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={()=>deleteNote(row.id)}>Delete</MenuItem>
      </Menu>
    </>
  );
};

export default PatientNotesRow;
