import React from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
  CardContent, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container:{
    margin:10
  },
  patientChartCard: {
    ...theme.globalClasses.patientChartCard,
    '& p':{
      marginTop:10,
    }
  },
  title:{
    fontSize:'24px !important',
    textAlign: 'left',
    margin: 'auto'
  },
}));

const CustomizeIntegration = () => {
  const classes = useStyles();
  return (
    <Grid item xs={12}><a id={'customize_integration'}/>
      <Paper className={classes.patientChartCard}>
        <CardContent>
          <Typography variant={'h1'} className={classes.title}>Customize Integration</Typography>
          <p>The following URL paramters can be included to hide specific features. Multiple parameters can also be included to hide multiple features, for example:</p>
          <div className="highlight">
            <pre className="highlight javascript">
            <code>
            <span className="dl">&#39;</span>
              <span className="s1">?view=nosidebar</span>
              <span className="dl">&#39;</span>
          </code>
            </pre>
          </div>
          <div className="highlight">
            <pre className="highlight javascript">
            <code>
              <span className="dl">&#39;</span>
              <span className="s1">?view=nosidebar,noprogressbar</span>
              <span className="dl">&#39;</span>
          </code>
            </pre>
          </div>
          <TableContainer component={Paper} style={{maxWidth:'60%',margin:'10px auto auto'}}>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Optional URL Paramters</TableCell>
                  <TableCell align="right">Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">nosidebar</TableCell>
                    <TableCell align="right">Hide the sidebar / navigation</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">noprogressbar</TableCell>
                    <TableCell align="right">Hide the assessment progress bar</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">nolanding</TableCell>
                    <TableCell align="right">Skips over the landing page</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">noonboarding</TableCell>
                    <TableCell align="right">Skips over the onboarding screen</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">nohelp</TableCell>
                    <TableCell align="right">Hide the Help page</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">nochat</TableCell>
                    <TableCell align="right">Hide the Chat / Support feature</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">nofeedback</TableCell>
                    <TableCell align="right">Hide the feedback feature</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">nofoodgroups</TableCell>
                    <TableCell align="right">Omits food group data</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">nocalories</TableCell>
                    <TableCell align="right">Omits calorie data</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">nodetails</TableCell>
                    <TableCell align="right">Hide additional details such as nutrition information</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">noworkflowresults</TableCell>
                    <TableCell align="right">Skips over the workflow results</TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          
        </CardContent>
      </Paper>
    </Grid>
  );
};
export default CustomizeIntegration;
