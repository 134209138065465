import React, { Component, useState } from 'react';
import { Select, MenuItem, InputLabel, Grid, List, ListItem, Typography, makeStyles, useTheme, useMediaQuery, FormControl, TextField } from "@material-ui/core";
import { INDUSTRY_SECTORS } from '../../lib/constants/constants'
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    margin:'auto auto -10px',
    paddingBottom:20
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    '& ul':{
      width:'100%',
    },
    '& li':{
      textAlign: 'center',
      display: 'block'
    },
  },
  patientLists:{
    marginTop:20,
  },
  title:{
    marginTop:20,
    textAlign: 'center',
    display: 'block'
  }
}));
const AboutYou = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [GA, setGA] = useState('');
  const [companySize, setCompanySize] = useState(10);
  
  const handleCompanyChange = (event) => {
    setCompanySize(event.target.value);
  };
  const handleTypeChange = (event) => {
    setCompanyType(event.target.value);
  };
    return (
      <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            required={true}
            key='first_name'
            id='first_name'
            label='First Name'
            value={firstName}
            margin='normal'
            variant='outlined'
            fullWidth={true}
            type={'text'}
            onChange={()=>setFirstName(event.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required={true}
            key='last_name'
            id='last_name'
            label='Last Name'
            value={lastName}
            margin='normal'
            variant='outlined'
            fullWidth={true}
            type={'text'}
            onChange={()=>setLastName(event.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required={true}
            key='company_name'
            id='company_name'
            label='Company Name'
            value={companyName}
            margin='normal'
            variant='outlined'
            fullWidth={true}
            type={'text'}
            onChange={()=>setCompanyName(event.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" style={{width:'100%', marginTop:16}}>
            <InputLabel id="company-size-label">Company Size</InputLabel>
            <Select
              required={true}
              labelId="company-size-label"
              id="company-size-outlined"
              value={companySize}
              onChange={handleCompanyChange}
              label="Company Size"
            >
              <MenuItem value={10}>Less than 10</MenuItem>
              <MenuItem value={50}>11-50</MenuItem>
              <MenuItem value={99}>51-99</MenuItem>
              <MenuItem value={100}>100+</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" style={{width:'100%', marginTop:16}}>
            <InputLabel id="company-size-label">Company Type</InputLabel>
            <Select
              required={true}
              labelId="company-type-label"
              id="company-type-outlined"
              value={companyType}
              onChange={handleTypeChange}
              label="Company Type"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {INDUSTRY_SECTORS.map((item, index)=>{
                return (<MenuItem key={index} value={item}>{item}</MenuItem>)
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            key='ga'
            id='ga'
            label='Google Analytics'
            placeholder={'UA-XXXXX'}
            value={GA}
            margin='normal'
            variant='outlined'
            fullWidth={true}
            type={'text'}
            onChange={()=>setGA(event.target.value)}
          />
        </Grid>
        
      </Grid>
      </>
    );
}

export default AboutYou;
