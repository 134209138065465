import React, { useContext } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
  Grid, Paper, Tooltip, Typography,
} from '@material-ui/core';
import { PatientTabsContext } from '../../../lib/contexts';
import { ProgressBar } from '../../../components/ProgressBar';
import useDiet from '../../../lib/hooks/useDiet';
import useMetric from '../../../lib/hooks/useMetric';

const useStyles = makeStyles(theme => ({
  patientChartCard: theme.globalClasses.patientChartCard,
  container:{
    margin:10
  },
  title:{
    fontSize:10,
  },
  itemBorder:{
    paddingTop:10,
    paddingBottom:10,
    borderWidth:'0px 1px 0px 0px',
    borderStyle:'solid',
    borderColor:theme.palette.admin.background
  },
  item:{
    textAlign:'center',
    '& p':{
      fontSize:20,
      marginBottom:10,
    }
  }
}));

const PatientGoalsBox = () => {
  const classes = useStyles();
  const {
    state: { currentTab },
  } = useContext(PatientTabsContext);
  let { data } = useMetric('goals', currentTab.id);
  data = data ? data.metric.value : data;
  const { data: diet } = useDiet(data && data.idealCode ? data.idealCode : false, data && data.idealQuality ? data.idealQuality : false, currentTab.id);
  
  return (
    <Grid item xs={12}>
      <Paper className={classes.patientChartCard}>
        <Typography variant={'h2'}>Goals</Typography>
        {!data ?
          <>{data && !data.idealCode ? <Typography>User did not finish Ideal Workflow.</Typography> : <ProgressBar />}</>
           :
          <>
            <Grid xs={12}
                  container item className={classes.container}>
              <Grid item
                    xs={6} className={`${classes.item} ${classes.itemBorder}`}>
                <Typography variant={'subtitle2'} className={classes.title}>Calories</Typography>
                {diet && <Typography>{diet.data.adjustedCalories != "" ? diet.data.adjustedCalories.toLocaleString({}) : diet.data.calories.toLocaleString({}) }</Typography>}
              </Grid>
              <Grid item
                    xs={6} className={classes.item}>
                <Typography variant={'subtitle2'} className={classes.title}>Diet Style</Typography>
                {diet && <Tooltip title={diet.data.description}>
                  <Typography>{diet.data.name} / {diet.data.quality}</Typography>
                </Tooltip>}
              </Grid>
            </Grid>
            {data && <>
              <Typography>{data.value}</Typography>
            </>}
          </>}
      </Paper>
    </Grid>
  );
};

export default PatientGoalsBox;
