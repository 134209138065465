import * as React from 'react';
import {Box, Grid, Typography, Fab, ListItem, ListItemIcon, ListItemText, Divider, Collapse} from "@material-ui/core";
import {
   AccountBalance, AccountCircle,
   Business,
   DateRange, ExpandLess, ExpandMore, Group,
   GroupAdd,
   Inbox,
   LiveHelp, NotificationsActive,
   Palette,
   Print, VerifiedUser,
   VpnKey
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {useCallback, useContext, useState} from "react";
import DateRangeFilterDialog from "../../../components/DateRangeFilterDialog/DateRangeFilterDialog";
import {UserContext} from "../../../lib/contexts";
import {prettyDate, minusMonth} from "../../../lib/helpers/helpers";
import List from "@material-ui/core/List";
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
   root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      '& nav':{
         padding: '0px !important',
         margin: '0px !important'
      }
   },
   nested: {
      paddingLeft: theme.spacing(4),
   },
}));

export default function Sidebar({selectedIndex}) {
   const classes = useStyles();
   let navigate = useNavigate();
   const [expandOrg, setExpandOrg] = React.useState(true);
   
   const handleClick = () => {
      setExpandOrg(!open);
   };
   const {
      state: {
         showingRange,
         startDate,
         endDate
      },
      actions: {
         setShowingRange
      }
   } = useContext(UserContext);
   
   const handleListItemClick = (event, index) => {
      (() => {
         switch (index) {
            case 0:
               navigate("../settings/account", { replace: true })
               break;
            case 1:
               navigate("../settings/design", { replace: true })
               break;
            case 2:
               navigate("../settings/organization", { replace: true })
               break;
            case 3:
               navigate("../settings/team", { replace: true })
               break;
            case 4:
               navigate("../settings/invitations", { replace: true })
               break;
            case 5:
               navigate("../settings/api_keys", { replace: true })
               break;
            case 6:
               navigate("../settings/domains", { replace: true })
               break;
            case 7:
               navigate("../settings/billing", { replace: true })
               break;
            case 8:
               navigate("../settings/notifications", { replace: true })
               break;
            case 9:
               navigate("../settings/support", { replace: true })
               break;
            default:
               return true
         }
      })()
   };
   
  return (
     <div className={classes.root}>
        <List component="nav" aria-label="settings sidebar">
           <ListItem
              button
              selected={selectedIndex === 0}
              onClick={(event) => handleListItemClick(event, 0)}
           >
              <ListItemIcon>
                 <AccountCircle />
              </ListItemIcon>
              <ListItemText primary="About You" />
           </ListItem>
           <ListItem
              button
              selected={selectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 1)}
           >
              <ListItemIcon>
                 <Palette />
              </ListItemIcon>
              <ListItemText primary="Design" />
           </ListItem>
           <ListItem button
                     selected={selectedIndex === 2}
                     onClick={(event) => {
                        handleListItemClick(event, 2);
                        //handleClick();
                     }}>
              <ListItemIcon>
                 <Business />
              </ListItemIcon>
              <ListItemText primary="Organization" />
              {expandOrg ? <ExpandLess /> : <ExpandMore />}
           </ListItem>
           <Collapse in={expandOrg} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                 <ListItem button className={classes.nested}
                           selected={selectedIndex === 3}
                           onClick={(event) => handleListItemClick(event, 3)}>
                    <ListItemIcon>
                       <Group />
                    </ListItemIcon>
                    <ListItemText primary="Manage Teammates" />
                 </ListItem>
                 <ListItem button className={classes.nested}
                           selected={selectedIndex === 4}
                           onClick={(event) => handleListItemClick(event, 4)}>
                    <ListItemIcon>
                       <GroupAdd />
                    </ListItemIcon>
                    <ListItemText primary="Manage Invitations" />
                 </ListItem>
              </List>
           </Collapse>
           
           <ListItem
              button
              selected={selectedIndex === 5}
              onClick={(event) => handleListItemClick(event, 5)}
           >
              <ListItemIcon>
                 <VpnKey />
              </ListItemIcon>
              <ListItemText primary="API Keys" />
           </ListItem>
           <ListItem
              button
              selected={selectedIndex === 6}
              onClick={(event) => handleListItemClick(event, 6)}
           >
              <ListItemIcon>
                 <VerifiedUser />
              </ListItemIcon>
              <ListItemText primary="Verified Domains" />
           </ListItem>
           <ListItem
              button
              selected={selectedIndex === 7}
              onClick={(event) => handleListItemClick(event, 7)}
           >
              <ListItemIcon>
                 <AccountBalance />
              </ListItemIcon>
              <ListItemText primary="Billing Settings" />
           </ListItem>
           <ListItem
              button
              selected={selectedIndex === 8}
              onClick={(event) => handleListItemClick(event, 8)}
           >
              <ListItemIcon>
                 <NotificationsActive />
              </ListItemIcon>
              <ListItemText primary="Notification Settings" />
           </ListItem>
        </List>
        <Divider />
        <List component="nav" aria-label="support">
           <ListItem
              button
              selected={selectedIndex === 9}
              onClick={(event) => handleListItemClick(event, 9)}
           >
              <ListItemIcon>
                 <LiveHelp />
              </ListItemIcon>
              <ListItemText primary="Support" />
           </ListItem>
        </List>
     </div>
  );
}
