import { createContext } from 'react';
import usePartner from '../hooks/usePartner';

export const PartnerContext = createContext({});
export const PartnerContextProvider = ({ children, props }) => {
  const urlSplit = window.location.host.split('.');
  const partnerObject = urlSplit.length > 1 ? usePartner(urlSplit[0]) : {};
  return (
    <PartnerContext.Provider
      value={{
        actions: {},
        state: {
          ...partnerObject,
          userRole: partnerObject && partnerObject.current_user && partnerObject.current_user.user_role ? partnerObject.current_user.user_role : ''
        },
      }}
      {...props}
    >
      {children}
    </PartnerContext.Provider>
  );
};
