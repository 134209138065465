import * as React from 'react';
import PropTypes from 'prop-types';
import {
  alpha,
  Box,
  IconButton, Paper, Table, TableBody,
  TableCell, TableContainer,
  TableHead, TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useCallback, useContext, useMemo, useState } from 'react';
import { V1DataContext } from '../../../lib/contexts/V1DataContext';
import { makeStyles } from '@material-ui/core/styles';
import { ProgressBar } from '../../../components/ProgressBar';
import { PatientSearchBar } from './index';
import usePatientData from '../../../lib/hooks/usePatientData';
import { Goals } from '../../../lib/helpers/dietIdHelpers';
import PatientTableRow from './PatientTableRow';
import { PatientTabsContext } from '../../../lib/contexts';

const useStyles = makeStyles(theme => ({
  progressBarContainer:{
    width: '80%',
    margin: 'auto'
  },
  viewReportButton:{
    borderRadius: 25,
    minWidth: 80
  }
}));

function createData(name,
                    latestAssessment,
                    email,
                    latestID,
                    qualityChange,
                    goals,
                    bmi,
                    userRole) {
  return {
    name,
    latestAssessment,
    email,
    latestID,
    qualityChange,
    goals,
    bmi,
    userRole
  };
}

const rowsTest = [
  createData('Jane Cupcake', 305, 3.7, 67, 4.3, 1, 2, 'Admin'),
  createData('Joe Donut', 452, 25.0, 51, 4.9, 1, 2, 'Billing'),
  createData('John Eclair', 262, 16.0, 24, 6.0, 1, 2, 'Virtual'),
  createData('Joanna Yoghurt', 159, 6.0, 24, 4.0, 1, 2, 'Super User'),
  createData('Ginger Bread', 356, 16.0, 49, 3.9, 1, 2, 'Admin'),
  createData('Honey Comb', 408, 3.2, 87, 6.5, 1, 2, 'Admin'),
  createData('Ice Cream', 237, 9.0, 37, 4.3, 1, 2, 'Admin'),
  createData('Jelly Bean', 375, 0.0, 94, 0.0, 1, 2, 'Admin'),
  createData('Kit Kat', 518, 26.0, 65, 7.0, 1, 2, 'Admin'),
  createData('Lolli Pop', 392, 0.2, 98, 0.0, 1, 2, 'Admin'),
  createData('Marsh Mallow', 318, 0, 81, 2.0, 1, 2, 'Admin'),
  createData('Nougat Center', 360, 19.0, 9, 37.0, 1, 2, 'Admin'),
  createData('Oreo Cookie', 437, 18.0, 63, 4.0, 1, 2, 'Admin'),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'report',
    numeric: false,
    disablePadding: true,
    label: 'Report',
  },
  {
    id: 'latestAssessment',
    numeric: true,
    disablePadding: false,
    label: 'Latest Assessment',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'latestID',
    numeric: true,
    disablePadding: false,
    label: 'Latest ID',
  },
  {
    id: 'qualityChange',
    numeric: true,
    disablePadding: false,
    label: 'Quality Change',
  },
  {
    id: 'goals',
    numeric: true,
    disablePadding: false,
    label: 'Goals',
  },
  {
    id: 'bmi',
    numeric: true,
    disablePadding: false,
    label: 'BMI',
  },
  {
    id: 'userRole',
    numeric: true,
    disablePadding: false,
    label: 'User Role',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  
  return (
    <TableHead>
      <TableRow>
        {/*
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all patients',
            }}
          />
        </TableCell>*/}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 &&
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      }
      
      
      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton>
            <Delete />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const PatientLatestResults = () => {
  
  const {
    state: { currentTab },
  } = useContext(PatientTabsContext);
  
  const { isLoading, data } = usePatientData();
  const rows = !isLoading && data ? data : undefined;
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
  const [patientStringFilter, setPatientStringFilter] = useState(undefined)
  const [patientListFilter, setPatientListFilter] = useState(undefined)
  
  const filteredRows = useMemo(() => {
    if(rows){
      let tempRows = rows
      const lowerString = patientStringFilter ? patientStringFilter.toLowerCase() : '';
      if(lowerString){
        tempRows = tempRows.filter((row) => (
            row.email && row.email.toLowerCase().includes(lowerString) ||
            row.username && row.username.toLowerCase().includes(lowerString) ||
            row.firstName && row.firstName.toLowerCase().includes(lowerString) ||
            row.lastName && row.lastName.toLowerCase().includes(lowerString) ||
            row.id && String(row.id).includes(lowerString) ||
            row.partnerId && String(row.partnerId).includes(lowerString)
          )
        )
      }
      if(patientListFilter){
        tempRows = tempRows.filter((row) => patientListFilter.indexOf(row.id) !== -1)
      }
      return tempRows
    } else{
      return []
    }
  },[rows, patientStringFilter, patientListFilter])
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  const handleSelectAllClick = (event) => {
    const {
      state: { data },
    } = useContext(V1DataContext);
    const rows = data.data;
    
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    
    setSelected(newSelected);
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 && filteredRows ? Math.max(0, (1 + page) * rowsPerPage - filteredRows.length) : 0;
  
  return (
    <>
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
{/*
        <EnhancedTableToolbar numSelected={selected.length} />
*/}
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filteredRows ? filteredRows.length : 0}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {filteredRows &&
                stableSort(filteredRows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (<PatientTableRow key={'PatientTableRow_'+index} id={'PatientTableRow_'+index} row={row} handleClick={handleClick} selected={selected}/> );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        
        {isLoading &&
        <Box
          className={classes.progressBarContainer}>
          <ProgressBar style={{margin:'250px auto 250px'}}/>
        </Box>
        }
        
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRows ? filteredRows.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
    </>
  );
}
export default PatientLatestResults;
