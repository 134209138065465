import React, { ReactChildren, ReactChild, useEffect } from 'react';
import { Box } from '@material-ui/core';
import cn from 'classnames';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import { APISideBarNavigation, SideBarNavigation, TopBarNavigation } from '../';

export interface Props {
  title?: string;
  showHeader?: boolean;
  showFooter?: boolean;
  showSidebarNav?: boolean;
  showBreadcrumbs?: any;//[{name: string, path: string}];
  showTopNav?: boolean;
  showAPISidebarNav?: boolean;
  elevated?: boolean;
  showPaddingTop?: boolean;
  noBackground?: boolean;
  className?: string;
  children?: ReactChild | ReactChildren;
}

const useStyles = makeStyles(theme => ({
  root: {
    '& a':{
      color: 'inherit',
    },
    '& a:visited':{
      color: 'inherit',
    },
    '& a:hover':{
      color: 'inherit',
      textDecoration: 'underline',
    }
  },
  crumbs:{
    marginTop:-50,
  },
  screenPaddingOn: {
    padding: 16,
    '@media print':{
      padding:0,
    }
  },
  screenPaddingOff: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  elevatedBox: {
    backgroundColor: '#ff0044',
    paddingTop: theme.spacing(5),
  },
  childrenBox: {
    minHeight: '75vh', // @ts-ignore
    background: theme.palette.common.grey['100'],
  },
  childrenBoxNoBackground: {
    marginLeft:'0vh',
    minHeight: '100vh', // @ts-ignore
    minWidth: '-webkit-fill-available', // @ts-ignore
    background: 'transparent',
  },
  screenWithSidebar:{
    marginLeft:230,
    '@media print':{
      marginLeft:0,
    }
  },
  screenWithAPISidebar:{
    marginLeft:250,
    '@media print':{
      marginLeft:0,
    }
  }
}));

const Screen = ({
  title,
  showHeader,
  showFooter,
  showSidebarNav,
  showBreadcrumbs,
  showAPISidebarNav,
  showTopNav,
  children,
  className,
  elevated = true,
  showPaddingTop = true,
  noBackground
}: Props) => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className={showSidebarNav ? classes.screenWithSidebar : showAPISidebarNav ? classes.screenWithAPISidebar : ''}>
      {showHeader && <Header title={title} />}
      {showSidebarNav && <SideBarNavigation />}
      {showAPISidebarNav && <APISideBarNavigation />}
      {showTopNav && <TopBarNavigation />}
      <Box
        className={cn(
            classes.root,
          elevated ? classes.screenPaddingOn : classes.screenPaddingOff,
          showPaddingTop && classes.elevatedBox,
          noBackground ? classes.childrenBoxNoBackground : classes.childrenBox,
          className,
        )} style={{paddingBottom:150}}
      >
        <div className={classes.crumbs}>
        {showBreadcrumbs &&
        <Breadcrumbs crumbs={showBreadcrumbs} />}
        </div>
        {children}
      </Box>
      {showFooter && <Footer />}
    </Box>
  );
};

export default Screen;
