import { lighten } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core';
import { opacity } from './utils';

const common = {
  black: '#0b0b0c',
  white: '#ffffff',
  red: '#ff0000',
  increase: '#BEF2D7',
  decrease: '#F9CDBA',
  border: 'rgba(0,0,0,0.28)',
  grey: {
    50: '#FAFAFC',
    100: '#F5F5F7',
    200: '#EEEEF2',
    300: '#DEDEE2',
    400: '#BDBDC2',
    500: '#9E9EA8',
    600: '#757575',
    700: '#757575',
    800: '#424247',
    900: '#212126',
  },
};
const palette = (primaryColor, secondaryColor) => {
  return {
    common: common,
    primary: {
      main: primaryColor || '#355c7d',
      dark: '#2972e0',
      light: '#4779a6',
      contrastText: '#d6e8f6',
      background: lighten('#536dfe', 0.92),
      backgroundHover: lighten('#536dfe', 0.5),
      border: '#2972e0',
    },
    secondary: {
      main: secondaryColor || '#ea9c87',
      dark: '#2972e0',
      light: '#2972e0',
      contrastText: '#ffffff',
      background: lighten('#536dfe', 0.92),
      backgroundHover: lighten('#536dfe', 0.5),
      border: '#2972e0',
    },
    admin: {
      main: '#1C6262',
      dark: '#164543',
      light: '#C4DCCC',
      bodyText: '#5B7B79',
      contrastText: '#C6DFCE',
      background: '#c4d8d7',
      backgroundHover: '#1D6362',
      border: '#164543',
      tab: '#B4CCCB',
      activeTab: '#DFE9E9',
      trendChart: '#eff4f4',
      starter: '#EFF6F2',
      premium: '#E5F1E8',
      enterprise: '#D9EADD',
      recommended: '#e2a787',
    },
    error: {
      main: '#DA4045',
      dark: '#A74145',
      light: '#EBB1B3',
      contrastText: '#ffffff',
      background: lighten('#DA4045', 0.92),
      backgroundHover: lighten('#DA4045', 0.5),
      border: '#A74145',
    },
    warning: {
      main: '#f8ab3a',
      dark: '#c38147',
      light: '#ffc960',
      contrastText: '#ffffff',
      background: lighten('#f8ab3a', 0.92),
      backgroundHover: lighten('#f8ab3a', 0.5),
      border: '#c38147',
    },
    success: {
      main: '#45ae57',
      dark: '#3d954c',
      light: '#60ba6f',
      contrastText: '#ffffff',
      background: lighten('#68b76b', 0.92),
      backgroundHover: lighten('#68b76b', 0.5),
      border: '#3d954c',
    },
    info: {
      main: '#536dfe',
      dark: '#25448f',
      light: '#9fa8da',
      contrastText: '#ffffff',
      background: lighten('#536dfe', 0.92),
      backgroundHover: lighten('#536dfe', 0.5),
      border: '#25448f',
    },
    background: {
      paper: '#ffffff',
      default: '#fafafa',
    },
  };
};

const theme = (primaryColor, secondaryColor) => {
  const finalPalette = palette(primaryColor, secondaryColor);
  
  return createTheme({
    themeName: 'Light',
    palette: finalPalette,
    globalClasses:{
      screen:{
        width:'96%',
        margin:'10px auto auto',
      },
      adminTableHead:{
        borderRadius: 5,
        color: finalPalette.primary.main,
        backgroundColor: finalPalette.primary.activeTab,
        '& *':{
          color: finalPalette.primary.main,
          fontWeight: 'bold',
        },
        '& .MuiTableSortLabel-active':{
          color: finalPalette.primary.dark,
        }
      },
      adminTableRow:{
        borderRadius: 5,
        color: finalPalette.primary.bodyText,
        backgroundColor: finalPalette.primary.trendChart,
        '& *':{
          color: finalPalette.primary.bodyText,
        },
        '& .MuiTableSortLabel-active':{
          color: finalPalette.primary.dark,
        }
      },
      adminTableRowWhite:{
        borderRadius: 5,
        color: finalPalette.primary.bodyText,
        backgroundColor: finalPalette.common.white,
        '& *':{
          color: finalPalette.primary.bodyText,
        },
        '& .MuiTableSortLabel-active':{
          color: finalPalette.primary.dark,
        }
      },
      patientChartCard:{
        cursor: 'pointer',
        color: finalPalette.common.grey['600'],
        padding: createTheme().spacing(1),
        width:'100%',
        height:'auto',
        '& h1':{
          color: finalPalette.primary.main,
          fontWeight: 'bold',
          fontSize: 22
        },
        '& h2':{
          color: finalPalette.primary.main,
          fontWeight: 'bold',
          fontSize: 14
        },
        '& h3':{
          color: finalPalette.primary.main,
          fontWeight: 'bold',
          fontSize: 12
        },
        '& a':{
          color: finalPalette.primary.main,
          fontWeight: 'bold',
        },
        '& textarea':{
          borderRadius:5,
          padding:5,
        }
      },
    },
    typography: {
      useNextVariants: true,
      htmlFontSize: 16,
      fontFamily: '"SharpBook19", "Helvetica", "Arial", sans-serif',
      fontSize: 14,
      fontWeightLight: 400,
      fontWeightRegular: 400,
      fontWeightMedium: 400,
      fontWeightBold: 400,
      h1: {},
      h2: {
        fontWeight: 600,
      },
      h3: {},
      h4: {},
      h5: {},
      h6: {},
      body1: {
        color: finalPalette.primary.bodyText,
        '& b':{
          color: finalPalette.primary.main,
          fontWeight:'bold'
        }
      },
      body2: {
        color: finalPalette.primary.bodyText,
      },
      subtitle1: {
        fontWeight: 600,
        width: '100%',
        marginTop: 100,
        marginBottom: 50,
        fontSize: 24,
        color: finalPalette.primary.main
      },
      subtitle2: {},
      helper: {
        primary: finalPalette.common.grey['100'],
        secondary: opacity(finalPalette.common.grey['100'], 0.54),
        light: '#DFE9E9',
      },
    },
    overrides: {
      MuiButton: {
        root: {},
        contained: {},
        outlined: {},
        text: {},
        sizeSmall: {},
        sizeLarge: {},
      },
    },
    breakpoints: {
      values: {
        xs: 320,
        sm: 641,
        md: 960,
        lg: 1080,
        xl: 1920,
      },
    },
  });
};
export default theme;
