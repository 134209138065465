import { useQuery } from 'react-query';
import {getOrganization} from "../api";

const useOrganization = () => {
  const result = useQuery('organization', () => getOrganization(), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useOrganization;
