import React, {Component, useEffect, useState} from 'react';
import {
  Select,
  MenuItem,
  InputLabel,
  Grid,
  List,
  ListItem,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
  FormControl,
  TextField,
  Divider, ListItemText, ListItemSecondaryAction, Button
} from "@material-ui/core";
import { INDUSTRY_SECTORS } from '../../../lib/constants/constants'
import Autocomplete from "@material-ui/lab/Autocomplete";
import locales from "../../../lib/constants/locales";
import timezones from "../../../lib/constants/timezones";
import useOrganization from "../../../lib/hooks/useOrganization";
import useUser from "../../../lib/hooks/useUser";
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    margin:'auto auto -10px',
    paddingBottom:20
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    '& ul':{
      width:'100%',
    },
    '& li':{
      textAlign: 'center',
      display: 'block'
    },
  },
  patientLists:{
    marginTop:20,
  },
  title:{
    marginTop:20,
    textAlign: 'center',
    display: 'block'
  }
}));
const AboutOrganization = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const {isLoading: isLoadingOrganization, isRefetching: isRefetchingOrganization, data: organization, refetchOrganization} = useOrganization();
  
  
  const [companyName, setCompanyName] = useState('');
  const [companySubdomain, setCompanySubdomain] = useState('');
  const [defaultReply, setDefaultReply] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [companyPhone, setCompanyPhone] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [companyLocale, setCompanyLocale] = useState('');
  const [companyTimezone, setCompanyTimezone] = useState('');
  const [companyDescription, setCompanyDescription] = useState('');
  const [GA, setGA] = useState('');
  
  useEffect(()=>{
    if(organization){
      setCompanyName(organization.name)
      setCompanySubdomain(organization.subdomain)
      setDefaultReply(organization.default_email)
      setCompanyWebsite(organization.website)
      setCompanyPhone(organization.phone_number)
      setCompanySize(organization.organization_size)
      setCompanyType(organization.organization_type)
      setCompanyDescription(organization.description)
      setGA(organization.google_analytics)
    }
  },[organization])
  
  const handleCompanyChange = (event) => {
    setCompanySize(event.target.value);
  };
  const handleTypeChange = (event) => {
    setCompanyType(event.target.value);
  };
    return (
      <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
  
          <TextField
             required={true}
             key='company_name'
             id='company_name'
             label='Company Name'
             value={companyName}
             margin='normal'
             variant='outlined'
             fullWidth={true}
             type={'text'}
             onChange={()=>setCompanyName(event.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
             required={true}
             key='Subdomain'
             id='Subdomain'
             label='Subdomain'
             value={companySubdomain}
             margin='normal'
             variant='outlined'
             fullWidth={true}
             type={'text'}
             onChange={()=>setCompanySubdomain(event.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required={true}
            key='website'
            id='website'
            label='Website'
            value={companyWebsite}
            margin='normal'
            variant='outlined'
            fullWidth={true}
            type={'text'}
            onChange={()=>setCompanyWebsite(event.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required={true}
            key='phone'
            id='phone'
            label='Phone'
            value={companyPhone}
            margin='normal'
            variant='outlined'
            fullWidth={true}
            type={'text'}
            onChange={()=>setCompanyPhone(event.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
             id="locale-autocomplete"
             onChange={(e,v)=>setCompanyLocale(v)}
             options={locales}
             value={companyLocale}
             defaultValue={companyLocale}
             getOptionLabel={(option) => option.name + " / " + (option.value || '').toUpperCase()}
             filterSelectedOptions
             groupBy={(option) => option ? option.name[0].toUpperCase() : undefined}
             renderInput={(params) => (
                <TextField
                   {...params}
                   variant="outlined"
                   label="Select Locale:"
                   placeholder="EN_US, FR_CA, etc"
                />
             )}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
             id="timezone-autocomplete"
             onChange={(e,v)=>setCompanyTimezone(v)}
             options={timezones}
             value={companyTimezone}
             defaultValue={companyTimezone}
             getOptionLabel={(option) => option.text}
             filterSelectedOptions
             groupBy={(option) => option ? option.abbr[0].toUpperCase() : option.offset}
             renderInput={(params) => (
                <TextField
                   {...params}
                   variant="outlined"
                   label="Select Timezone:"
                   placeholder="ET, CT, etc"
                />
             )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
             required={true}
             key='DefaultReply'
             id='DefaultReply'
             label='Default Reply Email'
             value={defaultReply}
             margin='normal'
             variant='outlined'
             fullWidth={true}
             type={'text'}
             onChange={()=>setDefaultReply(event.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" style={{width:'100%', marginTop:16}}>
            <InputLabel id="company-size-label">Company Size</InputLabel>
            <Select
              required={true}
              labelId="company-size-label"
              id="company-size-outlined"
              value={companySize}
              onChange={handleCompanyChange}
              label="Company Size"
            >
              <MenuItem value={'1-10'}>Less than 10</MenuItem>
              <MenuItem value={'11-50'}>11-50</MenuItem>
              <MenuItem value={'51-99'}>51-99</MenuItem>
              <MenuItem value={'100'}>100+</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" style={{width:'100%', marginTop:16}}>
            <InputLabel id="company-size-label">Company Type</InputLabel>
            <Select
              required={true}
              labelId="company-type-label"
              id="company-type-outlined"
              value={companyType}
              onChange={handleTypeChange}
              label="Company Type"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {INDUSTRY_SECTORS.map((item, index)=>{
                return (<MenuItem key={index} value={item}>{item}</MenuItem>)
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            key='description'
            id='description'
            label='Company Description'
            placeholder={'We produce...'}
            value={companyDescription}
            margin='normal'
            variant='outlined'
            fullWidth={true}
            type={'text'}
            onChange={()=>setCompanyDescription(event.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            key='ga'
            id='ga'
            label='Google Analytics'
            placeholder={'UA-XXXXX'}
            value={GA}
            margin='normal'
            variant='outlined'
            fullWidth={true}
            type={'text'}
            onChange={()=>setGA(event.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <List>
            <Divider style={{marginTop:20,marginBottom:20}}/>
            <ListItem>
              <ListItemText primary={''} secondary={''}/>
              <ListItemSecondaryAction>
                <Button variant={'contained'} color={'primary'}>Save</Button>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>
      </Grid>
      </>
    );
}

export default AboutOrganization;
