import React, {useCallback, useState, useMemo, useContext, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {
  Button, Grid, Paper,
  TextField, Typography,
  withStyles,
} from '@material-ui/core';
import { Add, Tune, Create, Drafts } from '@material-ui/icons';
import { postCampaign } from '../../lib/api/postCampaign';
import { UserContext, CampaignContext } from '../../lib/contexts';
import { ProgressBar } from '../ProgressBar';
import {AddCampaignStepper, CampaignSettings, PickTemplate, PickReceipient, PickSchedule, Review} from './components/';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    minWidth: 'fit-content',
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientListSmall:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:180,
    height:44,
    margin:'20px auto 0px',
    fontSize:13,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    '& *':{
      color: theme.palette.primary.contrastText,
      display:'inline',
    }
  },
  addPatientListText:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    display:'block',
    margin:'40px auto 0px !important',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    marginTop:-25,
    marginRight:25,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  selectedBox:{
    '& > *': {
      margin: 'auto',
      textAlign: 'center',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  fields:{
    marginBottom:50,
  },
  boxes:{
    padding:20,
    marginTop:5,
    marginBottom:20,
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      '& > *': {
        margin: theme.spacing(4),
        width: theme.spacing(16),
        height: theme.spacing(16),
        textAlign: 'center',
        display:'inline-flex',
        color: theme.palette.primary.main,
        '&:hover':{
          cursor:'pointer',
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
        '& > *': {
          margin: 'auto',
          textAlign: 'center',
      },
      },
    },
  },
  
}));

const AddCampaignDialog = ({forceOpen = false, setForceOpen, refetch, size, invisible, title = undefined}) => {
  const classes = useStyles();
  const {
    state: {
      selectedCampaigns,
      saving,
      campaignType,
      step,
      campaignTemplate,
       deliveryDate
    },
    actions: {
      setCampaignType,
      setStep,
       setSaving
    },
  } = useContext(CampaignContext)
  
  const [open, setOpen] = useState(invisible);
  
  useEffect(()=>{
    setOpen(forceOpen)
  },[forceOpen])
  
  const handleToggleOpen = useCallback(() => {
    setOpen(!open);
    if(forceOpen && open){
      setForceOpen(false)
    }
    if(!open==false){
      setSaving(false)
    }
  },[open, forceOpen]);
  
  return (
    <>
      {!invisible && <Button className={size == 'small' ? classes.addPatientListSmall : size == 'text' ? classes.addPatientListText : classes.addPatientList} variant={'contained'}
               onClick={handleToggleOpen} disabled={open}>
        {size != 'text' && <Add/>} {size == 'text' && <Typography>{title ?? 'Add Campaign'}</Typography>}
      </Button>}
      <Dialog
        maxWidth={step === 2 ? 'lg' : 'md'}
        fullWidth={true}
        open={open}
        onClose={step === 0 && !campaignTemplate ? handleToggleOpen : ()=>{}}
        aria-labelledby="add-campaign-dialog-title" className={classes.form}
      >
        <DialogTitle id="add-campaign-dialog-title"><Drafts/> {saving ? 'Saving' : 'Add a new'} Campaign</DialogTitle>
        <DialogContent>
          {
            saving === '' ?
              <ProgressBar style={{margin:'50px auto 50px'}}/>
              :
              <>
                <DialogContentText>{
                  (() => {
                    switch (step) {
                      case 0:
                        return (
                           <Typography>
                             Which type of Campaign do you want to create?
                           </Typography>
                        )
                      case 1:
                        return (
                           <Typography>
                             Choose your Campaign&apos;s Template
                           </Typography>
                        )
                      case 2:
                        return (
                           <Typography>
                             Select the Campaign&apos;s Recipients
                           </Typography>
                        )
                      case 3:
                        return (
                           <Typography>
                             Schedule when your Campaign will be delivered
                           </Typography>
                        )
                      case 4:
                      default:
                        return (<></>
                        )
                    }
    
                  })()
                }
                </DialogContentText>
                {step === 0 && <Grid
                   item
                   xs={12}
                   container
                   direction="row"
                   justifyContent="space-between"
                   alignItems="center"
                   spacing={1}
                   className={classes.boxes}>
                  <Grid item xs={4} style={{textAlign: 'center'}}
                        className={campaignType === 'email' ? classes.selectedBox : ''}>
                    <Paper elevation={3} variant="outlined" onClick={() => setCampaignType('email')}>
                      <Typography>Send Emails</Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={4} style={{textAlign: 'center'}}
                        className={campaignType === 'sms' ? classes.selectedBox : ''}>
                    <Paper xs={4} elevation={1} variant="outlined" onClick={() => setCampaignType('sms')}>
                      <Typography>Send SMS</Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={4} style={{textAlign: 'center'}}
                        className={campaignType === 'both' ? classes.selectedBox : ''}>
                    <Paper xs={4} elevation={1} variant="outlined" onClick={() => setCampaignType('both')}>
                      <Typography>Send Both</Typography>
                    </Paper>
                  </Grid>
                </Grid>}
  
                {campaignType && <form noValidate>
                  <Grid
                     item
                     xs={12}
                     direction="row"
                     justifyContent="space-between"
                     alignItems="center"
                     spacing={2}
                  className={classes.fields}>
                    {
                      (() => {
                        switch (step) {
                          case 0:
                            return (
                               <>
                                 <CampaignSettings/>
                               </>
                            )
                          case 1:
                            return (
                               <>
                                 <PickTemplate/>
                               </>
                            )
                          case 2:
                            return (
                               <>
                                 <PickReceipient/>
                               </>
                            )
                          case 3:
                            return (
                               <>
                                 <PickSchedule/>
                               </>
                            )
                          case 4:
                          default:
                            return (
                               <Review/>
                            )
                        }
    
                      })()
                    }
                  </Grid>
                </form>}
              </>
          }
          {campaignType && <AddCampaignStepper handleToggleOpen={handleToggleOpen} refetch={refetch}/>}
          
        </DialogContent>
      </Dialog>
    </>
  );
}
export default AddCampaignDialog;
