import React, {useCallback, useEffect, useState} from 'react';
import {
  Box,
  List,
  ListItem,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
  ListItemText, ListItemSecondaryAction, Button, Switch, Divider, IconButton, Menu, MenuItem
} from "@material-ui/core";
import {AddAPIKey, AddOrganizationInviteDialog, CopyAPIKey, ProgressBar} from '../../../components'
import useOrganizationInvitations from "../../../lib/hooks/useOrganizationInvitations";
import {updateOrganizationInvitation} from "../../../lib/api";
import useOrganizationTeammates from "../../../lib/hooks/useOrganizationTeammates";
import {MoreVert} from "@material-ui/icons";
import ManagePermissionsDialog from "../../../components/ManagePermissionsDialog/ManagePermissionsDialog";
import useOrganization from "../../../lib/hooks/useOrganization";
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    margin:'auto auto -10px',
    paddingBottom:20
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    '& ul':{
      width:'100%',
    },
    '& li':{
      textAlign: 'center',
      display: 'block'
    },
    '& a':{
      color: theme.palette.primary.main,
      textDecoration: 'none'
    },
  },
  patientLists:{
    marginTop:20,
  },
  title:{
    marginTop:20,
    textAlign: 'center',
    display: 'block'
  }
}));
const OrganizationMembers = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const [menuAnchor, setMenuAnchor] = useState(undefined);
  const [apiKey, setApiKey] = useState(undefined);
  const [saving, setSaving] = useState(false);
  const [openPermissions, setOpenPermissions] = useState(false);
  const {isLoading, isRefetching, data: teammates, refetch} = useOrganizationTeammates();
  const {isLoading: isLoadingOrganization, isRefetching: isRefetchingOrganization, data: organization, refetchOrganization} = useOrganization();
  
  const toggleMenu = useCallback((event) => {
    setMenuAnchor(menuAnchor ? null : event.currentTarget);
  },[menuAnchor])
  
  useEffect(()=>{
      setMenuAnchor(null)
  },[openPermissions])
  
  const handleTeammateUpdate = useCallback((invite) => {
    if(invite){
      setSaving(true);
      const options = {
        active: false,
      }
      updateOrganizationInvitation(invite.id, options).then(()=>{
        setSaving(false);
        refetch()
      })
    }
  },[refetch])
  
  return (
       <Box className={classes.container}>
         <Typography className={classes.title}>Easily manage your team members and their permissions.</Typography>
         {
           isLoading || isRefetching || isLoadingOrganization || isRefetchingOrganization || saving ?
              <ProgressBar style={{margin:150}}/>
              :
              teammates.length ?
                 <List>
                   {teammates.map(item=>{
                     return <ListItem key={item.id}>
                       <ListItemText primary={item.first_name+' '+item.last_name}
                                     secondary={
                         item.email ? item.email : '' +
                         item.email && item.phone_number + ' | ' +
                         item.phone_number ? item.phone_number : ''
                       }/>
                       <ListItemSecondaryAction>
                         {item.id === organization.creator && <Button variant={'contained'} color={'secondary'}>Super Admin</Button>}
                         {item.id !== organization.creator && <Button variant={'contained'} color={'primary'} style={{marginRight:5}} onClick={() => setOpenPermissions(item)}>Permissions</Button>}
                         {item.id !== organization.creator && <Button variant={'contained'} color={'standard'} style={{marginLeft:5}}>Remove</Button>}
                       </ListItemSecondaryAction>
                     </ListItem>
                   })}
                   
                   <Divider style={{marginTop:20,marginBottom:20}}/>
                   <ListItem>
                     <ListItemText primary={''} secondary={''}/>
                     <ListItemSecondaryAction>
                       <AddOrganizationInviteDialog refetch={refetch}/>
                     </ListItemSecondaryAction>
                   </ListItem>
                 </List>
                 :
                 <>
                   <Typography className={classes.title}>Let&apos;s send your first invite now.</Typography>
                   <AddOrganizationInviteDialog refetch={refetch}/>
                 </>
         }
         {openPermissions !== undefined && <ManagePermissionsDialog forceOpen={Boolean(openPermissions)} setForceOpen={setOpenPermissions} user={openPermissions}
                                   invisible={true}/>}
       </Box>
    );
}

export default OrganizationMembers;
