import React, { useContext } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Grid,
  IconButton,
  List,
  ListItem,  ListItemIcon,
  ListItemSecondaryAction, ListItemText,
} from '@material-ui/core';
import { PatientTabsContext } from '../../../lib/contexts/';
import { Close, Person } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  container:{
    display:'inline-flex',
    overflowX: 'scroll',
    marginLeft:25,
    '@media print':{
      display:'none',
    }
  },
  patientTab:{
    //marginRight:25,
    '*':{
      cursor:'pointer',
      color: theme.palette.admin.main,
      fontSize:14,
    },
    '& button':{
      marginLeft:10
    }
  },
  activeTab:{
    cursor: 'pointer',
    borderTopLeftRadius:5,
    borderTopRightRadius:5,
    backgroundColor: theme.palette.admin.activeTab,
    fontWeight:500
  },
  nonActiveTab:{
    cursor: 'pointer',
    borderTopLeftRadius:5,
    borderTopRightRadius:5,
    backgroundColor: theme.palette.admin.tab
  },
  listAvatar:{
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize:12,
  },
  listAvatarIcon:{
    marginRight:0
  }
}));

const PatientTabs = () => {
  const classes = useStyles();
  const {
    state: { activeTab, openTabs },
    actions: { setActiveTab, closePatientTab },
  } = useContext(PatientTabsContext);
  return (
    <Grid item className={classes.container}>
      {/*<ButtonGroup variant="contained" color="primary" aria-label="patient tab buttons">*/}
      <List dense
            style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
      {openTabs.length > 0 && openTabs.map((item) => {
        return <ListItem
                         key={'patientChip_'+item.uuid}
                         className={activeTab === item.uuid ? classes.activeTab : classes.nonActiveTab}
                         
        >
          <ListItemIcon className={classes.listAvatarIcon} onClick={()=>setActiveTab(item.uuid)}>
            <Avatar className={classes.listAvatar}>{item && item.firstName ? item.firstName[0] : <Person/>}</Avatar>
          </ListItemIcon>
          <ListItemText
            primary={item && item.firstName + ' ' + item.lastName}
            onClick={()=>setActiveTab(item.uuid)}
          />
        <ListItemSecondaryAction>
          <IconButton
            size={'small'}
            edge="start" aria-label="close tab"
            onClick={()=>closePatientTab(item.uuid)}><Close/></IconButton>
        </ListItemSecondaryAction>
        </ListItem>
/*
        return <ButtonGroup
          variant="contained"
          aria-label="split button"
          key={'patientChip_'+index}
          className={classes.patientTab}
        >
          <Button
            className={activeTab === index ? classes.activeTab : classes.nonActiveTab}
            onClick={()=>setActiveTab(index)}
          >
            <Avatar>{item && item.firstName[0]}</Avatar>&nbsp;{item && item.firstName + ' ' + item.lastName}
          </Button>
          <Button
            className={activeTab === index ? classes.activeTab : classes.nonActiveTab}
            size="small"
            aria-label="close tab"
            onClick={()=>closePatientTab(index)}
          >
            <Close />
          </Button>
        </ButtonGroup>
        */
        
 /*
        return <Chip
          key={'patientChip_'+index}
          className={classes.patientTab}
          color={activeTab === index ? 'primary' : 'default'}
          onClick={()=>setActiveTab(index)}
          onDelete={()=>closePatientTab(index)}
          avatar={<Avatar>{item && item.firstName[0]}</Avatar>}
          label={item && item.firstName + ' ' + item.lastName} />*/
      })}
      </List>
    </Grid>
  );
};

export default PatientTabs;
