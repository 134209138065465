import React, {useState, useContext, useCallback, useMemo} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DialogActions, Stepper} from '@material-ui/core/';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CampaignContext } from '../../../lib/contexts';
import { replaceTime } from '../../../lib/helpers/helpers';
import { postCampaign } from '../../../lib/api/postCampaign';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    float:'right',
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
}));

function getSteps() {
  return ['Campaign Settings', 'Choose Template', 'Select Contacts', 'Schedule', 'Review'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return 'Select campaign settings...';
    case 1:
      return 'What is an ad group anyways?';
    case 2:
      return 'This is the bit I really care about!';
    default:
      return 'Unknown step';
  }
}
const AddCampaignStepper = ( { handleToggleOpen, refetch }) => {
  const {
    state: {
      step: activeStep,
      saving,
      campaignType,
      campaignName,
      campaignSubject,
      campaignTemplate,
      campaignSmsTemplate,
      campaignRecipientLists,
      campaignRecipientContacts,
      campaignFromName,
      campaignFromEmail,
      deliveryDate,
      deliveryTime,
      batchDelivery,
      batchCount,
      batchTime,
      timewarpDelivery,
      aiDelivery,
      recurringType,
    },
    actions: {
      setStep,
      setSaving,
    },
  } = useContext(CampaignContext);
  
  const classes = useStyles();
  const [skipped, setSkipped] = useState(new Set());
  const steps = getSteps();
  
  const campaignData = useMemo(()=>{
    return {
    //campaign_type: campaignType,
    name: campaignName ? campaignName.trim() : null,
    template_id: campaignTemplate ? campaignTemplate.id : null,
    sms_template_id: campaignSmsTemplate ?? null,
    subject: campaignSubject ? campaignSubject.trim() : null,
    from_name: campaignFromName ? campaignFromName.trim() : null,
    from_email: campaignFromEmail ? campaignFromEmail.trim() : null,
    delivery_date: deliveryTime ? replaceTime(deliveryDate,deliveryTime) : deliveryDate,
    //recurring_type: recurringType,
    ai_delivery: aiDelivery,
    timewarp_delivery: timewarpDelivery,
    batch_delivery: batchDelivery,
    batch_count: batchCount,
    batch_time: batchTime,
    contact_lists: campaignRecipientLists.length ? campaignRecipientLists.map(item=>item.id) : null,
    contacts: campaignRecipientContacts.length ? campaignRecipientContacts : null,
       }
  },[
    campaignType,
    campaignName,
    campaignSubject,
    campaignTemplate,
    campaignSmsTemplate,
    campaignRecipientLists,
    campaignRecipientContacts,
    campaignFromName,
    campaignFromEmail,
    deliveryDate,
    deliveryTime,
    batchDelivery,
    batchCount,
    batchTime,
    timewarpDelivery,
    aiDelivery,])
  
  const isStepOptional = (step) => {
    return false; //step === 1;
  };
  
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    
    setStep((activeStep) => activeStep + 1);
    setSkipped(newSkipped);
  };
  
  const handleBack = () => {
    setStep((prevActiveStep) => prevActiveStep - 1);
  };
  
  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }
    
    setStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };
  
  const handleReset = () => {
    setStep(0);
  };
  
  const handleTest = useCallback(() => {
    if(deliveryDate){
      setSaving(true);
      postCampaign(campaignData, true).then(()=>{
        setSaving(false);
      })
    }
  },[campaignData, deliveryDate]);
  
  const handleSave = useCallback(() => {
    if(deliveryDate){
      setSaving(true);
      postCampaign(campaignData, false).then(()=>{
        setSaving(false);
          refetch();
          handleToggleOpen();
      })
    }
  },[campaignData, deliveryDate]);
  
  return (
     <div className={classes.root}>
       <Stepper activeStep={activeStep}>
         {steps.map((label, index) => {
           const stepProps = {};
           const labelProps = {};
           if (isStepOptional(index)) {
             labelProps.optional = <Typography variant="caption">Optional</Typography>;
           }
           if (isStepSkipped(index)) {
             stepProps.completed = false;
           }
           return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
           );
         })}
       </Stepper>
       <div>
         {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed - you&apos;re finished
              </Typography>
              <Button onClick={handleReset} className={classes.button}>
                Reset
              </Button>
            </div>
         ) : (
            <div>
              
              <div style={{display:'none'}}>
                {isStepOptional(activeStep) && (
                   <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSkip}
                      className={classes.button}
                   >
                     Skip
                   </Button>
                )}
                
                <Button
                   variant="contained"
                   color="primary"
                   onClick={handleNext}
                   className={classes.button}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                  Back
                </Button>
              </div>
            </div>
         )}
       </div>
       <DialogActions>
         <Button className={classes.cancelButton}
                 onClick={activeStep === 0 ? handleToggleOpen : handleBack}
                 disabled={(activeStep === 4 && saving)}>
           {activeStep === 0 ? 'Cancel' : 'Back'}
         </Button>
         {activeStep === steps.length - 1 && <Button className={classes.addButton}
                  onClick={handleTest}
                  disabled={(saving)}>
           {'Send Test'}
         </Button>}
         <Button onClick={activeStep === steps.length - 1 ? handleSave : handleNext}
                 className={classes.addButton}
                 disabled={
                   (activeStep === 0 && (!campaignName || !campaignType)) ||
                   (activeStep === 1 && (!campaignTemplate)) ||
                   (activeStep === 2 && (!campaignRecipientLists.length && !campaignRecipientContacts.length)) ||
                   (activeStep === 3 && (!deliveryDate || !deliveryTime )) ||
                   (activeStep === 4 && (saving))
                 }>
           {activeStep === steps.length - 1 ? 'Publish Campaign' : 'Next'}
         </Button>
       </DialogActions>
     </div>
  );
}
export default AddCampaignStepper;
