import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CloudDownload } from '@material-ui/icons';
import { ProgressBar } from '../ProgressBar';
import { toCapitalize } from '../../lib/helpers/helpers';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.admin.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
}));

const DownloadProgressDialog = ({ type }) => {
  const classes = useStyles();
  return (
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        open={true}
        aria-labelledby="add-patient-list-dialog-title" className={classes.form}
      >
        <DialogTitle id="add-patient-list-dialog-title"><CloudDownload/> Downloading {toCapitalize(type)} Export</DialogTitle>
        <DialogContent>
              <ProgressBar style={{margin:'50px auto 50px'}}/>
                <DialogContentText>
                  Just a moment!
                </DialogContentText>
        </DialogContent>
      </Dialog>
  );
}
export default DownloadProgressDialog;
