import React, { useState } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion, AccordionDetails, AccordionSummary,
  CardContent, Grid, Paper, Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  container:{
    margin:10
  },
  patientChartCard: {
    ...theme.globalClasses.patientChartCard,
    '& p':{
      marginTop:10,
    }
  },
    title:{
    fontSize:'24px !important',
    textAlign: 'left',
    margin: 'auto auto 20px'
  },
  root: {
  width: '100%',
},
  pre:{
    whiteSpace:'pre-wrap'
  },
  childAccordion:{
    display:'block'
  },
heading: {
  fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
},
headingGet: {
  fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  color: theme.palette.info.main,
},
  headingPost: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: theme.palette.success.main,
},
  headingPut: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: theme.palette.warning.main,
},
  headingDelete: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: theme.palette.error.main,
},
secondaryHeading: {
  fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
},
}));

const APIEndpoints = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [subExpanded, setSubExpanded] = useState(false);
  
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleSubChange = (panel) => (event, isExpanded) => {
    setSubExpanded(isExpanded ? panel : false);
  };
  return (
    <Grid item xs={12}><a id={'api_endpoints'}/>
      <Paper className={classes.patientChartCard}>
        <CardContent>
          <Typography variant={'h1'} className={classes.title}>API Endpoints</Typography>
          
  
          <Accordion expanded={expanded === 'sso'} onChange={handleChange('sso')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>SSO</Typography>
              <Typography className={classes.secondaryHeading}>Endpoints related to creating a patient and logging in.</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.childAccordion}>
              <Accordion className={classes.childAccordion} expanded={subExpanded === 'sso1'} onChange={handleSubChange('sso1')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.headingPost}>POST</Typography>
                  <Typography className={classes.secondaryHeading}>/api/v3/sso</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.childAccordion}>
                  <Grid container item xs={12} spacing={2} justifyContent={'space-between'}>
                    <Grid item xs={2}><h3>Example Request</h3></Grid>
                    <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                    </code>
                      </pre>
                      </Grid>
                    <Grid item xs={2}><h3>Example Response</h3></Grid>
                    <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                        &#123;
                        &quot;success&quot;: true,
                        &quot;url&quot;: &quot;https://abc123.sendboth.com/?u=ABC123...789XYZ&quot;
                        &#125;
                    </code>
                      </pre>
                      </Grid>
                    <Grid item xs={2}><h3>Error Response</h3></Grid>
                    <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                      &#123;
                      &quot;success&quot;: &quot;false&quot;,
                      &quot;message&quot;: &quot;Invalid client secret. Please check your Authorization header (Authorization: \&quot;`Bearer xxx\&quot;`)&quot;
                      &#125;
                    </code>
                      </pre>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={subExpanded === 'sso2'} onChange={handleSubChange('sso2')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.headingPost}>POST</Typography>
                  <Typography className={classes.secondaryHeading}>/api/v1/sso - DEPRECATING 2023</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.childAccordion}>
                  <Typography>
                    <Grid container item xs={12} spacing={2} justifyContent={'space-between'}>
                      <Grid item xs={2}><h3>Example Request</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                    </code>
                      </pre>
                      </Grid>
                      <Grid item xs={2}><h3>Example Response</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                        &#123;
                        &quot;success&quot;: true,
                        &quot;url&quot;: &quot;https://abc123.sendboth.com/#/u/ABC123...789XYZ&quot;
                        &#125;
                    </code>
                      </pre>
                      </Grid>
                      <Grid item xs={2}><h3>Error Response</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                      &#123;
                        &quot;success&quot;: &quot;false&quot;,
                        &quot;message&quot;: &quot;Invalid client secret. Please check your Authorization header (Authorization: \&quot;`Bearer xxx\&quot;`)&quot;
                      &#125;
                    </code>
                      </pre>
                      </Grid>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'firehose'} onChange={handleChange('firehose')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>Firehose</Typography>
              <Typography className={classes.secondaryHeading}>Endpoints related to the Firehose API.</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.childAccordion}>
              <Accordion expanded={subExpanded === 'firehose1'} onChange={handleSubChange('firehose1')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.headingGet}>GET</Typography>
                  <Typography className={classes.secondaryHeading}>/api/v1/firehose/users/partnerUserId/:partner_user_id</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.childAccordion}>
                  <Typography>
                    <Grid container item xs={12} spacing={2} justifyContent={'space-between'}>
                      <Grid item xs={2}><h3>Example Request</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                    </code>
                      </pre>
                      </Grid>
                      <Grid item xs={2}><h3>Example Response</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                        &#123;
                        &#125;
                    </code>
                      </pre>
                      </Grid>
                      <Grid item xs={2}><h3>Error Response</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                      &#123;
                        &quot;success&quot;: &quot;false&quot;,
                        &quot;message&quot;: &quot;Invalid client secret. Please check your Authorization header (Authorization: \&quot;`Bearer xxx\&quot;`)&quot;
                        &#125;
                    </code>
                      </pre>
                      </Grid>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={subExpanded === 'firehose2'} onChange={handleSubChange('firehose2')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.headingGet}>GET</Typography>
                  <Typography className={classes.secondaryHeading}>/api/v1/firehose/users/:user_id</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.childAccordion}>
                  <Typography>
                    <Grid container item xs={12} spacing={2} justifyContent={'space-between'}>
                      <Grid item xs={2}><h3>Example Request</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                    </code>
                      </pre>
                      </Grid>
                      <Grid item xs={2}><h3>Example Response</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                        &#123;
                        &#125;
                    </code>
                      </pre>
                      </Grid>
                      <Grid item xs={2}><h3>Error Response</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                      &#123;
                        &quot;success&quot;: &quot;false&quot;,
                        &quot;message&quot;: &quot;Invalid client secret. Please check your Authorization header (Authorization: \&quot;`Bearer xxx\&quot;`)&quot;
                      &#125;
                    </code>
                      </pre>
                      </Grid>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'healthgoals'} onChange={handleChange('healthgoals')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>Health Goals</Typography>
              <Typography className={classes.secondaryHeading}>Endpoints related to the various Health Goals.</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.childAccordion}>
              <Accordion expanded={subExpanded === 'goals1'} onChange={handleSubChange('goals1')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.headingGet}>GET</Typography>
                  <Typography className={classes.secondaryHeading}>api/v3/goals</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.childAccordion}>
                  <Typography>
                    <Grid container item xs={12} spacing={2} justifyContent={'space-between'}>
                      <Grid item xs={2}><h3>Example Request</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                    </code>
                      </pre>
                      </Grid>
                      <Grid item xs={2}><h3>Example Response</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                        &#123;
                        &quot;data&quot;: [
                        &quot;reduce_cardio_risk&quot;,
                        &quot;control_bp&quot;,
                        &quot;prevent_control_diabetes&quot;,
                        &quot;manage_heart_failure&quot;,
                        &quot;decrease_inflammation&quot;,
                        &quot;improve_overall&quot;,
                        &quot;manage_weight&quot;,
                        &quot;other&quot;,
                        &quot;manage_high_cholesterol&quot;,
                        &quot;manage_food_sensitivities&quot;,
                        &quot;improve_gut_health&quot;
                        ]
                        &#125;
                    </code>
                      </pre>
                      </Grid>
                      <Grid item xs={2}><h3>Error Response</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                      &#123;
                        &quot;success&quot;: &quot;false&quot;,
                        &quot;message&quot;: &quot;Must be logged in.&quot;
                        &#125;
                    </code>
                      </pre>
                      </Grid>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={subExpanded === 'goals2'} onChange={handleSubChange('goals2')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.headingGet}>GET</Typography>
                  <Typography className={classes.secondaryHeading}>api/v3/goals/all</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.childAccordion}>
                  <Typography>
                    <Grid container item xs={12} spacing={2} justifyContent={'space-between'}>
                      <Grid item xs={2}><h3>Example Request</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                    </code>
                      </pre>
                      </Grid>
                      <Grid item xs={2}><h3>Example Response</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                        &#123;
                        &quot;data&quot;: [
                        &#123;
                          &quot;name&quot;: &quot;Improve heart health&quot;,
                          &quot;slug&quot;: &quot;reduce_cardio_risk&quot;,
                          &quot;camel_slug&quot;: &quot;ReduceCardioRisk&quot;
                        &#125;,
                        &#123;
                          &quot;name&quot;: &quot;Control blood pressure&quot;,
                          &quot;slug&quot;: &quot;control_bp&quot;,
                          &quot;camel_slug&quot;: &quot;ControlBloodPressure&quot;
                        &#125;,
                        &#123;
                          &quot;name&quot;: &quot;Prevent and control diabetes&quot;,
                          &quot;slug&quot;: &quot;prevent_control_diabetes&quot;,
                          &quot;camel_slug&quot;: &quot;PreventOrControlDiabetes&quot;
                        &#125;,
                        &#123;
                          &quot;name&quot;: &quot;Manage heart failure&quot;,
                          &quot;slug&quot;: &quot;manage_heart_failure&quot;,
                          &quot;camel_slug&quot;: &quot;ManageHeartFailure&quot;
                        &#125;,
                        &#123;
                          &quot;name&quot;: &quot;Immunity support&quot;,
                          &quot;slug&quot;: &quot;decrease_inflammation&quot;,
                          &quot;camel_slug&quot;: &quot;DecreaseInflammation&quot;
                        &#125;,
                        &#123;
                          &quot;name&quot;: &quot;Improve overall health&quot;,
                          &quot;slug&quot;: &quot;improve_overall&quot;,
                          &quot;camel_slug&quot;: &quot;ImproveOverall&quot;
                        &#125;,
                        &#123;
                          &quot;name&quot;: &quot;Manage weight&quot;,
                          &quot;slug&quot;: &quot;manage_weight&quot;,
                          &quot;camel_slug&quot;: &quot;ManageWeight&quot;
                        &#125;,
                        &#123;
                          &quot;name&quot;: &quot;Other&quot;,
                          &quot;slug&quot;: &quot;other&quot;,
                          &quot;camel_slug&quot;: &quot;Other&quot;
                        &#125;,
                        &#123;
                          &quot;name&quot;: &quot;Manage High Cholesterol&quot;,
                          &quot;slug&quot;: &quot;manage_high_cholesterol&quot;,
                          &quot;camel_slug&quot;: &quot;ManageHighCholesterol&quot;
                        &#125;,
                        &#123;
                          &quot;name&quot;: &quot;Manage Food Sensitivities&quot;,
                          &quot;slug&quot;: &quot;manage_food_sensitivities&quot;,
                          &quot;camel_slug&quot;: &quot;ManageFoodSensitivities&quot;
                        &#125;,
                        &#123;
                          &quot;name&quot;: &quot;Improve Gut Health&quot;,
                          &quot;slug&quot;: &quot;improve_gut_health&quot;,
                          &quot;camel_slug&quot;: &quot;ImproveGutHealth&quot;
                        &#125;
                        ]
                        &#125;
                    </code>
                      </pre>
                      </Grid>
                      <Grid item xs={2}><h3>Error Response</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                      &#123;
                        &quot;success&quot;: &quot;false&quot;,
                        &quot;message&quot;: &quot;Must be logged in.&quot;
                        &#125;
                    </code>
                      </pre>
                      </Grid>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={subExpanded === 'goals3'} onChange={handleSubChange('goals3')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.headingGet}>GET</Typography>
                  <Typography className={classes.secondaryHeading}>api/v3/goals/:slug</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.childAccordion}>
                  <Typography>
                    <Grid container item xs={12} spacing={2} justifyContent={'space-between'}>
                      <Grid item xs={2}><h3>Example Request</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                    </code>
                      </pre>
                      </Grid>
                      <Grid item xs={2}><h3>Example Response</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                        &#123;
                        &quot;data&quot;: &#123;
                        &quot;name&quot;: &quot;Immunity support&quot;,
                        &quot;slug&quot;: &quot;decrease_inflammation&quot;,
                        &quot;camel_slug&quot;: &quot;DecreaseInflammation&quot;
                        &#125;
                        &#125;
                    </code>
                      </pre>
                      </Grid>
                      <Grid item xs={2}><h3>Error Response</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                      &#123;
                        &quot;success&quot;: &quot;false&quot;,
                        &quot;message&quot;: &quot;Must be logged in.&quot;
                        &#125;
                    </code>
                      </pre>
                      </Grid>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'diets'} onChange={handleChange('diets')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>Diets</Typography>
              <Typography className={classes.secondaryHeading}>Endpoints related to the various Diet, Quality & Nutrition Information.</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.childAccordion}>
              <Accordion expanded={subExpanded === 'diets1'} onChange={handleSubChange('diets1')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.headingGet}>GET</Typography>
                  <Typography className={classes.secondaryHeading}>/api/v3/diets</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.childAccordion}>
                  <Typography>
                    <Grid container item xs={12} spacing={2} justifyContent={'space-between'}>
                      <Grid item xs={2}><h3>Example Request</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                    </code>
                      </pre>
                      </Grid>
                      <Grid item xs={2}><h3>Example Response</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                        &#123;
                        &quot;data&quot;: &#091;
                        &quot;AME&quot;,
                        ...
                        &quot;WFR&quot;
                        &#093;
                        &#125;
                    </code>
                      </pre>
                      </Grid>
                      <Grid item xs={2}><h3>Error Response</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                      &#123;
                        &quot;success&quot;: &quot;false&quot;,
                        &quot;message&quot;: &quot;Must be logged in.&quot;
                        &#125;
                    </code>
                      </pre>
                      </Grid>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={subExpanded === 'diets2'} onChange={handleSubChange('diets2')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.headingGet}>GET</Typography>
                  <Typography className={classes.secondaryHeading}>/api/v3/diets/all</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.childAccordion}>
                  <Typography>
                    <Grid container item xs={12} spacing={2} justifyContent={'space-between'}>
                      <Grid item xs={2}><h3>Example Request</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                    </code>
                      </pre>
                      </Grid>
                      <Grid item xs={2}><h3>Example Response</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                        &#123;
                        &#125;
                    </code>
                      </pre>
                      </Grid>
                      <Grid item xs={2}><h3>Error Response</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                      &#123;
                        &quot;success&quot;: &quot;false&quot;,
                        &quot;message&quot;: &quot;Must be logged in.&quot;
                        &#125;
                    </code>
                      </pre>
                      </Grid>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={subExpanded === 'diets3'} onChange={handleSubChange('diets3')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.headingGet}>GET</Typography>
                  <Typography className={classes.secondaryHeading}>/api/v3/diets/:diet_code</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.childAccordion}>
                  <Typography>
                    <Grid container item xs={12} spacing={2} justifyContent={'space-between'}>
                      <Grid item xs={2}><h3>Example Request</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                    </code>
                      </pre>
                      </Grid>
                      <Grid item xs={2}><h3>Example Response</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                        &#123;
                        &#125;
                    </code>
                      </pre>
                      </Grid>
                      <Grid item xs={2}><h3>Error Response</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                      &#123;
                        &quot;success&quot;: &quot;false&quot;,
                        &quot;message&quot;: &quot;Must be logged in.&quot;
                        &#125;
                    </code>
                      </pre>
                      </Grid>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={subExpanded === 'diets4'} onChange={handleSubChange('diets4')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.headingGet}>GET</Typography>
                  <Typography className={classes.secondaryHeading}>/api/v3/diets/:diet_code/:quality</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.childAccordion}>
                  <Typography>
                    <Grid container item xs={12} spacing={2} justifyContent={'space-between'}>
                      <Grid item xs={2}><h3>Example Request</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                    </code>
                      </pre>
                      </Grid>
                      <Grid item xs={2}><h3>Example Response</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                        &#123;
                        &#125;
                    </code>
                      </pre>
                      </Grid>
                      <Grid item xs={2}><h3>Error Response</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                      &#123;
                        &quot;success&quot;: &quot;false&quot;,
                        &quot;message&quot;: &quot;Must be logged in.&quot;
                        &#125;
                    </code>
                      </pre>
                      </Grid>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={subExpanded === 'diets5'} onChange={handleSubChange('diets5')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.headingGet}>GET</Typography>
                  <Typography className={classes.secondaryHeading}>/api/v3/diets/compare/:diet_code/:quality/:ideal_code/:ideal_quality</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.childAccordion}>
                  <Typography>
                    <Grid container item xs={12} spacing={2} justifyContent={'space-between'}>
                      <Grid item xs={2}><h3>Example Request</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                    </code>
                      </pre>
                      </Grid>
                      <Grid item xs={2}><h3>Example Response</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                        &#123;
                        &#125;
                    </code>
                      </pre>
                      </Grid>
                      <Grid item xs={2}><h3>Error Response</h3></Grid>
                      <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                      &#123;
                        &quot;success&quot;: &quot;false&quot;,
                        &quot;message&quot;: &quot;Must be logged in.&quot;
                        &#125;
                    </code>
                      </pre>
                      </Grid>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          </Accordion>
  
         
        </CardContent>
      </Paper>
    </Grid>
  );
};
export default APIEndpoints;
