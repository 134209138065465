import React, { useContext } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography,
} from '@material-ui/core';
import { PatientTabsContext } from '../../../lib/contexts';
import useMetric from '../../../lib/hooks/useMetric';
import { ProgressBar } from '../../../components/ProgressBar';
import { PatientCheckInHistoryRow } from './index';
import InvoiceTableRow from './InvoiceTableRow';
import useBillingInvoices from '../../../lib/hooks/useBillingInvoices';

const useStyles = makeStyles(theme => ({
  patientChartCard: theme.globalClasses.patientChartCard,
  container:{
    maxHeight: 600,
    overflowY: 'scroll'
  },
  topBar:{
    marginBottom:10
  },
  tableHead: theme.globalClasses.adminTableHead,
  tableRow: theme.globalClasses.adminTableRow
}));

const InvoiceTable = () => {
  const classes = useStyles();
  const {isLoading, data } = useBillingInvoices();
  return (
    <Grid item xs={12} >
      <Paper className={classes.patientChartCard}>
        <Grid item container xs={12} className={classes.topBar}>
          <Grid item xs={11}>
            <Typography variant={'h2'}>Invoices</Typography>
          </Grid>
        </Grid>
        {isLoading ?
          <ProgressBar /> :
          <Box className={classes.container}>
            <Table>
              <TableHead className={classes.tableHead}>
                <TableRow className={classes.tableRow}>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Challenge</TableCell>
                  <TableCell align="left">Day #</TableCell>
                  <TableCell align="left">Feeling</TableCell>
                  <TableCell align="left">Meal Type</TableCell>
                  <TableCell align="left">Checked-In</TableCell>
                  <TableCell align="left">Paused</TableCell>
                  <TableCell align="left">Succeeded</TableCell>
                  <TableCell align="left">User Notes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.metric && data.metric.value
                  .slice(0, data.metric.value.length).map((item, index)=>{
                    return (
                      <InvoiceTableRow key={index} row={item}  />
                    )
                  })}
              </TableBody>
            </Table>
          </Box>
        }
      </Paper>
    </Grid>
  );
};

export default InvoiceTable;
