import React, { useState } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion, AccordionDetails, AccordionSummary,
  CardContent, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  container:{
    margin:10
  },
  patientChartCard: {
    ...theme.globalClasses.patientChartCard,
    '& p':{
      marginTop:10,
    }
  },
  title:{
    fontSize:'24px !important',
    textAlign: 'left',
    margin: 'auto auto 20px'
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  pre:{
    whiteSpace:'pre-wrap'
  },
}));

const Typings = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Grid item xs={12}><a id={'typings'}/>
      <Paper className={classes.patientChartCard}>
        <CardContent>
          <Typography variant={'h1'} className={classes.title}>Typings</Typography>
  
          <Accordion expanded={expanded === 'HealthGoals'} onChange={handleChange('HealthGoals')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className={classes.heading}>healthGoals</Typography>
              <Typography className={classes.secondaryHeading}>
                Types within the Health Goal List.
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper} style={{maxWidth:'100%',margin:'10px auto auto'}}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Prop</TableCell>
                      <TableCell align="right">Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      <TableCell>data</TableCell>
                      <TableCell align="right">Object</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell align="right">
  
                        <TableContainer component={Paper} style={{maxWidth:'100%',margin:'10px auto auto'}}>
                          <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Prop</TableCell>
                                <TableCell align="right">Type</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell component="th" scope="row">name</TableCell>
                                <TableCell align="right">String</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component="th" scope="row">slug</TableCell>
                                <TableCell align="right">String</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component="th" scope="row">camel_slug</TableCell>
                                <TableCell align="right">String</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        
                        
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'Goal'} onChange={handleChange('Goal')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className={classes.heading}>healthGoal</Typography>
              <Typography className={classes.secondaryHeading}>
                Types within a Health Goal.
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper} style={{maxWidth:'100%',margin:'10px auto auto'}}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Prop</TableCell>
                      <TableCell align="right">Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      <TableCell>data</TableCell>
                      <TableCell align="right">Object</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell align="right">
  
                        <TableContainer component={Paper} style={{maxWidth:'100%',margin:'10px auto auto'}}>
                          <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Prop</TableCell>
                                <TableCell align="right">Type</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell component="th" scope="row">name</TableCell>
                                <TableCell align="right">String</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component="th" scope="row">slug</TableCell>
                                <TableCell align="right">String</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component="th" scope="row">camel_slug</TableCell>
                                <TableCell align="right">String</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        
                        
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'schema12'} onChange={handleChange('schema12')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className={classes.heading}>Diet</Typography>
              <Typography className={classes.secondaryHeading}>
                Types within a Diet.
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar
                diam eros in elit. Pellentesque convallis laoreet laoreet.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'schema1'} onChange={handleChange('schema1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>User</Typography>
              <Typography className={classes.secondaryHeading}>Types within Patient</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <pre className={classes.pre}>
                      <code>
                    </code>
                      </pre>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'schema2'} onChange={handleChange('schema2')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className={classes.heading}>Workflow</Typography>
              <Typography className={classes.secondaryHeading}>
                Types within a Patient&#39;s Assessment Workflow
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar
                diam eros in elit. Pellentesque convallis laoreet laoreet.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'schema3'} onChange={handleChange('schema3')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className={classes.heading}>Workflow &#8627; dietScreener</Typography>
              <Typography className={classes.secondaryHeading}>
              
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar
                diam eros in elit. Pellentesque convallis laoreet laoreet.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'schema4'} onChange={handleChange('schema4')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className={classes.heading}>Workflow &#8627; dietRestrictions</Typography>
              <Typography className={classes.secondaryHeading}>
              
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar
                diam eros in elit. Pellentesque convallis laoreet laoreet.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'schema5'} onChange={handleChange('schema5')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className={classes.heading}>Workflow &#8627; idealGoals</Typography>
              <Typography className={classes.secondaryHeading}>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar
                diam eros in elit. Pellentesque convallis laoreet laoreet.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'schema6'} onChange={handleChange('schema6')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className={classes.heading}>Workflow &#8627; sendboth</Typography>
              <Typography className={classes.secondaryHeading}>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar
                diam eros in elit. Pellentesque convallis laoreet laoreet.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'schema7'} onChange={handleChange('schema7')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className={classes.heading}>Workflow &#8627; dietIdeal</Typography>
              <Typography className={classes.secondaryHeading}>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar
                diam eros in elit. Pellentesque convallis laoreet laoreet.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'schema8'} onChange={handleChange('schema8')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className={classes.heading}>Workflow &#8627; dietIdNutritionInfo</Typography>
              <Typography className={classes.secondaryHeading}>
              
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar
                diam eros in elit. Pellentesque convallis laoreet laoreet.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'schema9'} onChange={handleChange('schema9')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className={classes.heading}>Workflow &#8627; dietIdealNutritionInfo</Typography>
              <Typography className={classes.secondaryHeading}>
              
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar
                diam eros in elit. Pellentesque convallis laoreet laoreet.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'schema10'} onChange={handleChange('schema10')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className={classes.heading}>Workflow &#8627; userInfo</Typography>
              <Typography className={classes.secondaryHeading}>
              
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar
                diam eros in elit. Pellentesque convallis laoreet laoreet.
              </Typography>
            </AccordionDetails>
          </Accordion>
          
        </CardContent>
      </Paper>
    </Grid>
  );
};
export default Typings;
