import React, { useContext } from 'react';

import { Grid, Typography } from "@material-ui/core";
import { Screen } from '../../components';
import { makeStyles } from "@material-ui/core/styles";
import { PaymentOverview, SelectPlan, SelectPlanMoreDetails } from './components';
import { UserContext } from '../../lib/contexts';
const useStyles = makeStyles(theme => ({
  screen:{
    width:'96%',
    margin:'10px auto auto',
  },
  dashboard:{
    width:'92%',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginTop:20,
      marginBottom:20,
      color: theme.palette.admin.main,
      display: 'block'
    },
  },
  patientLists:{
    marginTop:20,
  }
}));

const BillingScreen = () => {
  const classes = useStyles();
  const {
    state: { first_name },
  } = useContext(UserContext)
  //const devMode = ['localhost', 'dev'].includes(process.env.REACT_APP_SYSTEM_ENV);
  
  
  // http://localhost:5001/api/v1/billing/plans
  // http://localhost:5001/api/v1/billing/subscription
  // http://localhost:5001/api/v1/billing/subscription_limit
  // http://localhost:5001/api/v1/billing/invoice
  
  return (
    <Screen title={'Billing | SendBoth'} showSidebarNav={true} admin={true}>
          <Grid
            container
            alignItems="center"
            spacing={0}
            className={classes.screen}
          >
            <Typography variant={'subtitle1'}>
              Welcome, {first_name && first_name+','} to your SendBoth Dashboard
            </Typography>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={4}
              className={classes.dashboard}
            >
              <SelectPlan/>
              <PaymentOverview/>
              {/*<SelectPlanMoreDetails />*/}
            </Grid>
          </Grid>
    </Screen>
  );
};

export default BillingScreen;
