import React, { useState, useEffect, useCallback, useContext } from 'react';
import './Login.css';
import LockIcon from '@material-ui/icons/Lock';
import { makeStyles } from '@material-ui/styles';
import { Logo, Screen } from '../../components/';

import {
  Fade,
  Button,
  Grid,
  Typography,
  TextField,
  FormControl,
  LinearProgress,
  Paper,
  Checkbox,
  FormGroup,
  FormControlLabel,
  AccordionSummary,
  Accordion,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { PartnerContext, UserContext } from '../../lib/contexts';
import { getCookie, setCookie } from '../../lib/helpers/helpers';
import { KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowUp, Visibility, VisibilityOff } from '@material-ui/icons';
import { mix_hexes } from '../../lib/helpers/colorHelpers';
import { postUserForgot, postUserLogin, postUserReset, postUserLoginConfirm } from '../../lib/api';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  bgshape1: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100vh',
    zIndex: -1,
    backgroundColor: theme.palette.primary.main, // || '#3f83d1',
  },
  bgshape2: {
    transform: 'rotate('+Math.floor((Math.random() * 45)+10)+'deg)',
    position: 'fixed',
    right: -500,
    top: -100,
    width: 0,
    height: 0,
    borderTop: '100vh solid transparent',
    borderBottom: '100vh solid transparent',
    borderLeft: theme.palette.primary.main && theme.palette.secondary.main ? '100vh solid ' + mix_hexes(theme.palette.primary.main, theme.palette.secondary.main) : '100vh solid #b6b6b6',
    zIndex: -1,
    backgroundColor: theme.palette.primary.main && theme.palette.secondary.main ? mix_hexes(theme.palette.primary.main, theme.palette.secondary.main) : '#b6b6b6',
  },
  bgshape3: {
    transform: 'rotate('+Math.floor((Math.random() * 40)+1)+'deg)',
    position: 'fixed',
    left: '-50vh',
    top: '-55vh',
    width: 0,
    height: 0,
    borderTop: '100vh solid transparent',
    borderBottom: '100vh solid transparent',
    borderLeft: '100vh solid ' + theme.palette.secondary.main,
    zIndex: -1,
    backgroundColor: theme.palette.secondary.main, // || '#ff0044',
  },
  paper: {
    minWidth: 400,
    minHeight: 600,
    width: '80vw',
    height: '65vh',
    maxWidth:1000,
    paddingBottom:15,
    margin: '15vh auto 5vh',
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
    [theme.breakpoints.down('md')]: {
      margin: '5vh auto 5vh',
      minHeight: '80vh',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '5vh auto 5vh',
      minHeight: '90vh',
      width: '90vw',
    },
  },
  container:{
    width: '100%',
    minWidth: 300,
    margin: 'auto',
    display: 'block',
    overflowY:'auto',
  },
  welcomeMessage:{
    marginTop: 0,
    fontSize: '1.8rem',
    margin:'auto',
    marginBottom: 15,
    textAlign: 'center',
    maxWidth:'60%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '75%',
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
      fontSize: '1.2rem',
    },
  }
}));

const LoginScreen = props => {
  const urlParams = new URLSearchParams(window.location.search);
  const classes = useStyles();
  const {
    actions: { setUser },
  } = useContext(UserContext);

  const {
    state: { id },
  } = useContext(PartnerContext);
  const [status, setStatus] = useState(null);
  const [emailBad, setEmailBad] = useState(false);
  const [tryingLogin, setTryingLogin] = useState(false);
  
  const [loginID, setLoginID] = useState(null);
  const [loginEmail, setLoginEmail] = useState('');
  const [OTP, setOTP] = useState('');
  const [phone, setPhone] = useState('');
  
  
  const [showPassword, setShowPassword] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [OTPLogin, setOTPLogin] = useState(false);
  const [accessCodeLogin, setAccessCodeLogin] = useState(false);
  const [appCodeLogin, setAppCodeLogin] = useState(false);
  const [smsLogin, setSmsLogin] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);
  
  const [resetToken, setResetToken] = useState();
  const [pwResetEmail, setPwResetEmail] = useState();
  
  const [loginPw, setLoginPw] = useState('');
  const [confirmPw, setConfirmPw] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [appCode, setAppCode] = useState('');
  const [helperText, setHelperText] = useState('');
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);

  useEffect(() => {
    if(getCookie('phone')){
     // setPhone(getCookie('phone'));
    }
    if(getCookie('email')){
     // setLoginEmail(getCookie('email'));
    }
    if(urlParams.get('email')){
     // setPwResetEmail(urlParams.get('email'));
    }
    if(urlParams.get('reset_token')){
     // setResetToken(urlParams.get('reset_token'));
    }
  }, []);
  
  useEffect(() => {
    document.title = 'Log In | SendBoth';
  }, []); //html page title
  const resetRequest = useCallback(async () => {
    setStatus(null);
    setTryingLogin(true);
    const resetData = {
      email: pwResetEmail,
      reset_token: resetToken,
      user: {
        password: loginPw,
        password_confirmation: confirmPw,
      }
    };
    try {
      const { data } = await postUserReset(resetData, id);
      
      if (data) {
        setTryingLogin(false);
        if(data.success){
          setResetPasswordSuccess(true);
          setResetToken(null);
          setPwResetEmail(null);
        }
      }else {
        setStatus('failure');
        setTryingLogin(false);
      }
    } catch (error) {
      console.error(error);
      setStatus('failure');
      setTryingLogin(false);
    }
  },[id, pwResetEmail, resetToken, loginPw, confirmPw]);
  const forgotRequest = useCallback(async () => {
    setStatus(null);
    setTryingLogin(true);
    const resetData = {
      password_reset: {
        email: loginEmail,
      }
    };
    try {
      const { data } = await postUserForgot(resetData, id);
    
      if (data) {
        setTryingLogin(false);
      if(data.success){
        setForgotPasswordSuccess(true);
      }
      }else {
        setStatus('failure');
        setTryingLogin(false);
      }
    } catch (error) {
      console.error(error);
      setStatus('failure');
      setTryingLogin(false);
    }
  },[id, loginEmail]);
  const loginRequestConfirm = useCallback(async () => {
    setStatus(null);
    setTryingLogin(true);
    const loginData = {
      email: loginEmail ? loginEmail : null,
      phone_number: phone ? "+1"+phone : null,
      token: OTPLogin ? OTP : null,
    };
    try {
      const { data } = await postUserLoginConfirm(loginData);
  
      if (data) {
        localStorage.setItem('SB_AUTH',
          JSON.stringify({
            user: data.user,
            user_id: data.user.id,
            token: data.token
          }));
        setUser(data.user)
    
        if(rememberMe && loginEmail){
          setCookie('email', loginEmail);
        }
        if(rememberMe && phone){
          setCookie('phone', phone);
        }
    
        window.location = window.location.protocol + '//' + window.location.host + '/';
        setTryingLogin(false);
      } else {
        setStatus('failure');
        setTryingLogin(false);
      }
      
    } catch (error) {
      console.error(error);
      setStatus('failure');
      setTryingLogin(false);
    }
  },[loginEmail, phone, OTP]);
  
  const loginRequest = useCallback(async () => {
    
    if(loginEmail === 'demo'){
      localStorage.setItem('SB_AUTH',
        JSON.stringify({
          user: {firstName:'Demo'},
          user_id: -1,
          partner_id: -1,
          token: 'demo'
        }));
      //setUser(data.user)
      window.location = window.location.protocol + '//' + window.location.host + '/';
    }
    setStatus(null);
    setTryingLogin(true);
    const loginData = {
        email: loginEmail ? loginEmail : null,
        phone_number: phone ? "+1"+phone : null,
        token: OTPLogin ? OTP : null,
    };
    try {
      const result = await postUserLogin(loginData, id);
      if (result.status === 204) {
        //OTP
        setOTPLogin(true);
        setSmsLogin(false);
        setTryingLogin(false);
        
      } else {
        setStatus('failure');
        setTryingLogin(false);
      }
    } catch (error) {
      console.error(error);
      setStatus('failure');
      setTryingLogin(false);
    }
  }, [loginEmail, loginPw, authToken, accessCode, appCode, OTP, phone, smsLogin, OTPLogin, props]);

  const validateEmail = useCallback(email => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }, []);
  
  useEffect(()=>{
    if(OTP && OTP.length === 4){
      loginRequestConfirm();
    }
  },[OTP])
  
  return (
    <Screen title={'Login | SendBoth'} noBackground={true} showFooter={true}>
    <Paper className={classes.paper} elevation={12}>
      <div style={{ padding: '2.5vh', textAlign: 'center' }}>
        <Logo variant={'login_logo'} />
      </div>

      <div className="banner">
        <div className={classes.container}>
          <Fade in={true}>
            <>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Fade in={true}>
                    <>
                      <Typography className={classes.welcomeMessage}
                        gutterBottom
                        variant="h4"
                      >
                        Next-Level SMS & Email Marketing Automation
                        {/*Welcome! Sign-in to your Dashboard.*/}
                      </Typography>
                      <Typography gutterBottom variant="h6" style={{ fontSize: '1.0rem', textAlign: 'center' }}>
                        {status === 'failure'
                          ? forgotPassword ? 'Please double-check your Email address.' : 'Please double-check your Email and Password.' : OTPLogin
                            ? 'Please enter the One-Time-Password sent to your Device:'
                            : forgotPasswordSuccess ? 'Success! We\'ve sent you an email.' : resetPasswordSuccess ? 'Updated! Login with new credentials.' : resetToken ? 'Please enter new Password and Confirm.' : 'Please enter your details below:'}
                      </Typography>
                    </>
                  </Fade>
                </Grid>
              </Grid>
              <Grid
                style={{ width: 300, margin: 'auto' }}
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={0}
              >
  
                {smsLogin ? <Grid item xs={12} style={{ marginBottom: 15 }}>
                  <Button variant={'text'}
                          style={{display:'block', margin:'auto'}}
                          onClick={()=> {
                      setForgotPassword(false);
                      setSmsLogin(false);
                    }}><KeyboardArrowLeft
                    style={{marginBottom:-7, marginRight:-2}}/> Back</Button>
                </Grid>
                :
                  (OTPLogin || appCodeLogin || accessCodeLogin) ? <Grid item xs={12} style={{ marginBottom: 15 }}>
                  <FormControl style={{ width: 300 }}>
                    <TextField
                      error={status === 'failure' ? true : false}
                      className='field'
                      key='otp'
                      id='otp'
                      label={(appCodeLogin || accessCodeLogin) ? 'Enter Code' :  'Enter One-Time-Password'}
                      value={OTPLogin ? OTP : appCodeLogin ? appCode : accessCode}
                      onChange={event => {
                        if(OTPLogin){
                          setOTP(event.target.value);
                        }
                        else if(appCodeLogin){
                          setAppCode(event.target.value);
                        }
                        else if(accessCodeLogin){
                          setAccessCode(event.target.value);
                        }
                      }}
                      margin='normal'
                      variant='outlined'
                      fullWidth={true}
                      disabled={tryingLogin}
                      type={'text'}
                    />
                  </FormControl>
                    <Button variant={'text'}
                            style={{display:'block', margin:'auto'}}
                            onClick={()=> {
                      setAccessCodeLogin(false);
                      setOTPLogin(false);
                      setAppCodeLogin(false);
                      setForgotPassword(false);
                    }}><KeyboardArrowLeft
                      style={{marginBottom:-7, marginRight:-2}}/> Back</Button>
                </Grid>
                  :
                  <>
                    <Accordion elevation={0} expanded={!showMore || forgotPassword}>
                      <AccordionSummary
                        style={{height:0, display:'none'}}
                        aria-controls="emailLogin"
                        id="emailLogin"
                      >
                      </AccordionSummary>
                        <Grid item xs={12}>
                          {!resetToken && <FormControl style={{ width: 300 }}>
                            <TextField
                              error={status === 'failure' || emailBad ? true : false}
                              required={!phone}
                              id='email'
                              key='email'
                              label='Enter Email'
                              margin='normal'
                              variant='outlined'
                              fullWidth={true}
                              disabled={tryingLogin}
                              value={loginEmail}
                              onChange={(event,value) => {
                                console.log('g');
                                console.log(event.target.value);
                                console.log(value);
                                setLoginEmail(event.target.value);
                                setEmailBad(!validateEmail(event.target.value));
                              }}
                              type={'email'}
                              helperText={helperText}
                            />
                          </FormControl>}
                          <Typography style={{ marginTop:8, textAlign: 'center' }}>OR</Typography>
                          {!forgotPassword && <FormControl style={{ width: 300 }}>
                            <TextField
                               value={phone}
                               onChange={event => {
                                 let val = event.target.value.replace(/[^0-9]/g, '')
                                 if(val.length < 11){
                                   setPhone(val);
                                 }
                               }}
                              error={status === 'failure' ? true : false}
                              required={!loginEmail}
                              className='field'
                              key='otp'
                              id='otp'
                              label={'Enter Phone Number'}
                              margin='normal'
                              variant='outlined'
                              fullWidth={true}
                              disabled={tryingLogin}
                              type={'text'}
                              inputProps={{
                                max: 10,
                              }}
                            />
                          </FormControl>}
                          {resetToken && <FormControl style={{ width: 300 }}>
                            <TextField
                              error={status === 'failure' ? true : false}
                              className='field'
                              key='confirmPw'
                              required
                              id='confirmPw'
                              label='Confirm Password'
                              value={confirmPw}
                              onChange={event => {
                                setConfirmPw(event.target.value);
                              }}
                              margin='normal'
                              variant='outlined'
                              fullWidth={true}
                              disabled={tryingLogin}
                              type={!showPassword ? 'password' : 'text'}
      
                              InputProps={{
                                endAdornment:
                                  <InputAdornment position='end'>
                                    <IconButton
                                      aria-label='toggle password visibility'
                                      onClick={() => setShowPassword(!showPassword)}
                                    >
                                      {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>,
                              }}
                            />
                          </FormControl>}
  
                          {forgotPassword && <Button variant={'text'}
                                   style={{ display: 'block', margin: 'auto' }}
                                   onClick={() => {
                                     setForgotPassword(false);
                                     setShowMore(false);
                                   }}><KeyboardArrowLeft
                            style={{ marginBottom: -7, marginRight: -2 }} /> Back</Button>}
                        </Grid>
                    </Accordion>
                    {1 === 2 && !forgotPassword && !resetToken && <Accordion elevation={0} expanded={showMore && !forgotPassword} onChange={()=>setShowMore(!showMore)}>
                      <AccordionSummary
                        aria-controls='moreWays'
                        id='moreWays'
                      >
                        <Typography style={{ textAlign: 'center', margin: 'auto' }}>More Login Options<br />{showMore ?
                          <KeyboardArrowUp /> : <KeyboardArrowDown />}</Typography>
                      </AccordionSummary>
                      <Grid item xs={12} style={{ marginBottom: 15, textAlign:'left' }}>
                        <Button fullWidth variant={'text'} onClick={()=>setSmsLogin(!smsLogin)}>Login via SMS</Button>
                        {/* <Button fullWidth variant={'text'} onClick={()=>setAccessCodeLogin(!accessCodeLogin)}>Login via Access Code</Button>
                        <Button fullWidth variant={'text'} onClick={()=>setAppCodeLogin(!appCodeLogin)}>Login via App Code</Button>
                        */}
                        <Button fullWidth variant={'text'} onClick={()=>setForgotPassword(!forgotPassword)}>Forgot Password?</Button>
                      </Grid>
                    </Accordion>}
                  </>
                }

                {tryingLogin ? (
                  <LinearProgress
                    color="primary"
                    style={{ width: '80%', display: 'block', textAlign: 'center', margin: '20px auto 20px auto' }}
                  />
                ) : (
                  <Button
                    disabled={( (!emailBad && loginEmail !== '') || (OTP && OTP.length > 3) ||  (phone && phone.length == 10) ) ? false : true}
         //           disabled={( (resetToken && pwResetEmail && confirmPw.length > 7 && confirmPw == loginPw) || (!emailBad && loginEmail !== '') || OTP.length > 3 ||  phone.length == 10 || accessCode.length > 4 || (forgotPassword && !emailBad)) ? false : true}
                    variant="contained"
                    color="primary"
                    style={{ width: 300, marginBottom: 20, marginTop:0 }}
                    onClick={OTPLogin ? () => loginRequestConfirm() : () => loginRequest()}
                  >
                    {forgotPassword ? <>Reset Password &nbsp;</> : resetToken ? <>Update Password &nbsp;</> : <>Sign In &nbsp;</>}
                    <LockIcon />
                  </Button>
                )}
  
                {!resetToken && !OTPLogin && !appCodeLogin && !accessCodeLogin && !forgotPassword && <Grid item xs={12} style={{ marginBottom: 15 }}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox defaultChecked
                                                         checked={rememberMe}
                                                         onChange={event => {
                                                           setRememberMe(!rememberMe);
                                                         }}
                    />} label='Remember Me?' />
                  </FormGroup>
                </Grid>}
                
              </Grid>
            </>
          </Fade>
        </div>
      </div>

      <div className={classes.bgshape1}></div>
      {/*
      <div className={classes.bgshape2}></div>
      <div className={classes.bgshape3}></div>
      */}
    </Paper>
    </Screen>
  );
};

export default LoginScreen;
