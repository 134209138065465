import React, { useContext } from 'react';
import LogoUrl from '../../assets/logos/trimmed/logo3_white_blue.svg';
import LogoUrlBlack from '../../assets/logos/trimmed/logo3_blue.svg';
import LogoUrlOrange from '../../assets/logos/trimmed/logo3_orange.svg';
import LogoIconUrl from '../../assets/images/LogoIcon.png';
import LogoIconWhiteUrl from '../../assets/images/LogoIconWhite.png';
import LogoWhiteUrl from '../../assets/images/LogoWhite.png';
import { PartnerContext } from '../../lib/contexts';

const Logo = ({ link_herf, variant, height, color }) => {
  const {
    state: { logo_medium_url },
  } = useContext(PartnerContext);
  const url_base = window.location.protocol + '//' + window.location.host;
  const url = link_herf ? url_base + link_herf : url_base + '/dashboard';
  height = height === null ? 38 : height;
  let imgSrc = '';
  let additionalStyle = {};
  switch (variant) {
    default:
    case 'logoWhite':
    case 'admin':
      imgSrc = LogoWhiteUrl;
      additionalStyle = { margin: '25px auto', display: 'block', width: 110 };
      break;
    case 'login_logo':
      imgSrc = LogoUrlOrange;
      additionalStyle = { margin: '100px auto 10px', display: 'block', width: 400 };
      break;
    case 'logo':
      imgSrc = color === 'black' ? LogoUrlBlack : LogoUrl;
      additionalStyle = { margin: 'auto', display: 'block', width: 200 };
      break;
    case 'icon':
      imgSrc = LogoIconUrl;
      additionalStyle = { margin: '10px auto', display: 'block' };
      break;
    case 'iconWhite':
      imgSrc = LogoIconWhiteUrl;
      additionalStyle = { margin: '10px auto', display: 'block' };
      break;
    case 'text':
      additionalStyle = { margin: '10px auto', display: 'block' };
      break;
  }
  return (
        <a href={url} style={{ display: 'block', color:'inherit', fontSize:variant === 'text' ? 38 : 1 }}>
          {variant ==='text' ? 'SendBoth' :
          <img style={{ height: height, ...additionalStyle }}
                src={variant == 'admin' ? imgSrc : logo_medium_url ?? imgSrc} alt='logo' />
          }
        </a>
  );
};
export default Logo;
