import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import { Screen } from '../../components';
import { makeStyles } from "@material-ui/core/styles";
import { Build } from '@material-ui/icons';
const useStyles = makeStyles(() => ({
  screen:{
    width:'96%',
    margin:'10px auto auto',
  },
  buildIcon:{
    fontSize:80,
    margin:'20vh auto 20px auto',
    display:'block'
  }
}));

const NotFoundScreenInternal = () => {
  const classes = useStyles();
  
  return (
    <Screen title={'Not Found | SendBoth'} showSidebarNav={true} admin={true}>
      <Grid
        container
        alignItems="center"
        spacing={0}
        className={classes.screen}
      >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
          >
            <Typography variant={'subtitle1'} style={{textAlign:'center'}}>
              <Build className={classes.buildIcon}/>
              Check back later!
              <br/>
              Page Under Construction.
            </Typography>
          </Grid>
      </Grid>
    </Screen>
  );
};

export default NotFoundScreenInternal;
