import React from 'react';

import {
  TableCell, TableRow
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { prettyDateTime, toCapitalize } from '../../../lib/helpers/helpers';
import { Check, Close } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  viewReportButton:{
    borderRadius: 25,
    minWidth: 80
  },
  tableRow: theme.globalClasses.adminTableRow
}));

const PatientLatestAssessmentRow = ({row}) => {
  const classes = useStyles();
  return (
      <TableRow
        hover
        tabIndex={-1}
        id={row.id}
        key={row.id}
        className={classes.tableRow}
      >
        <TableCell align="left">{prettyDateTime(row.createdAt)}</TableCell>
        <TableCell align="left">{row.challenge ? row.challenge.name : 'N/A'}</TableCell>
        <TableCell align="left">{row.subscription ? row.subscription.cached_challenge_stats.last_reached_day + " / " + row.subscription.cached_challenge_stats.total_days : 'N/A'}</TableCell>
        <TableCell align="left">{row.feeling ? toCapitalize(row.feeling) : 'N/A'}</TableCell>
        <TableCell align="left">{row.mealType ? toCapitalize(row.mealType) : 'N/A'}</TableCell>
        <TableCell align="left">{row.score ? row.score === "success" ? <Check/> : <Close/> : 'N/A'}</TableCell>
        <TableCell align="left">{row.subscription ? row.subscription.paused ? <Check/> : <Close/> : 'N/A'}</TableCell>
        <TableCell align="left">{row.subscription ? row.subscription.succeeded ? <Check/> : <Close/> : 'N/A'}</TableCell>
        <TableCell align="left">{row.notes ?? 'N/A'}</TableCell>
      </TableRow>
  );
};

export default PatientLatestAssessmentRow;
