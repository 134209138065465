import React, { useContext, useEffect, useState } from 'react';

import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Screen } from '../../components';
import { makeStyles } from "@material-ui/core/styles";
import { PatientTabChart, PatientTableResults, PatientTabs } from './components/';
import { PatientTabsContext, PatientTabsContextProvider } from '../../lib/contexts/PatientTabsContext';
import { PartnerContext } from '../../lib/contexts';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
  screen: theme.globalClasses.screen,
  inlineTitle:{
    fontWeight: 600,
    width: '100%',
    marginBottom: 50,
    display:'inline-block',
    fontSize: 24,
    color: theme.palette.admin.main,
    cursor:'pointer'
  }
}));

const PatientsScreen = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.up('sm'));
  const matchMD = useMediaQuery(theme.breakpoints.up('md'));
  const matchLG = useMediaQuery(theme.breakpoints.up('lg'));
  const matchXL = useMediaQuery(theme.breakpoints.up('xl'));
  const [showSidebarNav, setShowSidebarNav] = useState(true);
  
  useEffect(()=>{
    setShowSidebarNav((matchSM || matchMD));
  },[matchSM, matchMD]);
  return (
    <Screen title={'Patients | SendBoth'} showSidebarNav={showSidebarNav} admin={true}>
          <Grid
            container
            alignItems="center"
            spacing={0}
            className={classes.screen}
          >
            <PatientTabsContextProvider>
              <>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start">
                    <PageTitleCloseTabs />
                    <PatientTabs />
                </Grid>
                <PageSwitcher />
              </>
            </PatientTabsContextProvider>
{/*
  // For use with Blake's legacy data dump
  const {
    state: { data },
  } = useContext(V1DataContext);
<V1DataContextProvider>
</V1DataContextProvider>
            */}
          </Grid>
    </Screen>
  );
};

const PageTitleCloseTabs = () => {
  const classes = useStyles();
  const {
    actions: { goHomeTab },
  } = useContext(PatientTabsContext);
  
  return <Grid item>
    <Typography className={classes.inlineTitle} onClick={goHomeTab}>
      Patient Data
    </Typography>
  </Grid>
}
const PageSwitcher = () => {
  let { uuid, listId } = useParams();
  const navigate = useNavigate();
  
  const {
    state: { openTabs, activeTab, currentTab },
  } = useContext(PatientTabsContext);
  const {
    state: { code },
  } = useContext(PartnerContext);
  
  useEffect(()=>{
      if(currentTab && currentTab.uuid !== uuid || activeTab && activeTab != uuid){
        //alert(JSON.stringify(currentTab))
        navigate("../patients/"+currentTab.uuid, {replace: true});
        //window.history.replaceState(null,
        //  currentTab ? currentTab.firstName + ' ' + currentTab.lastName + ' | ' : '' + window.document.title,
        //  getClientHost(code) + '/patients/' + currentTab.uuid);
      }else{
        if(openTabs.length > 0 && activeTab == null && listId == null){
          navigate("../patients/", {replace: true})
          //window.history.replaceState(null, window.document.title,getClientHost(code) + '/patients/');
        }
      }
    
  },[currentTab, activeTab, openTabs, code, uuid, navigate])
  
  return activeTab != null ? <PatientTabChart/> : <PatientTableResults />
}

export default PatientsScreen;
