import React, { useContext, useMemo } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid, ListItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography,
} from '@material-ui/core';
import { PatientTabsContext } from '../../../lib/contexts';
import useMetric from '../../../lib/hooks/useMetric';
import { ProgressBar } from '../../../components/ProgressBar';
import { PatientLatestAssessmentRow } from './index';
import PatientNotesRow from './PatientNotesRow';
import usePatientNotes from '../../../lib/hooks/usePatientNotes';

const useStyles = makeStyles(theme => ({
  patientChartCard: theme.globalClasses.patientChartCard,
  container:{
    maxHeight: 600,
    overflowY: 'scroll'
  },
  topBar:{
    marginBottom:10
  },
  tableHead: theme.globalClasses.adminTableHead,
  tableRow: theme.globalClasses.adminTableRow
}));

const PatientNotesBox = ({ summary, setSetSubtab }) => {
  const classes = useStyles();
  const {
    state: { currentTab },
  } = useContext(PatientTabsContext);
  const {isLoading, data, refetch } = usePatientNotes(currentTab.id);
  return (
    <Grid item xs={summary ? 9 : 12} >
      <Paper className={classes.patientChartCard}>
        <Grid item container xs={12} className={classes.topBar}>
          <Grid item xs={11}>
            <Typography variant={'h2'}>Patient Notes</Typography>
          </Grid>
          {summary && <Grid item xs={1}>
            <Typography variant={'h3'} onClick={() => setSetSubtab('1')}>View All</Typography>
          </Grid>}
        </Grid>
        {isLoading ?
          <ProgressBar /> :
          <Box className={classes.container}>
            <Table>
              <TableHead className={classes.tableHead}>
                <TableRow className={classes.tableRow}>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Author</TableCell>
                  <TableCell align="left">Note</TableCell>
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
            {data && data
              .slice(0, summary ? 3 : data.length).map((item, index)=>{
              return (
                <PatientNotesRow key={index} row={item} summary={summary} refetch={refetch}  />
              )
            })}
              </TableBody>
            </Table>
          </Box>
        }
      </Paper>
    </Grid>
  );
};

export default PatientNotesBox;
