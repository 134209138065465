import React, { useCallback, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box, Button, Grid, Select, MenuItem } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {
  TextField, Typography,
  withStyles,
} from '@material-ui/core';
import { Add, Tune, Create, GroupAdd, Delete } from '@material-ui/icons';
import { postContactList } from '../../lib/api/postContactList';
import { ProgressBar } from '../ProgressBar';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    /* width: 'fit-content',*/
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientListSmall:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:180,
    height:44,
    margin:'20px auto 0px',
    fontSize:13,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    '& *':{
      color: theme.palette.primary.contrastText,
      display:'inline',
    }
  },
  addPatientListText:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    display:'block',
    margin:'40px auto 0px !important',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    marginTop:-25,
    marginRight:25,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  shareSwitch:{
    color: theme.palette.primary.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
  container:{
    maxHeight:250,
    overflowY: 'auto',
    margin:'20px auto 20px',
    textAlign:'center',
    '& *':{
      textAlign:'center',
    }
  },
  dynamicVarListItem:{
    marginBottom:20
  }
}));

const AdminGreenSwitch = withStyles(theme =>({
  switchBase: {
    color: theme.palette.primary.dark,
    '&$checked': {
      color: theme.palette.primary.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  checked: {},
  track: {},
}))(Switch);
const AdminGreenTextField = withStyles(theme =>({
  root: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
}))(TextField);

const AddContactListDialog = ({forceOpen = false, setForceOpen, refetch, size, invisible, title = undefined}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(invisible);
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState('');
  const [dynamicList, setDynamicList] = useState(true);
  const [dynamicVar, setDynamicVar] = useState('email');
  const [dynamicType, setDynamicType] = useState('equal');
  const [dynamicValue, setDynamicValue] = useState();
  const [dynamicVarList, setDynamicVarList] = useState([]);
  
  useEffect(()=>{
    setOpen(forceOpen)
  },[forceOpen])
  
  const handleToggleOpen = useCallback(() => {
    setOpen(!open);
    if(forceOpen && open){
      setForceOpen(false)
    }
  },[open, forceOpen, setForceOpen]);
  
  const handleInputChange = useCallback((event) => {
    setName(event.target.value);
  },[]);
  
  const handleDynamicListEnable = useCallback((event) => {
    setDynamicList(event.target.checked);
  },[]);
  
  const handleSave = useCallback(() => {
    const cleanName = name.trim();
    if(cleanName.length > 3){
      setSaving(true);
      postContactList(cleanName, dynamicVarList).then(()=>{
        setSaving(false);
        setOpen(false);
        setName('')
        setDynamicList(true);
        refetch();
      })
    }
  },[name, dynamicVarList]);
  
  const handleAddDynamicListItem = useCallback(() => {
    const tempList = [{
      id: Date.now(),
      varType:'email',
      dynamicType:'equal',
      dynamicValue:'',
    }, ...dynamicVarList]
    setDynamicVarList(tempList)
  },[dynamicVarList]);
  
  const handleUpdateDynamicListItem = useCallback((id, varType, dynamicType, dynamicValue) => {
    const tempList = dynamicVarList.map( item => {
    return item.id !== id ? item : {
      id: id,
      varType:varType,
      dynamicType:dynamicType,
      dynamicValue:dynamicValue,
    }
    })
    setDynamicVarList(tempList)
  },[dynamicVarList]);
  
  const handleDeleteDynamicListItem = useCallback((id) => {
    const tempList = [...dynamicVarList.filter(item=>item.id !== id)]
    setDynamicVarList(tempList)
  },[dynamicVarList]);
  
  return (
    <>
      {!invisible && <Button className={size == 'small' ? classes.addPatientListSmall : size == 'text' ? classes.addPatientListText : classes.addPatientList} variant={'contained'}
               onClick={handleToggleOpen} disabled={open || saving}>
        {size != 'text' && <Add/>} {size == 'text' && <Typography>{title ?? 'New List'}</Typography>}
      </Button>}
      <Dialog
        maxWidth={dynamicList ? 'md' : 'sm'}
        fullWidth={true}
        open={open}
        onClose={handleToggleOpen}
        aria-labelledby="add-patient-list-dialog-form" className={classes.form}
      >
        <DialogTitle id="add-patient-list-dialog-title"><GroupAdd/> {saving ? 'Saving' : 'Add a new'} Contact List</DialogTitle>
        <DialogContent>
          {
            saving ?
              <ProgressBar style={{margin:'50px auto 50px'}}/>
              :
              <>
                <DialogContentText>
                  Please enter a nick name and whether the list grows dynamically.
                </DialogContentText>
                <form noValidate>
                  
                  <AdminGreenTextField
                    autoFocus={true}
                    value={name}
                    onChange={handleInputChange}
                    variant={'outlined'}
                    fullWidth={true}
                    label={<><Create/> List Name</>} placeholder={'e.g., Sales List'}
                  />
                  
                  <FormControlLabel
                    className={classes.formControlLabel}
                    control={<AdminGreenSwitch checked={dynamicList} onChange={handleDynamicListEnable} />}
                    label={<><Tune/> Dynamic List?</>}
                    labelPlacement={'start'}
                  />
  
                  <DialogContentText>
                    Dynamically add Contacts to a Contact List based on a mixture of variables.
                  </DialogContentText>
                  {dynamicList && <Grid
                     container
                     direction="row"
                     justifyContent="space-between"
                     alignItems="center"
                     spacing={0}
                     className={classes.container}>
                    <>
                    <Grid item xs={2}>
                      <DialogContentText>
                        Variable
                      </DialogContentText>
                    </Grid>
                    <Grid item xs={2}>
                      <DialogContentText>
                        Type
                      </DialogContentText>
                    </Grid>
                    <Grid item xs={5}>
                      <DialogContentText>
                        Value
                      </DialogContentText>
                    </Grid>
                    <Grid item xs={2}>
                      <DialogContentText>
                        Add
                      </DialogContentText>
                    </Grid>
                    </>
                    {
                      dynamicVarList.sort((a, b) => {
                        return a.id > b.id
                      }).map((item)=>{
                        return <Grid
                           container
                           direction="row"
                           justifyContent="space-between"
                           alignItems="center"
                           spacing={0} key={item.id} className={classes.dynamicVarListItem}>
                          <Grid item xs={2}>
                            <Select
                               labelId="dynamic-var-label"
                               id="dynamic-var"
                               value={item.varType}
                               onChange={event=>handleUpdateDynamicListItem(item.id, event.target.value, item.dynamicType, item.dynamicValue)}>
                              <MenuItem value={'hashtag'}>Hashtag</MenuItem>
                              <MenuItem value={'email'}>Email</MenuItem>
                              <MenuItem value={'phone_number'}>Phone</MenuItem>
                              <MenuItem value={'first_name'}>First Name</MenuItem>
                              <MenuItem value={'last_name'}>Last Name</MenuItem>
                              <MenuItem value={'username'}>Username</MenuItem>
                              <MenuItem value={'gender'}>Gender</MenuItem>
                              <MenuItem value={'organization'}>Organization</MenuItem>
                              <MenuItem value={'dob'}>DOB</MenuItem>
                              <MenuItem value={'address'}>Address</MenuItem>
                              <MenuItem value={'city'}>City</MenuItem>
                              <MenuItem value={'state'}>State</MenuItem>
                              <MenuItem value={'postal_code'}>Postal Code</MenuItem>
                              <MenuItem value={'country'}>Country</MenuItem>
                              <MenuItem value={'source'}>Source</MenuItem>
                              <MenuItem value={'locale'}>Locale</MenuItem>
                              <MenuItem value={'email_opt_in'}>Email Opt-In</MenuItem>
                              <MenuItem value={'email_opt_in_time'}>Email Opt-In Time</MenuItem>
                              <MenuItem value={'sms_opt_in'}>SMS Opt-In</MenuItem>
                              <MenuItem value={'sms_opt_in_time'}>SMS Opt-In Time</MenuItem>
                              <MenuItem value={'created_at'}>Created At</MenuItem>
                              <MenuItem value={'updated_at'}>Updated At</MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={2}>
                            <Select
                               labelId="dynamic-type-label"
                               id="dynamic-type"
                               value={item.dynamicType}
                               onChange={event=>handleUpdateDynamicListItem(item.id, item.varType, event.target.value, item.dynamicValue)}>
                              <MenuItem value={'equal'}>Is Equal</MenuItem>
                              <MenuItem value={'not_equal'}>Is Not Equal</MenuItem>
                              <MenuItem value={'include'}>Includes</MenuItem>
                              <MenuItem value={'exclude'}>Excludes</MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={5}>
                            <AdminGreenTextField
                               value={item.dynamicValue}
                               onChange={event=>handleUpdateDynamicListItem(item.id, item.varType, item.dynamicType, event.target.value)}
                               variant={'outlined'}
                               fullWidth={true}
                               
                               
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Button size={'small'} variant={'outlined'} onClick={()=>handleDeleteDynamicListItem(item.id)}><Delete style={{margin:10}}/></Button>
                          </Grid>
                        </Grid>
                      })
                    }
                  </Grid>}
                  {dynamicList && <Button style={{float:'right'}} variant={'outlined'} onClick={handleAddDynamicListItem}><Add style={{margin:5}}/></Button>}
                </form>
              </>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleOpen}
                  className={classes.cancelButton}
                  disabled={saving}>
            Cancel
          </Button>
          <Button onClick={handleSave}
                  className={classes.addButton}
                  disabled={saving}>
            Add List
          </Button>
        </DialogActions>
      </Dialog>
      
    </>
  );
}

export default AddContactListDialog;
