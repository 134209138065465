import React, {useCallback, useEffect, useState} from 'react';
import {
  Box,
  List,
  ListItem,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
  ListItemText, ListItemSecondaryAction, Button, Switch, Divider, IconButton, Menu, MenuItem
} from "@material-ui/core";
import {AddAPIKey, AddOrganizationInviteDialog, CopyAPIKey, ProgressBar} from '../../../components'
import useOrganizationInvitations from "../../../lib/hooks/useOrganizationInvitations";
import {updateOrganizationInvitation} from "../../../lib/api";
import useOrganizationTeammates from "../../../lib/hooks/useOrganizationTeammates";
import {MoreVert} from "@material-ui/icons";
import ManagePermissionsDialog from "../../../components/ManagePermissionsDialog/ManagePermissionsDialog";
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    margin:'auto auto -10px',
    paddingBottom:20
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    '& ul':{
      width:'100%',
    },
    '& li':{
      textAlign: 'center',
      display: 'block'
    },
    '& a':{
      color: theme.palette.primary.main,
      textDecoration: 'none'
    },
  },
  patientLists:{
    marginTop:20,
  },
  title:{
    marginTop:20,
    textAlign: 'center',
    display: 'block'
  }
}));
const NotificationSettings = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const [menuAnchor, setMenuAnchor] = useState(undefined);
  const [apiKey, setApiKey] = useState(undefined);
  const [managingUser, setManagingUser] = useState(undefined);
  const [saving, setSaving] = useState(false);
  const [openPermissions, setOpenPermissions] = useState(false);
  const {isLoading, isRefetching, data: teammates, refetch} = useOrganizationTeammates();
  
  const toggleMenu = useCallback((event) => {
    setMenuAnchor(menuAnchor ? null : event.currentTarget);
  },[menuAnchor])
  
  useEffect(()=>{
      setMenuAnchor(null)
  },[openPermissions])
  
  const handleTeammateUpdate = useCallback((invite) => {
    if(invite){
      setSaving(true);
      const options = {
        active: false,
      }
      updateOrganizationInvitation(invite.id, options).then(()=>{
        setSaving(false);
        refetch()
      })
    }
  },[refetch])
  
  const [rows, setRows] = useState([
    {
      type:'latest_features',
      value: true
    },
    {
      type:'weekly_report',
      value: true
    },
    {
      type:'monthly_report',
      value: true
    },
    {
      type:'split_test_winner',
      value: true
    },
    {
      type:'campaign_completion',
      value: true
    },
    {
      type:'contact_created_via_widget',
      value: true
    },
    {
      type:'sms_reply',
      value: true
    },
    {
      type:'teammate_joined',
      value: true
    },
    {
      type:'api_alerts',
      value: true
    },
    {
      type:'verified_domain_alerts',
      value: true
    },
  ])
  
  const groups = [
     'Get notified about our latest features:',
     'Get notified when your weekly report is ready:',
     'Get notified when your monthly report is ready:',
     'Get notified when a Split-Test Winner has been chosen:',
     'Get notified when a Campaign finishes:',
     'Get notified when a new Contact is created via an Embed Widget:',
     'Get notified when someone replies to you via SMS:',
    'Get notified when a new Teammate joins:', '' +
    'Get notified when an API key is Created or Removed:',
    'Get notified when a Verified Domain is Added or Removed:'
  ]
  
  
  return (
       <Box className={classes.container}>
         <Typography className={classes.title}>Easily manage your notification settings.</Typography>
         {
           isLoading || isRefetching || saving ?
              <ProgressBar style={{margin:150}}/>
              :
              groups.length ?
                 <List>
                   {groups.map(item=>{
                     return <ListItem key={item}>
                       <ListItemText primary={item}/>
                       <ListItemSecondaryAction>
                         <Switch checked={true}/>
                       </ListItemSecondaryAction>
                     </ListItem>
                   })}
                   
                   <Divider style={{marginTop:20,marginBottom:20}}/>
                   <ListItem>
                     <ListItemText primary={''} secondary={''}/>
                     <ListItemSecondaryAction>
                       <Button variant={'contained'} color={'standard'} style={{marginRight:10}}>Billing Notifications</Button>
                       <Button variant={'contained'} color={'primary'}>Save</Button>
                     </ListItemSecondaryAction>
                   </ListItem>
                 </List>
                 :
                 <>
                   <Typography className={classes.title}>Let&apos;s send your first invite now.</Typography>
                   {apiKey == undefined ? <AddAPIKey setApiKey={setApiKey} /> : <CopyAPIKey api={apiKey} />}
                 </>
         }
         {managingUser && <ManagePermissionsDialog forceOpen={openPermissions} setForceOpen={setOpenPermissions} user={managingUser}
                                   invisible={true}/>}
       </Box>
    );
}

export default NotificationSettings;
