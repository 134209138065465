import React, { useCallback, useContext, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  TextField,
  withStyles,
} from '@material-ui/core';
import { Share } from '@material-ui/icons';
import { PartnerContext } from '../../lib/contexts';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.admin.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.admin.main,
    color: theme.palette.admin.light,
    width:214,
    height:50,
    padding:0,
    '&:hover':{
      backgroundColor: theme.palette.admin.dark,
      color: theme.palette.admin.light,
    }
  },
  progressBar:{
    display: 'block',
    margin:'50px auto 50px',
    backgroundColor: theme.palette.admin.light
  },
  progressBarColor:{
    backgroundColor: theme.palette.admin.main
  },
  shareSwitch:{
    color: theme.palette.admin.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.admin.main,
  },
}));

const AdminGreenTextField = withStyles(theme =>({
  root: {
    borderColor: theme.palette.admin.main,
    color: theme.palette.admin.main,
    '&:hover': {
      borderColor: theme.palette.admin.main,
      color: theme.palette.admin.main,
    },
  },
}))(TextField);

const ShareLinkDialog = ({ handleClose, link }) => {
  const classes = useStyles();
  const {
    state: { code: partnerCode },
  } = useContext(PartnerContext);
  
  const [copied, setCopied] = useState(false);
  const shareURL = useMemo(()=> link ?? 'https://'+partnerCode+'.sendboth.com',[partnerCode, link])
  
  const copyTextToClipboard = useCallback(async () => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(shareURL);
    } else {
      return document.execCommand('copy', true, shareURL);
    }
  },[shareURL]);
  
  const handleCopy = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard()
      .then(() => {
        // If successful, update the isCopied state value
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  return (
    <>
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        open={true}
        onClose={handleClose}
        aria-labelledby="share-dialog-title" className={classes.form}
      >
        <DialogTitle id="share-dialog-title"><Share/> {copied ? 'Share URL Copied!' : 'Share URL'}</DialogTitle>
        <DialogContent>
            <>
                <DialogContentText>
                  Copy & Share your SendBoth Assessment URL below:
                </DialogContentText>
                <form noValidate>
                  
                  <AdminGreenTextField
                    variant={'outlined'}
                    fullWidth={true}
                    value={shareURL}
                  />
                </form>
              </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}
                  className={classes.cancelButton}>
            Cancel
          </Button>
          <Button onClick={handleCopy}
                  className={classes.addButton}
          >
            Copy URL
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default ShareLinkDialog;