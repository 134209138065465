import React, { useMemo, useState, useEffect } from 'react';

import {
  Box,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import useContactLists from '../../../lib/hooks/useContactLists';
import IndividualCard from './IndividualCard';
import { ProgressBar } from '../../../components/ProgressBar';
import { AddContactListDialog } from '../../../components/AddContactListDialog';
const useStyles = makeStyles(theme => ({
  progressBarContainer:{
    width:214,
    marginRight:25,
  },
  patientListCard:{
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginRight:25,
    width:214,
    height:50,
    padding:0
  },
  patientListCardMoreButton:{
    marginTop:-5,
    marginRight:-10,
    padding:0
  }
}));

const ContactListsCards = () => {
  const classes = useStyles();
  const {isLoading, isRefetching, data, refetch } = useContactLists();
  const contactLists = useMemo(()=> data,[data]);
  const [forceOpen, setForceOpen] = useState(false);
  useEffect(() => {
    const callback = (event) => {
      // event.metaKey - pressed Command key on Macs
      // event.ctrlKey - pressed Control key on Linux or Windows
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'KeyN') {
        event.preventDefault();
        setForceOpen(true)
      }
      if (event.code === 'Escape') {
        setForceOpen(false)
      }
      
    };
    document.addEventListener('keydown', callback);
    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, [forceOpen]);
  
  return (
          <>
            {isLoading || isRefetching ?
              <Box className={classes.progressBarContainer}>
                <ProgressBar style={{margin:'20px auto 20px'}}/>
              </Box>
              :
              <>
                {(forceOpen || !forceOpen) && <AddContactListDialog refetch={refetch} forceOpen={forceOpen} setForceOpen={setForceOpen}/>}
                {contactLists && contactLists.length > 0 && contactLists.map((list, index) => {
                  return (
                    <IndividualCard listItem={list} key={index} refetch={refetch}/>
                  )
                })
                }
                </>
                }
          </>
  );
};

export default ContactListsCards;
