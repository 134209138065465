import { createContext, useCallback, useMemo, useState } from 'react';
import {LoggedOutDialog} from '../../components/LoggedOutDialog'
import {minusMonth, prettyDate} from "../helpers/helpers";

export const UserContext = createContext({});
export const UserContextProvider = ({ children, props }) => {
  const [userObject, setUser] = useState(localStorage.getItem('SB_AUTH') ? JSON.parse(localStorage.getItem('SB_AUTH')) : {});
  const user = useMemo(()=>Object.assign({}, userObject, userObject.user), [userObject]);
  const pageSlug = useMemo(()=> window.location.href.substring(window.location.href.lastIndexOf('/') + 1),[window.location])
  
  
  //universal helpers
  const [showingSearch, setShowingSearch] = useState(false);
  const [showingHelp, setShowingHelp] = useState(false);
  //report helpers
   const [showingRange, setShowingRange] = useState(false);
   const [startDate, setStartDate] = useState(minusMonth(new Date()));
   const [endDate, setEndDate] = useState(prettyDate(new Date()));
   
  
   const logout = useCallback(() => {
    //alert('Logged Out');
    localStorage.removeItem('SB_AUTH');
    setUser({});
    window.location = window.location.protocol + '//' + window.location.host + '/';
  }, []);
  
   const forceLogout = useCallback(() => {
    localStorage.removeItem('SB_AUTH');
    setUser({});
  }, []);
  return (
    <UserContext.Provider
      value={{
        actions: {
           setUser,
           logout,
           forceLogout,
           setShowingSearch,
           setShowingHelp,
           setStartDate,
           setEndDate,
           setShowingRange
        },
        state: {
           showingRange,
           startDate,
           endDate,
           showingSearch,
           showingHelp,
           ...user,
           plan: user ? user.plan : '',
        }
      }}
      {...props}
    >
      {children}
       {user && !user.user_id && pageSlug !== '' && <LoggedOutDialog logout={logout}/>}
    </UserContext.Provider>
  );
};
