import { useQuery } from 'react-query';
import { useState } from 'react';

const useCurrentUser = () => {
  const userObject = JSON.parse(localStorage.getItem('SB_AUTH')) || JSON.parse(sessionStorage.getItem('SB_AUTH')) || undefined;
  

  return {
    token: userObject ? userObject.token : undefined,
    userID: userObject ? userObject.user_id : undefined,
    //firstName: userObject ? userObject.user.first_name : undefined,
  }
  /*
  const result = useQuery('current_user', () => postUserAuth(requestParams), {
    refetchOnWindowFocus: false,
    refetchInterval: 50000,
  });
  return result.data;*/
};

export default useCurrentUser;
