import * as React from 'react';
import {  Grid, Typography } from "@material-ui/core";
import Chart from "react-apexcharts";
import useMetric from "../../../lib/hooks/useMetric";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import {useParams} from "react-router";
import AddCampaignDialog from "../../../components/AddCampaignDialog/AddCampaignDialog";
export default function TotalOpensClicksByCampaign() {
   const {campaignId} = useParams();
   const {isLoading, isRefetching, data, refetch} = useMetric('total_opens_clicks_by_campaign', 'campaign', campaignId);
  return (
    <>
      <Typography variant={'h2'} color={'primary'}>
        Total Opens & Clicks by Campaign
      </Typography>
      <Grid
         item
         container
         justifyContent="space-between"
         alignItems="center"
         spacing={2}
         xs={12}
      >
         {isLoading || isRefetching && <ProgressBar style={{margin:20}}/>}
         {!isLoading && !isRefetching && !data && <AddCampaignDialog refetch={refetch} size={'text'} title={'Create Campaign'} />}
         {!isLoading && !isRefetching && data && data.metric.map(campaign=>{
            return <Grid item xs={6} key={campaign.id}>
                  <Chart
                     options={{
                        colors: [function({ value, seriesIndex, w }) {
                           if (value < 55) {
                              return '#546E7A'
                           } else {
                              return '#D9534F'
                           }
                        }, function({ value, seriesIndex, w }) {
                           if (value > 111) {
                              return '#546E7A'
                           } else {
                              return '#D9534F'
                           }
                        }],
                        title: {
                           text: campaign.name,
                           align: "left"
                        },
                        chart: {
                           id: "bar"
                        },
                        plotOptions: {
                           bar: {
                              horizontal: true
                           }
                        },
                        xaxis:{
                           labels: {
                              show: false
                           }
                        }
                     }}
                     series={[{
                        data: [{
                           x: 'Total Opens',
                           y: campaign.total_opens
                        }, {
                           x: 'Total Clicks',
                           y: campaign.total_clicks
                        }]
                     }]}
                     type="bar"
                     height={120}
                  />
               </Grid>
         })}
      </Grid>
    </>
  );
}
