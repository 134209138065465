import React, { useContext, useMemo } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid, ListItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery, useTheme,
} from '@material-ui/core';
import { PatientTabsContext } from '../../../lib/contexts';
import useMetric from '../../../lib/hooks/useMetric';
import { ProgressBar } from '../../../components/ProgressBar';
import { PatientLatestAssessmentRow } from './index';

const useStyles = makeStyles(theme => ({
  patientChartCard: theme.globalClasses.patientChartCard,
  container:{
    maxHeight: 600,
    overflowY: 'scroll'
  },
  topBar:{
    marginBottom:10
  },
  tableHead: theme.globalClasses.adminTableHead,
  tableRow: theme.globalClasses.adminTableRow
}));

const PatientLatestAssessmentsBox = ({ summary, setSetSubtab }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.up('sm'));
  const matchMD = useMediaQuery(theme.breakpoints.up('md'));
  const matchLG = useMediaQuery(theme.breakpoints.up('lg'));
  const matchXL = useMediaQuery(theme.breakpoints.up('xl'));
  const {
    state: { currentTab },
  } = useContext(PatientTabsContext);
  const {isLoading, data } = useMetric('latest_assessments', currentTab.id);
  return (
    <Grid item xs={summary ? (matchXL || matchLG) ? 9 : 12 : 12} >
      <Paper className={classes.patientChartCard}>
        <Grid item container xs={12} className={classes.topBar}>
          <Grid item xs={11}>
            <Typography variant={'h2'}>Latest Assessments</Typography>
          </Grid>
          {summary && <Grid item xs={1}>
            <Typography variant={'h3'} onClick={() => setSetSubtab('1')}>View All</Typography>
          </Grid>}
        </Grid>
        {isLoading ?
          <ProgressBar /> :
          <Box className={classes.container}>
            <Table>
              <TableHead className={classes.tableHead}>
                <TableRow className={classes.tableRow}>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Diet Quality</TableCell>
                  <TableCell align="left">Goal Diet</TableCell>
                  <TableCell align="left">Weight Trend</TableCell>
                  {!summary && <>
                    <TableCell align="left">HEI</TableCell>
                    <TableCell align="left">Age</TableCell>
                    <TableCell align="left">Height</TableCell>
                    <TableCell align="left">Weight</TableCell>
                    <TableCell align="left">Gender</TableCell>
                    
                    <TableCell align="left">Pre / Post Diet Type</TableCell>
                    <TableCell align="left">Pre / Post Diet Quality</TableCell>
                    <TableCell align="left">Pre / Post Quality Change</TableCell>
                    <TableCell align="left">Days between Pre / Post</TableCell>
                    <TableCell align="left">Pre / Post BMI Change</TableCell>
                    <TableCell align="left">Pre / Post Weight Change</TableCell>
                  </>}
                  <TableCell align="center">Report</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
            {data && data.metric && data.metric.value
              .slice(0, summary ? 3 : data.metric.value.length).map((item, index)=>{
              return (
                <PatientLatestAssessmentRow key={index} row={item} summary={summary}  />
              )
            })}
              </TableBody>
            </Table>
          </Box>
        }
      </Paper>
    </Grid>
  );
};

export default PatientLatestAssessmentsBox;
