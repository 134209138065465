import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
  Grid, InputBase, Paper, Typography,
} from '@material-ui/core';
import { PatientTabsContext } from '../../../lib/contexts';
import useMetric from '../../../lib/hooks/useMetric';
import { ProgressBar } from '../../../components/ProgressBar';
import { postPatientAlert } from '../../../lib/api/postPatientAlert';

const useStyles = makeStyles(theme => ({
  patientChartCard: {
    borderWidth:1,
    borderRadius:5,
    borderStyle:'solid',
    borderColor: theme.palette.admin.border,
    ...theme.globalClasses.patientChartCard,
  },
  textarea:{
    color: theme.typography.helper.main+' !important',
    fontSize: 16,
  }
}));

const PatientAlertBox = () => {
  const classes = useStyles();
  const {
    state: { currentTab },
  } = useContext(PatientTabsContext);
  const { isLoading, data } = useMetric('alert', currentTab.id);
  const [isSaving, setIsSaving] = useState( false);
  const [content, setContent] = useState( '');
  const [lastContent, setLastContent] = useState( '');
  const throttling = useRef(false)
  
  
  useEffect(()=>{
    if(data){
      setContent(data.metric.value || '');
      setLastContent(data.metric.value || '');
    }
  },[data]);
  
  const handleChange = useCallback((event)=> {
    const content = event.target.value;
    setContent(content)
  },[]);
  
  const throttleSave = () => {
    if (isSaving || content == lastContent) {
      return
    }
    const cleanContent = content.trim();
    if (cleanContent.length > 3){
    setTimeout(() => {
      setIsSaving(true);
      postPatientAlert(currentTab.id, cleanContent).then(() => {
        setIsSaving(false);
        setLastContent(cleanContent)
      })
    }, 500, [currentTab, cleanContent])
  }
    
  };
  
  return (
    <Grid item xs={12}>
      <Paper className={classes.patientChartCard}>
        <Grid item container xs={12}>
                <Grid item xs={1}>
                  <Typography variant={'h2'}>Alert</Typography>
                </Grid>
                <Grid item xs={11}>
                  <InputBase value={content} onChange={handleChange} onBlur={throttleSave} className={classes.textarea} multiline minRows={2} fullWidth placeholder={'Enter a shared message here that will always show up when you open this patient\'s record. Use it for helpful reminders about any triggers, barriers, family notices, etc.'} style={{marginTop:12}}/>
                  {(isLoading || isSaving) && <ProgressBar style={{margin:'0px auto 12px'}} />}
                </Grid>
              </Grid>
      </Paper>
    </Grid>
  );
}

export default PatientAlertBox;
