import React, { useContext, useMemo } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar, Box, Divider,
  Grid, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Paper, Tooltip, Typography,
} from '@material-ui/core';
import { PatientTabsContext } from '../../../lib/contexts';
import useMetric from '../../../lib/hooks/useMetric';
import { ProgressBar } from '../../../components/ProgressBar';
import {
   ArrowDropDown, ArrowDropUp,
  EmojiObjects,
  FlashOn, InfoRounded,
  Print,
} from '@material-ui/icons';
import { AdminButton } from '../../../components/AdminButton';
import { roundHalf, toCapitalize } from '../../../lib/helpers/helpers';
import { BillingContext } from '../../../lib/contexts/BillingContext';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  container:{
    margin:10
  },
  patientChartCard: {
    ...theme.globalClasses.patientChartCard,
    position:'relative',
    overflow: 'hidden',
    marginTop:20,
    '& h1':{
      textAlign:'center'
    },
    '& p':{
      marginTop:10,
      textAlign:'center',
      display:'block',
      width: '100%'
    },
    '& hr':{
      height:4,
      backgroundColor:theme.palette.admin.recommended
    },
    borderRadius: 10,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.common.grey['300']
  },
  chosenPlan:{
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.admin.recommended
  },
  starter:{backgroundColor:theme.palette.admin.starter},
  premium:{backgroundColor:theme.palette.admin.premium},
  enterprise:{backgroundColor:theme.palette.admin.enterprise},
  recommended:{backgroundColor:theme.palette.admin.recommended},
  recommendedBar:{
    backgroundColor:theme.palette.admin.recommended,
    color: theme.palette.common.white,
    boxShadow: '1px 1px '+ theme.palette.common.grey['600'],
    position:'absolute',
    right: -28,
    top: 28,
    fontSize:14,
    paddingLeft:19,
    paddingRight:20,
    transform: 'rotate(45deg)'
  }
  
  
}));

const SelectPlanCard = ({type, schedule}) => {
  const classes = useStyles();
  const {
    state: { chosenPlan, chosenSchedule, starterPrice, starterPriceMonthly, premiumPrice, premiumPriceMonthly },
    actions: { setChosenPlan },
  } = useContext(BillingContext);
  return (
    <Grid item container xs={4}>
      <Box
        className={clsx(
          classes[type],
          classes.patientChartCard,
          chosenPlan === type && classes.chosenPlan)
        }
        onClick={()=>setChosenPlan(type)}>
        <Grid item container xs={12} spacing={1} justifyContent={'space-around'}>
          <Grid item xs={12}>
            {type === 'premium' && <Box className={classes.recommendedBar}>Recommended</Box>}
            <Typography variant={'h1'}>{toCapitalize(type)}</Typography>
            <Typography variant={'h1'}>{
              {
                'starter': "$"+ (chosenSchedule == 'year' ? starterPrice : starterPriceMonthly),
                'premium': "$"+ (chosenSchedule == 'year' ? premiumPrice : premiumPriceMonthly),
                'enterprise': 'Custom'
              }[type]
            }</Typography>
            <Typography variant={'body1'}>per {chosenSchedule}</Typography>
          </Grid>
          <Divider/>
          {
            {
              'starter': <>
                <Typography>1 provider</Typography>
                <Typography>Up to 100 Patients/Clients</Typography>
                <Typography>Rapid Dietary Assessment</Typography>
                <Typography>Goal Setting</Typography>
                <Typography>Basic Branding</Typography>
                <Typography>Resource Library</Typography>
              </>,
              'premium': <>
                <Typography>Multiple Providers</Typography>
                <Typography>Up to 250 Patients per Provider</Typography>
                <Typography>Rapid Dietary Assessment</Typography>
                <Typography>Goal Setting</Typography>
                <Typography>Daily Challenges</Typography>
                <Typography>Expanded Branding</Typography>
                <Typography>Custom Goals List</Typography>
              </>,
              'enterprise': <>
                <Typography>All of Premium plus:</Typography>
                <Typography>API Integration</Typography>
                <Typography>Custom Questions</Typography>
                <Typography>System-wide Deployment</Typography>
              </>
            }[type]
          }
        </Grid>
        
      </Box>
    </Grid>
  );
};

export default SelectPlanCard;
