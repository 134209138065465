import React, { useContext, useMemo } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Grid, Paper, Typography,
} from '@material-ui/core';
import useMetric from '../../../lib/hooks/useMetric';
import { PatientTabsContext } from '../../../lib/contexts';
import { ProgressBar } from '../../../components/ProgressBar';

const useStyles = makeStyles(theme => ({
  container:{
    display:'inline-flex',
    overflowX: 'scroll',
    marginLeft:25,
  },
}));

const PatientTipOfDay = () => {
  const classes = useStyles();
  const {
    state: { currentTab },
  } = useContext(PatientTabsContext);
  const {isLoading, data } = useMetric('tip_of_the_day', currentTab.id);
  return (
    <Grid item className={classes.container}>
      {console.log(currentTab, data)}
      <Paper variant={'outlined'}>
        {isLoading ?
          <ProgressBar /> :
          <>
            {data && <>
              <Typography>{data.title}</Typography>
              <Typography>{data.metric.value}</Typography>
            </>}
          </>}
      </Paper>
    </Grid>
  );
};

export default PatientTipOfDay;
