import React, { useCallback, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Tune } from '@material-ui/icons';
import { AboutYou, APIKey, FirstList, ImportContacts, ChoosePlan } from './';

import useCheckoutToken from '../../lib/hooks/useCheckoutToken';
import {BillingContext} from "../../lib/contexts";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  onboardingForm: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    '& .MuiDialog-paperWidthSm':{
      minWidth: '50vw',
      width: '80%',
      maxWidth: '80%',
    },
    '& input':{
      borderColor: theme.palette.admin.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  shareSwitch:{
    color: theme.palette.admin.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.admin.main,
  },
  listBorder:{
    borderWidth:0,
    borderBottomWidth:2,
    borderStyle:'solid'
  }
}));


const getSteps = () => {
  return [
    'About You',
    'Embed Code',
    'First List',
    'Import Contacts',
    //'First Campaign',
    'Choose Plan'
  ];
}

const OnboardingDialog = ({patient, handleClose, refetch}) => {
  const {
    state: { chosenPlan, checkingOut },
    actions: { setChosenPlan, setCheckingOut },
  } = useContext(BillingContext);
  
  const { isLoading, data } = useCheckoutToken(chosenPlan);
  const handleRelocation = useCallback(()=>{
    console.log('checkingOut')
    console.log(data)
    window.location = data.url
  },[data])
  
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  
  const handleReset = () => {
    setActiveStep(0);
  };
  const handleCheckout = () => {
    setCheckingOut(true)
  };
  
  return (
    <>
      <Dialog
        fullWidth={true}
        open={true}
        onClose={handleClose}
        aria-labelledby="onboarding-dialog"
        className={classes.onboardingForm}
      >
        <DialogTitle id="onboarding-dialog-title"><Tune style={{marginBottom:-5}}/> Let&apos;s get you onboarded!</DialogTitle>
        <DialogContent>
          {
              <>
                <div className={classes.root}>
                  <DialogContentText style={{textAlign:'center', display:'none'}}>
                    Just a few questions to get you ready.
                  </DialogContentText>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <div>
                    {activeStep === steps.length ? (
                      <div>
                        <Typography className={classes.instructions}>All steps completed</Typography>
                        <Button onClick={handleReset}>Reset</Button>
                      </div>
                    ) : (
                      <>
                        {activeStep === 0 &&  <AboutYou/>}
                        {activeStep === 1 &&  <APIKey/>}
                        {activeStep === 2 &&  <FirstList/>}
                        {activeStep === 3 &&  <ImportContacts/>}
                        {/*activeStep === 4 &&  <FirstCampaign/>*/}
                        {activeStep === 4 &&  <ChoosePlan/>}
                      </>
                    )}
                    {checkingOut && handleRelocation()}
                  </div>
                </div>
              </>
          }
        </DialogContent>
        <DialogActions>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.backButton}
          >
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={activeStep === steps.length - 1 ? handleCheckout : handleNext}>
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default OnboardingDialog;
