import React, {Component, useEffect, useState} from 'react';
import {
  Select,
  MenuItem,
  InputLabel,
  Grid,
  List,
  ListItem,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
  FormControl,
  TextField,
  Avatar, ListItemText, ListItemSecondaryAction, Switch, Divider, Button, FormLabel
} from "@material-ui/core";
import { INDUSTRY_SECTORS } from '../../../lib/constants/constants'
import useOrganization from "../../../lib/hooks/useOrganization";
import {ColorPicker} from "material-ui-color";
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    margin:'auto auto -10px',
    paddingBottom:20
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    '& ul':{
      width:'100%',
    },
    '& li':{
      textAlign: 'center',
      display: 'block'
    },
  },
  patientLists:{
    marginTop:20,
  },
  title:{
    marginTop:20,
    textAlign: 'center',
    display: 'block'
  }
}));
const Design = () => {
  const classes = useStyles();
  const theme = useTheme();
  const {isLoading: isLoadingOrganization, isRefetching: isRefetchingOrganization, data: organization, refetchOrganization} = useOrganization();
  
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [poweredBy, setPoweredBy] = useState(true);
  const [icon, setIcon] = useState('');
  const [logoSmall, setLogoSmall] = useState('');
  const [logoLarge, setLogoLarge] = useState('');
  
  useEffect(()=>{
    if(organization){
      setPrimaryColor(organization.primary_color)
      setSecondaryColor(organization.secondary_color)
      setPoweredBy(organization.show_powered_by)
      setIcon(organization.icon)
      setLogoSmall(organization.logo_small)
      setLogoLarge(organization.logo_large)
    }
  },[organization])
  
  return (
     <>
       <Typography className={classes.title}>Quickly adjust the default designs for your templates, forms and landing pages.</Typography>
  
       <Grid container spacing={2} style={{marginTop:20}}>
         <Grid item xs={6} style={{textAlign:'center'}}>
           <FormControl variant="outlined">
             <Typography htmlFor="primaryColor">Primary Color</Typography>
             <ColorPicker id={'primaryColor'} value={primaryColor} onChange={value=>setPrimaryColor(value)} />
           </FormControl>
         </Grid>
         <Grid item xs={6} style={{textAlign:'center'}}>
           <FormControl variant="outlined">
             <Typography htmlFor="secondaryColor">Secondary Color</Typography>
             <ColorPicker id={'secondaryColor'} value={secondaryColor} onChange={value=>setSecondaryColor(value)} />
           </FormControl>
         </Grid>
         <Grid item xs={4} style={{textAlign:'center', alignItems:'center'}}>
           <FormControl variant="outlined">
             <Typography htmlFor="icon">Icon</Typography>
             <Avatar id='icon' style={{margin:'20px auto auto', width:90, height:icon ? 'auto' : 80}} src={icon}/>
           </FormControl>
         </Grid>
         <Grid item container xs={4} style={{textAlign:'center', alignItems:'center'}}>
           <FormControl variant="outlined">
             <Typography htmlFor="logoSmall">Light Logo</Typography>
             <Avatar id='logoSmall' variant={'square'} style={{margin:'20px auto auto', width:160, height:logoSmall ? 'auto' : 80}} src={logoSmall}/>
           </FormControl>
         </Grid>
         <Grid item container xs={4} style={{textAlign:'center', alignItems:'center'}}>
           <FormControl variant="outlined">
             <Typography htmlFor="logoLarge">Dark Logo</Typography>
             <Avatar id='logoLarge' variant={'square'} style={{margin:'20px auto auto', width:160, height:logoLarge ? 'auto' : 80}} src={logoLarge}/>
           </FormControl>
         </Grid>
         <Grid item xs={12}>
           <List style={{width:'90%',margin:'20px auto auto'}}>
             <ListItem>
               <ListItemText>Show &quot;Powered By SendBoth&quot;</ListItemText>
               <ListItemSecondaryAction>
                 <Switch checked={poweredBy} onChange={()=>setPoweredBy(!poweredBy)} disabled={true}/>
                 <Button variant={'contained'} color={'primary'} style={{marginLeft:10}}>Upgrade</Button>
               </ListItemSecondaryAction>
             </ListItem>
           </List>
         </Grid>
         <Grid item xs={12}>
           <List>
             <Divider style={{marginTop:20,marginBottom:20}}/>
             <ListItem>
               <ListItemText primary={''} secondary={''}/>
               <ListItemSecondaryAction>
                 <Button variant={'contained'} color={'primary'}>Save</Button>
               </ListItemSecondaryAction>
             </ListItem>
           </List>
         </Grid>

       </Grid>
     </>
  );
}

export default Design;
