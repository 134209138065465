import React, { useCallback, useContext, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Grid } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Chip,
  Typography,
  IconButton,
  FormControl,
  TextField,
  InputAdornment,
  withStyles,
} from '@material-ui/core';
import { Share, FileCopy, Create } from '@material-ui/icons';
import { CampaignContext, UserContext } from '../../../lib/contexts';
import { prettyDate, prettyTime } from '../../../lib/helpers/helpers';
import useTemplates from '../../../lib/hooks/useTemplates';
import {TemplateListing} from '../../TemplateListing/';
import {ProgressBar} from '../../ProgressBar/';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.admin.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.admin.main,
    color: theme.palette.admin.light,
    width:214,
    height:50,
    padding:0,
    '&:hover':{
      backgroundColor: theme.palette.admin.dark,
      color: theme.palette.admin.light,
    }
  },
  progressBar:{
    display: 'block',
    margin:'50px auto 50px',
    backgroundColor: theme.palette.admin.light
  },
  progressBarColor:{
    backgroundColor: theme.palette.admin.main
  },
  shareSwitch:{
    color: theme.palette.admin.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.admin.main,
  },
  item:{
    display: 'block',
    margin: 'auto',
    width: '70%',
    textAlign:'center',
    '& p':{
      textAlign:'left',
      
    }
  },
}));

const AdminGreenTextField = withStyles(theme =>({
  root: {
    borderColor: theme.palette.admin.main,
    color: theme.palette.admin.main,
    '&:hover': {
      borderColor: theme.palette.admin.main,
      color: theme.palette.admin.main,
    },
  },
}))(TextField);

const Review = () => {
  const classes = useStyles();
  
  const {
    state: { user_id },
  } = useContext(UserContext)
  const { isLoading, data:templateDataRaw, refetch:templateRefetch } = useTemplates();
  const templateData = useMemo(()=>templateDataRaw && !templateDataRaw.error ? templateDataRaw : [],[templateDataRaw]);
  const userTemplates = templateData ? templateData.filter(item=>item.creator === user_id) : [];
  
  const {
    state: {
      selectedCampaign,
      saving,
      campaignType,
      campaignName,
      campaignSubject,
      campaignFromName,
      campaignFromEmail,
      campaignTemplate,
      step,
      viewingCampaign,
      deliveryDate,
      deliveryTime,
      batchDelivery,
      batchCount,
      batchTime,
      timewarpDelivery,
      aiDelivery,
      campaignRecipientLists,
      campaignRecipientContacts,
    },
    actions: {
      setStep,
    },
  } = useContext(CampaignContext)
  
  const handleInputChange = useCallback((event) => {
    //setName(event.target.value);
  },[]);
  
  
  
  return (
    <>
      <Grid
         container
         direction="row"
         justifyContent="space-evenly"
         alignItems="center"
         spacing={0}
         className={classes.container}
      >
        {!saving && <>
          <Grid
             container
             direction="row"
             alignItems="center"
             justifyContent="space-between"
             spacing={4} xs={8}
             className={classes.imageList}>
            <Typography
               className={classes.item}>
              Let&apos;s Review your Campaign!
            </Typography>
            <Grid container item xs={12}>
              <Grid item xs={2}>
                <Typography color={'primary'}>
                  Settings
                </Typography>
              </Grid>
              <Grid container item xs={10}>
                <Grid item xs={6}>
                  <Typography>
                    Campaign Type:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {campaignType === 'email' ? 'Email' : campaignType === 'sms' ? 'SMS' : 'Email & SMS'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    Name:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {campaignName ?? 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    Subject:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {campaignSubject ?? 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    From Name:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {campaignFromName ?? 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    From Email:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {campaignFromEmail ?? 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={2}>
                <Typography color={'primary'}>
                  Template
                </Typography>
              </Grid>
              <Grid container item xs={10}>
                <Grid item xs={6}>
                  <Typography>
                    For Emails:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {campaignType !== 'sms' ? campaignTemplate ? campaignTemplate.name : 'N/A' : 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    For SMS:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {campaignType !== 'email' ? 'Generic' : 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={2}>
                <Typography color={'primary'}>
                  Recipients
                </Typography>
              </Grid>
              <Grid container item xs={10}>
                <Grid item xs={6}>
                  <Typography>
                    List:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {campaignRecipientLists.length ? campaignRecipientLists.map(item=><Chip color={'primary'} key={item} label={item.list_name} style={{margin:'0px 5px 5px 0px'}}/>) : 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    Contacts:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {campaignRecipientContacts.length ? <><b>{campaignRecipientContacts.length}</b> Chosen</> : 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={2}>
                <Typography color={'primary'}>
                  Schedule
                </Typography>
              </Grid>
              <Grid container item xs={10}>
                <Grid item xs={6}>
                  <Typography>
                    Start Date:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {deliveryDate ? prettyDate(deliveryDate) : 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    Start Time:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {deliveryTime ? prettyTime(deliveryTime) : 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    Batches:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <b>{batchCount}</b> batches every <b>{batchTime}</b> minutes
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    Time-Zone Timewarp:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {timewarpDelivery === false ? <Chip label={'Disabled'} style={{margin:'0px 5px 5px 0px'}}/> : <Chip color={'primary'} label={'Enabled'} style={{margin:'0px 5px 5px 0px'}}/> }
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    AI-Optimization:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {aiDelivery === false ? <Chip label={'Disabled'} style={{margin:'0px 5px 5px 0px'}}/> : <Chip color={'primary'} label={'Enabled'} style={{margin:'0px 5px 5px 0px'}}/> }
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>}
      </Grid>
      {saving &&
      <ProgressBar style={{margin:'50px auto 50px'}}/>}
    </>
  );
}
export default Review;
