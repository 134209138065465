import React, { useCallback, useContext, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  IconButton,
  FormControl,
  TextField,
  InputAdornment,
  withStyles,
} from '@material-ui/core';
import { Share, FileCopy } from '@material-ui/icons';
import { PartnerContext } from '../../lib/contexts';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.admin.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.admin.main,
    color: theme.palette.admin.light,
    width:214,
    height:50,
    padding:0,
    '&:hover':{
      backgroundColor: theme.palette.admin.dark,
      color: theme.palette.admin.light,
    }
  },
  progressBar:{
    display: 'block',
    margin:'50px auto 50px',
    backgroundColor: theme.palette.admin.light
  },
  progressBarColor:{
    backgroundColor: theme.palette.admin.main
  },
  shareSwitch:{
    color: theme.palette.admin.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.admin.main,
  },
}));

const AdminGreenTextField = withStyles(theme =>({
  root: {
    borderColor: theme.palette.admin.main,
    color: theme.palette.admin.main,
    '&:hover': {
      borderColor: theme.palette.admin.main,
      color: theme.palette.admin.main,
    },
  },
}))(TextField);

const CopyEmbedCode = ({ snippet }) => {
  const classes = useStyles();
  
  const [code, setCode] = useState(snippet || '<script async defer crossorigin="anonymous" src="https://embed.sendboth.com/sdk.jsversion=v1.0"></script><script>window.SendBoth && window.SendBoth.init(\'rz1frj\')</script>');
  const [copied, setCopied] = useState(false);
  
  const copyTextToClipboard = useCallback(async () => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(code);
    } else {
      return document.execCommand('copy', true, code);
    }
  },[code]);
  
  const handleCopy = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard()
      .then(() => {
        // If successful, update the isCopied state value
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  return (
    <>
              <FormControl style={{ maxWidth: 400, width: '100%', margin:'20px auto auto', display:'block' }}>
                <TextField
                  key='embed_code'
                  id='embed_code'
                  label='Your Embed Code'
                  value={code}
                  margin='normal'
                  variant='outlined'
                  multiline={true}
                  fullWidth={true}
                  type={'text'}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='copy embed code'
                          onClick={() => handleCopy()}
                        >
                          {<FileCopy />}
                        </IconButton>
                      </InputAdornment>,
                  }}
                />
              </FormControl>
    </>
  );
}
export default CopyEmbedCode;
