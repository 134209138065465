import React, { useCallback, useContext, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Grid } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  IconButton,
  FormControl,
  TextField,
  InputAdornment,
  withStyles,
} from '@material-ui/core';
import { Share, FileCopy, Create } from '@material-ui/icons';
import { CampaignContext } from '../../../lib/contexts';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.admin.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.admin.main,
    color: theme.palette.admin.light,
    width:214,
    height:50,
    padding:0,
    '&:hover':{
      backgroundColor: theme.palette.admin.dark,
      color: theme.palette.admin.light,
    }
  },
  progressBar:{
    display: 'block',
    margin:'50px auto 50px',
    backgroundColor: theme.palette.admin.light
  },
  progressBarColor:{
    backgroundColor: theme.palette.admin.main
  },
  shareSwitch:{
    color: theme.palette.admin.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.admin.main,
  },
}));

const AdminGreenTextField = withStyles(theme =>({
  root: {
    borderColor: theme.palette.admin.main,
    color: theme.palette.admin.main,
    '&:hover': {
      borderColor: theme.palette.admin.main,
      color: theme.palette.admin.main,
    },
  },
}))(TextField);

const CampaignSettings = ({ api }) => {
  const classes = useStyles();
  
  const {
    state: {
      selectedCampaigns,
      saving,
      campaignType,
      campaignName,
      campaignSubject,
      campaignFromName,
      campaignFromEmail,
      step,
    },
    actions: {
      setCampaignType,
      setCampaignName,
      setCampaignSubject,
      setCampaignFromName,
      setCampaignFromEmail,
      setStep,
    },
  } = useContext(CampaignContext)
  
  const handleInputChange = useCallback((event) => {
    //setName(event.target.value);
  },[]);
  
  
  
  return (
    <Grid
  item
  container
  xs={12}
  direction="row"
  justifyContent="space-between"
  alignItems="center"
  spacing={2}>
      <Grid item xs={campaignType === 'sms' ? 12 : 6} style={{textAlign: 'center'}}>
        <AdminGreenTextField
           value={campaignName}
           onChange={event=>setCampaignName(event.target.value)}
           variant={'outlined'}
           fullWidth={true}
           label={<><Create/> Campaign Name</>} placeholder={'e.g., Fall Newsletter'}
        />
      </Grid>
      {campaignType !== 'sms' && <Grid item xs={6} style={{textAlign: 'center'}}>
        <AdminGreenTextField
           value={campaignSubject}
           onChange={event=>setCampaignSubject(event.target.value)}
           variant={'outlined'}
           fullWidth={true}
           maxLength={150}
           label={<><Create/> Email Subject</>} placeholder={'e.g., Holiday Event'}
        />
      </Grid>}
  
      {campaignType !== 'sms' && <Grid item xs={6} style={{textAlign: 'center'}}>
        <AdminGreenTextField
           value={campaignFromName}
           onChange={event=>setCampaignFromName(event.target.value)}
           variant={'outlined'}
           fullWidth={true}
           maxLength={99}
           label={<><Create/> From Name</>} placeholder={'e.g., Party Planners'}
        />
      </Grid>}
      {campaignType !== 'sms' && <Grid item xs={6}>
        <AdminGreenTextField style={{textAlign: 'center', margin: 'auto', display: 'block'}}
                             value={campaignFromEmail}
                             onChange={event=>setCampaignFromEmail(event.target.value)}
                             variant={'outlined'}
                             fullWidth={true}
                             maxLength={99}
                             label={<><Create/> From Email</>} placeholder={'e.g., noreply@example.com'}
        />
      </Grid>}
    </Grid>
  );
}
export default CampaignSettings;
