import React, { useContext } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
} from '@material-ui/core';
import { BillingContext } from '../../../lib/contexts/BillingContext';

const useStyles = makeStyles(theme => ({
  container:{
    margin:10
  },
  patientChartCard: {
    ...theme.globalClasses.patientChartCard,
    '& p':{
      marginTop:10,
    }
  },
  title:{
    fontSize:'24px !important',
    textAlign: 'center',
    margin: 'auto'
  },
}));

const InvoiceTableRow = () => {
  const classes = useStyles();
  const {
    state: { invoices },
  } = useContext(BillingContext);
  
  
  
  return (
    <Grid item xs={4}>
    
    </Grid>
  );
};

export default InvoiceTableRow;
