import React from 'react';

import { Grid, Typography } from "@material-ui/core";
import { Screen } from '../../components';
import { makeStyles } from "@material-ui/core/styles";
import {
  APIEndpoints,
  ChooseIntegration,
  CustomizeIntegration,
  GettingStarted,
  RetrievingResults,
  Schemas, Typings,
} from './components';
const useStyles = makeStyles(theme => ({
  screen:{
    width:'96%',
    margin:'10px auto auto',
  },
  dashboard:{
    width:'92%',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginTop:20,
      marginBottom:20,
      color: theme.palette.admin.main,
      display: 'block'
    },
  },
  patientLists:{
    marginTop:20,
  }
}));

const APIDocScreen = () => {
  const classes = useStyles();
  return (
    <Screen title={'API Docs | SendBoth'} showSidebarNav={false} showAPISidebarNav={true} admin={true}>
          <Grid
            container
            alignItems="center"
            spacing={0}
            className={classes.screen}
          >
            <Typography variant={'subtitle1'}>
              SendBoth API Integration Documentation
            </Typography>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={4}
              className={classes.dashboard}
            >
              <GettingStarted/>
              <RetrievingResults/>
              <ChooseIntegration/>
              <CustomizeIntegration/>
              <APIEndpoints/>
              {/*<Typings />*/}
              <Schemas/>
            </Grid>
          </Grid>
    </Screen>
  );
};

export default APIDocScreen;
