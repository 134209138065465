import React, {useCallback, useContext, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Dialog} from '@material-ui/core/';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DateRange } from '@material-ui/icons';
import { ProgressBar } from '../ProgressBar';
import {dateToUnix, prettyDate, toCapitalize} from '../../lib/helpers/helpers';
import {Grid, TextField, Typography} from "@material-ui/core";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {UserContext} from "../../lib/contexts";

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
  },
  formControl: {
    padding:50,
    '& p':{
      marginBottom: 20,
    },
    '& h6':{
      marginTop: 0,
    },
    textAlign:'center'
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
}));

const DateRangeFilterDialog = ({ logout }) => {
  const classes = useStyles();
  const {
    state: {
      startDate,
      endDate
    },
    actions: {
      setStartDate,
      setEndDate,
      setShowingRange
    }
  } = useContext(UserContext);
  
  
  const [internalStartDate, setInternalStartDate] = useState(startDate);
  const [internalEndDate, setInternalEndDate] = useState(endDate);
  
  const updateDates = useCallback(()=>{
      setStartDate(prettyDate(internalStartDate));
      setEndDate(prettyDate(internalEndDate));
      setShowingRange(false);
  },[internalStartDate, internalEndDate]);
  
  return (
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        open={true}
        aria-labelledby="add-patient-list-dialog-title" className={classes.form}
        onClose={()=>setShowingRange(false)}
      >
        <DialogTitle id="add-patient-list-dialog-title"><DateRange/> Campaign Report Date-Range</DialogTitle>
  
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DialogContent className={classes.formControl}>
          <Grid container item xs={12} justifyContent={'space-between'} direction={'row'} spacing={2}>
            <Grid item xs={6}>
              <DatePicker
                 label="Begin Date"
                 value={internalStartDate}
                 onChange={(newValue) => {
                   setInternalStartDate(newValue);
                 }}
                 disablePast={false}
                 disableFuture={true}
                 maxDate={internalEndDate}
                 renderInput={(params) => <TextField {...params}
                                                     color="primary" variant="outlined" helperText={'Specify Begin Date.'} />}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                 label="End Date"
                 value={internalEndDate}
                 minDate={internalStartDate}
                 onChange={(newValue) => {
                   setInternalEndDate(newValue);
                 }}
                 disablePast={false}
                 disableFuture={true}
                 renderInput={(params) => <TextField {...params}
                                                     color="primary" variant="outlined" helperText={'Specify End Date.'} />}
              />
            </Grid>
          </Grid>
                <Button variant={'contained'} color={'primary'} onClick={updateDates} disabled={dateToUnix(internalEndDate) < dateToUnix(internalStartDate)}>
                  Update
                </Button>
          
        </DialogContent>
        </LocalizationProvider>
      </Dialog>
  );
}
export default DateRangeFilterDialog;
