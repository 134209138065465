import React from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
   CardContent, Grid, Paper, Typography,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container:{
    margin:10
  },
  patientChartCard: {
    ...theme.globalClasses.patientChartCard,
    '& p':{
      marginTop:10,
    }
  },
  title:{
    fontSize:'24px !important',
    textAlign: 'left',
    margin: 'auto'
  },
  pre:{
    whiteSpace:'pre-wrap'
  },
}));

const ChooseIntegration = () => {
  const classes = useStyles();
  return (
    <Grid item xs={12}><a id={'choose_integration'}/>
      <Paper className={classes.patientChartCard}>
        <CardContent>
          <Typography variant={'h1'} className={classes.title}>Choose Integration</Typography>
          <p>To handle user interactions, you can point the user to a white-labeled SendBoth page, or embed SendBoth within your app.</p>
          <h3>2.1 Direct URL</h3>
          <p>The simplest way to add SendBoth is to distribute the url for your custom, white-labeled product (e.g., <a href="https://your-subdomain.sendboth.com" rel="nofollow">https://your-subdomain.sendboth.com</a> or a custom domain) via your website, app, email, or other means.</p>
          <p>The experience is fully branded with your name, logo, and colors. When users visit the site they will create an account, go through the process, and any results can be manually sent to you.</p>
          <p>Setup for this usually takes less than a day.</p>
          <h3>2.2 Embedded</h3>
          <p>If you have an app or a website and you want SendBoth’s modules accessible there, you can embed SendBoth’s modules with a simple iframe. To do this simply add an iframe tag with the src attribute set to your custom url.</p>
          <p>If you’d like to hide certain elements of the SendBoth modules in your iframe, you can add parameters to the URL:</p>
          <p>Users will still need to create accounts or sign in to use the tools, but the experience will take place within your app or digital platform, and therefore appear more seamless.</p>
          <p>This method is perfect if you have a website or landing page where you drive all of your users, and you want SendBoth’s modules to “live” there, but you have limited tech resources.</p>
          <p>Set up for this usually takes one day.</p>
          <h3>2.3 Embedded with SSO</h3>
          <p>If you want your users to use SendBoth without having to create accounts, we can automatically create accounts for them via SSO. This allows for seamless switching between your app and SendBoth, and ensures a one-to-one mapping between your user accounts and corresponding SendBoth accounts.</p>
          <p>Getting this running will require some backend work, as you&#39;ll be generating SSO urls using a secret key shared between your backend and SendBoth&#39;s backend. See <code>getWorkflowUrl</code> in our <a href="/api/v1/docs/example/api_client" rel="nofollow">example client</a> for how to generate the SSO url server-side.</p>
          <p>Once the url is generated and sent to the frontend, simply set the <code>src</code> attribute of an iframe to the SSO url and you&#39;ll be good to go. Users who haven&#39;t completed an assessment will see the assessment, while existing users will see their results. Accounts are automatically deduped based on the user id you pass to SendBoth.</p>
          <h3>2.4 API</h3>
          <h3>API Environments:</h3>
          <ul>
            <li>Staging API: <a href="#" rel="nofollow">https://api.sendboth.com/api/:version/:endpoint</a></li>
            <li>Production API: <a href="#" rel="nofollow">https://api.sendboth.com/api/:version/:endpoint</a></li>
          </ul>
          <h3>API Rate-Limits</h3>
          <ul>
            <li>10 queries per second (QPS) per IP address.</li>
            <li>Rate limit of 1,000 requests per hour and per user.
              SendBoth reserves the right to enforce API Rate-Limit&#39;s at our discretion.</li>
            <li>Service may be affected if you are over limit for 24 consecutive hours.</li>
          </ul>
          <Grid container item xs={12} spacing={2} justifyContent={'space-between'}>
            <Grid item xs={2}><h3>Rate-Limit Error Response</h3></Grid>
            <Grid item xs={10}>
                      <pre className={classes.pre}>
                      <code>
                      &#123;
                        &quot;error&quot;: &quot;Throttle limit reached. Retry later.&quot;
                        &#125;
                    </code>
                      </pre>
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Grid>
  );
};
export default ChooseIntegration;
