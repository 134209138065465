import React, { useContext, useState } from 'react';

import { Grid, Typography, useTheme, useMediaQuery, Switch } from "@material-ui/core";
import { Screen, AccountCredits } from '../../components';
import MetricCard from '../../components/MetricCard/MetricCard';
import { ContactListsCards } from './components';
import { UserContext } from '../../lib/contexts';
import {makeStyles} from "@material-ui/core/styles";
import {DateRangeFilter} from "../ReportsScreen/components";
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
  },
  patientLists:{
    marginTop:20,
  }
}));

const DashboardScreen = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const [viewingAllTime, setViewingAllTime] = useState(false)
  const {
    state: { first_name },
  } = useContext(UserContext)
  
  return (
    <Screen title={'Dashboard | SendBoth'} showTopNav={true} showFooter={true}>
          <Grid
            container
            alignItems="center"
            spacing={0}
            className={classes.screen}
          >
            <Grid item xs={8}>
              <Typography variant={'subtitle1'}>
                Dashboard
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <AccountCredits />
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={0}
              className={classes.container}
            >
                <Typography variant={'h2'} color={'primary'}>
                  Campaign Overview - Last 30 Days
                  <Grid style={{display:'none'}} variant={'h4'} container alignItems="center" spacing={1}>
                    <Grid item>Last 30 Days</Grid>
                    <Grid item>
                      <Switch checked={viewingAllTime} onChange={()=>setViewingAllTime(!viewingAllTime)} name="viewingAllTime" />
                    </Grid>
                    <Grid item>All Time</Grid>
                  </Grid>
                </Typography>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0}
              >
                <MetricCard slug={'emails_sent'}/>
                <MetricCard slug={'total_opens'}/>
                <MetricCard slug={'unique_opens'}/>
                <MetricCard slug={'open_rate'}/>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0}
              >
                <MetricCard slug={'total_clicks'}/>
                <MetricCard slug={'unique_clicks'}/>
                <MetricCard slug={'click_rate'}/>
                <MetricCard slug={'unsubscribers'}/>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0}
              >
                {/*<MetricCard slug={'number_of_campaigns'}/>*/}
                <MetricCard slug={'sms_sent'}/>
                <MetricCard slug={'hard_bounces'}/>
                <MetricCard slug={'soft_bounces'}/>
                <MetricCard slug={'total_forwards'}/>
              </Grid>
              <Typography variant={'h2'} color={'primary'}>
                Quick Access - Contact Lists
              </Typography>
              <Grid
                container
                item
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0}
                className={classes.patientLists}
              >
                <ContactListsCards/>
              </Grid>
            </Grid>
          </Grid>
    </Screen>
  );
};

export default DashboardScreen;
