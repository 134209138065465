import { useQuery } from 'react-query';
import {getUser} from "../api";

const useUser = () => {
  const result = useQuery('user', () => getUser(), {
    refetchOnWindowFocus: false,
    staleTime: 50000,
  });
  return result;
};

export default useUser;
