import React, {useState, useCallback, useContext, useMemo, useEffect} from 'react';
import { useParams } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import {Button, List, Menu, MenuItem } from '@material-ui/core/';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { MoreVert, Delete, People, Settings, SaveAlt } from '@material-ui/icons/';
import { ListItemRow } from './index';
import { AddContactsToListsDialog, AddContactListDialog, DownloadProgressDialog } from '../../../components';
import { ContactTable } from '../../ContactsScreen/components/';
import useContacts from '../../../lib/hooks/useContacts.js';
import { ContactsContext, ContactsContextProvider } from '../../../lib/contexts/ContactsContext';
import ProgressBar from '../../../components/ProgressBar/ProgressBar.js';
import {deleteContactFromList, postExport} from "../../../lib/api";

const useStyles = makeStyles((theme) => ({
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    padding:10
  },
  content:{
    margin:'-20px auto auto',
    width:'100%',
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  noneFound:{
    padding:40,
    margin: 'auto',
    textAlign:'center',
    '& *':{
      display:'block',
      margin: 'auto',
      textAlign:'center',
    }
  }
}));

const SpecificList = () => {
  const {
    state: { selectedContacts },
    actions: { setSelectedContacts },
  } = useContext(ContactsContext);
  const classes = useStyles();
  const {listId} = useParams();
  const { isLoading, data, refetch } = useContacts(listId);
  const tableData = useMemo(()=>data && !data.error ? data : [],[data]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [exportMenuOpen, setExportMenuOpen] = useState(null);
  const [forceOpen, setForceOpen] = useState(false);
  useEffect(() => {
    const callback = (event) => {
      // event.metaKey - pressed Command key on Macs
      // event.ctrlKey - pressed Control key on Linux or Windows
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'KeyN') {
        event.preventDefault();
        setForceOpen(true)
      }
      if (event.code === 'Escape') {
        setForceOpen(false)
      }
      
    };
    document.addEventListener('keydown', callback);
    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, [forceOpen]);
  
  
  const handleExportMenu = useCallback((event) => {
    setExportMenuOpen(event.currentTarget)
  },[])
  const handleExportMenuClose = useCallback(()=>{
    setExportMenuOpen(false)
  },[])
  
  const handleFileExport = useCallback((type, format) => {
    if(type && format){
      setIsDownloading(type);
      postExport(type, format, listId).then((result)=>{
        if(result.status === 200){
          const data = result.data
          const prefix = 'SendBoth_Export_';
          const fileName = prefix+type + "." + format;
          const blob = new Blob([format === 'csv' ? data : JSON.stringify(data)], { type: format === 'csv' ? 'text/csv' : 'text/json' })
          // Create an anchor element and dispatch a click event on it to trigger a download
          const a = document.createElement('a')
          a.download = fileName
          a.href = window.URL.createObjectURL(blob)
          const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
          })
          a.dispatchEvent(clickEvt)
          a.remove()
        }else{
          alert('Something went wrong, please try again.');
        }
        setIsDownloading(null);
        setExportMenuOpen(null);
      }).catch(()=>{
        alert('Something went wrong, please try again.');
        setIsDownloading(null);
        setExportMenuOpen(null);
      })
    }
  },[])
  
  const handleDeleteContactFromList = useCallback(event => {
    const confirm = window.confirm('Confirm Deletion: '+selectedContacts.length)
    if(confirm){
      deleteContactFromList(null, listId, selectedContacts).then(()=>{
        refetch();
      })
    }
  },[listId, selectedContacts, refetch]);
  
  return (
     <div className={classes.content}>
     <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={1}
        className={classes.container}
     >
            {isLoading ?
               <ProgressBar /> :
               <>
                 {tableData ? <>
                   <Grid item xs={6}>
                     <Typography variant="h6" className={classes.title}>
                       Total Contacts on List: {tableData ? tableData.length : 0}
                     </Typography>
                   </Grid>
                   <Grid item xs={2}>
                     {(forceOpen || !forceOpen) && <AddContactsToListsDialog forceOpen={forceOpen} setForceOpen={setForceOpen} refetch={refetch} size={'text'} title={'Add to List'} viewingList={listId}/>}
                   </Grid>
                   <Grid item xs={2}>
                        <Button disabled={!tableData.length} color={'default'} variant={'contained'} style={{ width:160,height:50}}
                                onClick={handleExportMenu}>
                          <SaveAlt/>
                          Export
                        </Button>
                        <Menu
                           id="export-menu"
                           anchorEl={exportMenuOpen}
                           open={Boolean(exportMenuOpen)}
                           onClose={handleExportMenuClose}
                           anchorOrigin={{ vertical: 'center', horizontal: 'left'}}
                        >
                          <MenuItem onClick={()=>handleFileExport("users_list", "csv")}>CSV</MenuItem>
                          <MenuItem onClick={()=>handleFileExport("users_list", "json")}>JSON</MenuItem>
                        </Menu>
                        {isDownloading && <DownloadProgressDialog type={isDownloading} />}
                      </Grid>
                   <Grid item xs={1}>
                        <Button onClick={handleDeleteContactFromList} disabled={!selectedContacts.length} color={'default'} variant={'contained'} style={{ height:50}}><Delete/></Button>
                      </Grid>
                   <Grid item xs={1}>
                     <Button color={'default'} variant={'contained'} style={{ height:50}}><Settings/></Button>
                   </Grid>
                   <Grid item xs={12}>
                     <ContactTable listId={listId} data={tableData}/>
                   </Grid>
                 </>
                    :
                    <>
                      <Typography variant="h6" className={classes.noneFound}>
                        Contact List not found.
                        <AddContactListDialog forceOpen={forceOpen} setForceOpen={setForceOpen} size={'text'} title={'Add Your First List'}/>
                      </Typography>
                    </>
                 }
                 </>
            }
            </Grid>
     </div>
  );
}

const SpecificListWrapper = () => {
  return (
     <ContactsContextProvider>
       <SpecificList/>
     </ContactsContextProvider>
  )
}

export default SpecificListWrapper;
