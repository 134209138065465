import React, { useState, useCallback, useEffect, useContext } from 'react';

import {
  Button, FormControl,
  Grid,
  IconButton,
  InputBase, InputLabel, Menu, MenuItem,
  Paper, Select,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { FilterList, KeyboardArrowDown, Person, SaveAlt, Search } from '@material-ui/icons';
import { toCapitalize } from '../../../lib/helpers/helpers';
import useContactLists from '../../../lib/hooks/useContactLists';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { API_HOST } from '../../../lib/constants/constants';
import { PartnerContext } from '../../../lib/contexts';
import { postExport } from '../../../lib/api';
import { DownloadProgressDialog } from '../../../components/DownloadProgressDialog';
import { AddContactListDialog } from '../../../components/AddContactListDialog';
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    margin:'0px auto 0px',
    '@media print':{
      display:'none',
    }
  },
  card:{
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    maxWidth: 300,
    marginBottom:'25px',
  },
  inputBase: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: theme.spacing(100)
  },
  iconButton:{
    padding: 10
  },
  iconAdjust:{
     marginBottom:-3,
     marginLeft:7,
     marginRight:10,
  },
  dropdown:{
    minWidth: 200,
    top:-5
  },
  exportButton:{
    width: 100,
    height: 48,
    '& span':{
      marginBottom:-2,
    },
    '& svg':{
      marginBottom:5,
      marginLeft:0,
      marginRight:5,
    }
  }
}));

const PatientSearchBar = ({
                            patientStringFilter,
                            handlePatientStringFilter,
                            handlePatientListFilter,
}) => {
  
  const classes = useStyles();
  let { listId } = useParams();
  let navigate = useNavigate();
  const {isLoading, isRefetching, data, refetch: refetchInternal } = useContactLists();
  //const {isLoading, isRefetching, data, refetch } = useCoaches();
  const patientLists = data ?? []
  
  const [exportMenuOpen, setExportMenuOpen] = useState(null)
  const [isDownloading, setIsDownloading] = useState(null)
  
  const [patientListFilter, setPatientListFilter] = useState(listId)
  const [coachFilter, setCoachFilter] = useState('')
  const [resultsSorting, setResultsSorting] = useState('desc')
  const [coachList, setCoachList] = useState([])
  const [newListDialog, setNewListDialog] = useState(false)
  
  const handlePatientListFilterInternal = useCallback((event) => {
    const val = event.target.value
    setPatientListFilter(val)
    if(patientLists && val){
      let chosenList = patientLists.filter(list=>list.id == val)[0];
      handlePatientListFilter(val === 'all' ? 'all' : chosenList['patient_ids'])
    //update url
    navigate(val === 'all' ? "../patients/" : "../patients/list/" + chosenList.id, {replace: true});
    }
  },[patientLists])
  
  useEffect(()=>{
    if(listId && patientLists.length){
      let chosenList = patientLists.filter(list=>list.id == listId)[0];
      handlePatientListFilter(chosenList['patient_ids']);
    }
  },[patientLists, listId])
  
  const handleExportMenu = useCallback((event) => {
    setExportMenuOpen(event.currentTarget)
  },[])
  
  const handleExportMenuClose = useCallback((event) => {
    setExportMenuOpen(null)
  },[])

  const handleFileExport = useCallback((type, format) => {
    if(type && format){
      setIsDownloading(type);
      postExport(type, format).then((result)=>{
        if(result.status === 200){
          const data = result.data
          const fileName = type + "." + format;
  
          const blob = new Blob([format === 'csv' ? data : JSON.stringify(data)], { type: format === 'csv' ? 'text/csv' : 'text/json' })
          // Create an anchor element and dispatch a click event on it to trigger a download
          const a = document.createElement('a')
          a.download = fileName
          a.href = window.URL.createObjectURL(blob)
          const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
          })
          a.dispatchEvent(clickEvt)
          a.remove()
          
          /*
          anchor technique
          var element = document.createElement('a');
          element.setAttribute('href', 'data:text/plain;charset=utf-8,' + (format === 'csv' ? text : JSON.stringify(text)));
          element.setAttribute('download', filename);
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
          */
          
        }else{
          alert('Something went wrong, please try again.');
        }
        
        setIsDownloading(null);
        setExportMenuOpen(null);
      }).catch(()=>{
        alert('Something went wrong, please try again.');
        setIsDownloading(null);
        setExportMenuOpen(null);
      })
    }
  },[])

  return (
          <Grid
            container
            alignItems="center"
            spacing={0}
            className={classes.screen}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
            >
              <Paper className={classes.card}>
                <IconButton type="submit" className={classes.iconButton} aria-label="search">
                  <Search />
                </IconButton>
                <InputBase
                  value={patientStringFilter}
                  onChange={handlePatientStringFilter}
                  onBlur={handlePatientStringFilter}
                  className={classes.inputBase}
                  placeholder="Search Patients"
                  inputProps={{ 'aria-label': 'search patients' }}
                />
              </Paper>
  
              <Paper className={classes.card}
                     style={{display:'none'}}>
                <Person/>
                <FormControl className={classes.dropdown}>
                  <InputLabel id="coach-list-filter-label">Filter by Coach</InputLabel>
                  <Select
                    labelId="coach-list-filter-label"
                    id="coach-list-filter"
                    value={coachFilter}
                    onChange={setCoachFilter}
                  >
                    {coachList && coachList.map((list, index) => {
                      return (
                        <MenuItem key={'coachList' + list.id} value={list.id}>{toCapitalize(list.list_name)}</MenuItem>
                      )
                    })
                    }
                  </Select>
                </FormControl>
              </Paper>
              <Grid item xs={4}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={0}
                >
                  <Paper className={classes.card}>
                    <Button variant={'text'}
                            className={classes.exportButton}
                            onClick={handleExportMenu}>
                      <SaveAlt/>
                      Export
                    </Button>
                    <Menu
                      id="export-menu"
                      anchorEl={exportMenuOpen}
                      open={Boolean(exportMenuOpen)}
                      onClose={handleExportMenuClose}
                      anchorOrigin={{ vertical: 'center', horizontal: 'left'}}
                    >
                      <MenuItem onClick={handleExportMenuClose} disabled={true}>Export Current List</MenuItem>
                      <MenuItem onClick={()=>handleFileExport("users", "csv")}>Export All Users [CSV]</MenuItem>
                      <MenuItem onClick={()=>handleFileExport("users", "json")}>Export All Users [JSON]</MenuItem>
                      <MenuItem onClick={()=>handleFileExport("workflows", "csv")}>Export All Workflows [CSV]</MenuItem>
                      <MenuItem onClick={()=>handleFileExport("workflows", "json")}>Export All Workflows [JSON]</MenuItem>
                    </Menu>
                    {isDownloading && <DownloadProgressDialog type={isDownloading} />}
                  </Paper>
                  
              <Paper className={classes.card}>
                <FilterList className={classes.iconAdjust}/>
                <FormControl className={classes.dropdown}>
                  <InputLabel id="patient-list-filter-label">Filter by Patient List</InputLabel>
                  <Select
                    labelId="patient-list-filter-label"
                    id="patient-list-filter"
                    value={patientListFilter}
                    onChange={handlePatientListFilterInternal}
                    variant={'standard'}
                    disableUnderline={true}
                  >
                    <MenuItem value={'all'}>All Patients</MenuItem>
                    {patientLists && patientLists.map((list) => {
                      return (
                        <MenuItem key={'patientLists' + list.id} value={list.id}>{toCapitalize(list.list_name)}</MenuItem>
                      )
                    })
                    }
                    <MenuItem onClick={()=>setNewListDialog(true)}>Create New List</MenuItem>
                  </Select>
                </FormControl>
                {newListDialog && <AddContactListDialog invisible={true} refetch={refetchInternal} />}
              </Paper>
            </Grid>
            </Grid>
            </Grid>
            
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
              style={{display:'none'}}
            >
              
              <Paper className={classes.search}>
                <FormControl className={classes.dropdown}>
                  <InputLabel id="results-list-filter-label">Sort by</InputLabel>
                  <Select
                    labelId="results-list-filter-label"
                    id="results-list-filter"
                    value={resultsSorting}
                    onChange={setResultsSorting}
                  >
                    <MenuItem value={'desc'}>Latest</MenuItem>
                    <MenuItem value={'asc'}>Earliest</MenuItem>
                  </Select>
                </FormControl>
              </Paper>
  
              <Grid item xs={3}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={0}
                >
                <Paper className={classes.search}>
                  <Button variant={'outlined'}>Export</Button>
                </Paper>
  
                <Paper className={classes.search}>
                  <FilterList/>
                  <Button variant={'outlined'}>Columns</Button>
                </Paper>
              </Grid>
              </Grid>
              
            </Grid>
          </Grid>
  );
};

export default PatientSearchBar;
