import React, { Component } from 'react';
import { Grid, List, ListItem, FormHelperText, Typography, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import { AddContactListDialog } from '../AddContactListDialog';
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    margin:'auto auto -10px',
    paddingBottom:20
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    '& ul':{
      width:'100%',
    },
    '& li':{
      textAlign: 'center',
      display: 'block'
    },
  },
  patientLists:{
    marginTop:20,
  },
  title:{
    marginTop:20,
    textAlign: 'center',
    display: 'block'
  }
}));
const FirstList = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
    return (
      <>
        <Typography className={classes.title}>Lists are perfect for organizing your Contacts. Let&apos;s make your first one now.</Typography>
        <AddContactListDialog size={'small'}/>
        <FormHelperText style={{textAlign:'center'}}>Optional</FormHelperText>
      </>
    );
}

export default FirstList;
