import React, { useCallback, useContext, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Grid } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Typography,
  IconButton,
  FormControl,
  TextField,
  InputAdornment,
  withStyles,
} from '@material-ui/core';
import { Share, FileCopy, Create } from '@material-ui/icons';
import { CampaignContext, UserContext } from '../../../lib/contexts';
import useTemplates from '../../../lib/hooks/useTemplates';
import {TemplateListing} from '../../TemplateListing/';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.admin.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.admin.main,
    color: theme.palette.admin.light,
    width:214,
    height:50,
    padding:0,
    '&:hover':{
      backgroundColor: theme.palette.admin.dark,
      color: theme.palette.admin.light,
    }
  },
  progressBar:{
    display: 'block',
    margin:'50px auto 50px',
    backgroundColor: theme.palette.admin.light
  },
  progressBarColor:{
    backgroundColor: theme.palette.admin.main
  },
  shareSwitch:{
    color: theme.palette.admin.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.admin.main,
  },
  imageList:{
    height:230,
    overflowX: 'auto',
    overflowY: 'none'
  }
}));

const AdminGreenTextField = withStyles(theme =>({
  root: {
    borderColor: theme.palette.admin.main,
    color: theme.palette.admin.main,
    '&:hover': {
      borderColor: theme.palette.admin.main,
      color: theme.palette.admin.main,
    },
  },
}))(TextField);

const CampaignSettings = ({ api }) => {
  const classes = useStyles();
  
  const {
    state: { user_id },
  } = useContext(UserContext)
  const { isLoading, data:templateDataRaw, refetch:templateRefetch } = useTemplates();
  const templateData = useMemo(()=>templateDataRaw && !templateDataRaw.error ? templateDataRaw : [],[templateDataRaw]);
  const userTemplates = templateData ? templateData.filter(item=>item.creator === user_id && !item.public) : [];
  const premiumTemplates = templateData ? templateData.filter(item=>!item.basic && item.public).sort((a, b) => {
    return a.name < b.name
  }) : [];
  const basicTemplates = templateData ? templateData.filter(item=>item.basic && item.public).sort((a, b) => {
    return a.name > b.name
  }) : [];
  
  const {
    state: {
      selectedCampaigns,
      saving,
      campaignType,
       campaignTemplate,
      step,
    },
    actions: {
      setCampaignType,
      setStep,
       setCampaignTemplate
    },
  } = useContext(CampaignContext)
  
  const handleInputChange = useCallback((event) => {
    //setName(event.target.value);
  },[]);
  
  
  
  return (
    <>
      <Grid
         container
         direction="row"
         justifyContent="space-evenly"
         alignItems="center"
         spacing={0}
         className={classes.container}
      >
        {userTemplates && <>
          <Typography color={'primary'}>
            Your Templates
          </Typography>
          <Grid
             container
             direction="row"
             alignItems="center"
             justifyContent="space-between"
             spacing={0}
             className={classes.imageList}>
            {userTemplates.map((item, index)=>{
              return <TemplateListing mode={'choosing'} item={item} key={index} campaignTemplate={campaignTemplate} onClick={()=>setCampaignTemplate(item)}/>
            })}
          </Grid>
        </>}
        {premiumTemplates && <>
          <Typography color={'primary'}>
            Premium Templates
          </Typography>
          <Grid
             container
             direction="row"
             alignItems="center"
             justifyContent="space-between"
             spacing={0}
             className={classes.imageList}>
            {premiumTemplates.map((item, index)=>{
              return <TemplateListing mode={'choosing'} item={item} key={index} campaignTemplate={campaignTemplate} onClick={()=>setCampaignTemplate(item)}/>
            })}
          </Grid>
        </>}
        {basicTemplates && <>
          <Typography color={'primary'}>
            Basic Templates
          </Typography>
          <Grid
             container
             direction="row"
             alignItems="center"
             justifyContent="space-between"
             spacing={0}
             className={classes.imageList}>
            {basicTemplates.map((item, index)=>{
              return <TemplateListing mode={'choosing'} item={item} key={index} campaignTemplate={campaignTemplate} onClick={()=>setCampaignTemplate(item)}/>
            })}
          </Grid>
        </>}
      </Grid>
    </>
  );
}
export default CampaignSettings;
