import React, { useContext, useMemo, useState } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid, Paper, Typography,
} from '@material-ui/core';
import { PatientTabsContext } from '../../../lib/contexts';
import useMetric from '../../../lib/hooks/useMetric';
import { ProgressBar } from '../../../components/ProgressBar';
import { AdminChip } from '../../../components/AdminChip';
import { Add } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import AddContactsToListsDialog from '../../../components/AddContactsToListsDialog/AddContactsToListsDialog';

const useStyles = makeStyles(theme => ({
  patientChartCard: theme.globalClasses.patientChartCard,
  container:{
    maxHeight: 120,
    overflowY: 'scroll'
  },
  topBar:{
    marginBottom:10
  },
  addList:{
    fontSize:18,
    marginBottom:-5,
    fontWeight:'bold'
  }
}));

const PatientListsBox = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    state: { currentTab },
    actions: { goListTab },
  } = useContext(PatientTabsContext);
  const {isLoading, data, refetch } = useMetric('patient_lists', currentTab.id);
  const [addingPatient, setAddingPatient] = useState(false)
  return (
    <Grid item xs={12}>
      <Paper className={classes.patientChartCard}>
        <Grid item container xs={12} className={classes.topBar}>
          <Grid item xs={10}>
            <Typography variant={'h2'}>On Following Lists</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant={'h3'}>View All</Typography>
          </Grid>
        </Grid>
        {isLoading ?
          <ProgressBar /> :
          <Box className={classes.container}>
            {data && data.metric && data.metric.value.map((item, index)=>{
              return (
                <AdminChip key={index} label={item.list_name} clickable onClick={()=>goListTab(item.id)} />
              )
            })}
            <AdminChip label={<><Add className={classes.addList}/>&nbsp;Add</>} clickable onClick={()=>setAddingPatient(true)} />
          </Box>
        }
        <AddContactsToListsDialog open={addingPatient} patient={currentTab} handleClose={()=>setAddingPatient(false)} refetch={refetch}/>
      </Paper>
    </Grid>
  );
};

export default PatientListsBox;
