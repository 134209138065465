import { loadStripe } from '@stripe/stripe-js'
import { postBillingSubscription } from '../../../lib/api';
//import config from 'constants/config'

let stripe

export const stripePromise = loadStripe('pk_test_vTuKF3VD6cd2mR9iiy3Fw5xE00ZCVtzYVp')

export const setStripe = s => {
  stripe = s
}

const handleCustomerActionRequired = async ({ subscription, invoice, priceId, paymentMethodId, isRetry }) => {
  if (subscription && subscription.status === 'active') {
    // subscription is active, no customer actions required.
    return { subscription, priceId, paymentMethodId }
  }

  // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
  // If it's a retry, the payment intent will be on the invoice itself.
  let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent

  if (
    paymentIntent.status === 'requires_action' ||
    (isRetry === true && paymentIntent.status === 'requires_payment_method')
  ) {
    const result = await stripe.confirmCardPayment(paymentIntent.client_secret, { payment_method: paymentMethodId })

    if (result.error) {
      // start code flow to handle updating the payment details
      // Display error message in your UI.
      // The card was declined (i.e. insufficient funds, card has expired, etc)
      throw result
    }

    if (result.paymentIntent.status === 'succeeded') {
      // There's a risk of the customer closing the window before callback
      // execution. To handle this case, set up a webhook endpoint and
      // listen to invoice.payment_succeeded. This webhook endpoint
      // returns an Invoice.
      return {
        priceId: priceId,
        subscription: subscription,
        invoice: invoice,
        paymentMethodId: paymentMethodId
      }
    }
  }

  // No customer action needed
  return { subscription, priceId, paymentMethodId }
}

const handlePaymentMethodRequired = ({ subscription, paymentMethodId, priceId }) => {
  // subscription is active, no customer actions required.
  if (subscription.status === 'active') {
    return { subscription, priceId, paymentMethodId }
  }

  if (subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
    // Using localStorage to store the state of the retry here
    // (feel free to replace with what you prefer)
    // Store the latest invoice ID and status
    localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id)
    localStorage.setItem('latestInvoicePaymentIntentStatus', subscription.latest_invoice.payment_intent.status)
    throw { error: { message: 'Your card was declined. Please try a different card.' } }
  }

  return { subscription, priceId, paymentMethodId }
}

export const createSubscription = async ({ paymentMethodId, priceId, name, email, isIncompleteExpired }) => {
  try {
    let { result } = postBillingSubscription({
      payment_method_id: paymentMethodId,
      price_id: priceId,
      is_incomplete_expired: isIncompleteExpired,
      name,
      email
    })

    // If the card is declined, display an error to the user.
    if (result.error) {
      // The card had an error when trying to attach it to a customer
      throw result
    }

    // Normalize the result to contain the object returned
    // by Stripe. Add the addional details we need.
    result = {
      subscription: result.stripe_subscription,
      paymentMethodId,
      priceId
    }

    // Some payment methods require a customer to do additional
    // authentication with their financial institution.
    // Eg: 2FA for cards.
    result = await handleCustomerActionRequired(result)

    // If attaching this card to a Customer object succeeds,
    // but attempts to charge the customer fail. You will
    // get a requires_payment_method error.
    result = handlePaymentMethodRequired(result)

    return { success: true, result }
  } catch (e) {
    return { success: false, message: e.error.message }
  }
}
