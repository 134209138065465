import React, { useCallback, useContext, useMemo, useState } from 'react';

import {
  Button, IconButton, Menu, MenuItem, TableCell, TableRow, Tooltip, Typography,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { prettyDate } from '../../../lib/helpers/helpers';
import { getGoals } from '../../../lib/helpers/dietIdHelpers';
import { MoreHoriz } from '@material-ui/icons';
import { PatientTabsContext } from '../../../lib/contexts';
import AddContactsToListsDialog from '../../../components/AddContactsToListsDialog/AddContactsToListsDialog';

const useStyles = makeStyles(theme => ({
  viewReportButton:{
    borderRadius: 25,
    minWidth: 80
  },
  tableRow: theme.globalClasses.adminTableRowWhite
}));

const PatientTableRow = ({row, handleClick, selected, id}) => {
  const classes = useStyles();
  
  const {
    actions: { openPatientTab },
  } = useContext(PatientTabsContext);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [addingPatient, setAddingPatient] = useState(false);
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const isItemSelected = isSelected(row.id);
  
  const toggleMenu = useCallback((event) => {
    setMenuAnchor(menuAnchor ? null : event.currentTarget);
  },[menuAnchor])
  
  const handleClose = useCallback(() => {
    setAddingPatient(false);
  },[])
  
  const modifiedWorkflowURL = useMemo(()=>{
    if(row.workflowShareUrl){
      const url = row.workflowShareUrl;
      return url.slice(0, url.indexOf('&aggregate=1')) + url.slice(url.indexOf('#'));
    }
  },[row.workflowShareUrl]);
  
  return (
      <TableRow
        className={classes.tableRow}
        hover
        onClick={(event) => handleClick(event, row.id)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        id={id}
        key={id}
        selected={isItemSelected}
      >
        {/*
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      */}
        <TableCell
          component="th"
          id={'cell' + id} align="center"
        >
          <Button className={classes.viewReportButton} disabled={!row} variant={'outlined'} onClick={() => openPatientTab(row)}>{row ? 'View' : 'N/A'}</Button>
        </TableCell>
        <TableCell align="center">{(row.firstName || row.lastName) ? row.firstName + ' ' + row.lastName : row.accessCode}</TableCell>
        <TableCell align="center">{row && row.lastWorkflow ? prettyDate(row.lastWorkflow.created_at) : 'N/A'}</TableCell>
        <TableCell align="center">{row.email}</TableCell>
        <TableCell align="center">{row.lastType ? row.lastType + " / " + row.lastQuality : 'N/A'}</TableCell>
        <TableCell align="center">{row.preAndPost ? row.preAndPost.postQualityChange : 'N/A'}</TableCell>
        <TableCell align="center" style={{maxWidth:300}}>
              {row.lastWorkflow && row.lastWorkflow.raw_responses && row.lastWorkflow.raw_responses.ideal_goals && getGoals(row.lastWorkflow.raw_responses.ideal_goals)}
          </TableCell>
        <TableCell align="center"><IconButton onClick={toggleMenu}><MoreHoriz/></IconButton></TableCell>
  
        <Menu
          id={'menu_' + id}
          key={'menu_' + id}
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={toggleMenu}
        >
          <MenuItem disabled={!row.workflowShareUrl} onClick={() => window.open(modifiedWorkflowURL,'_blank')}>View Shared Report</MenuItem>
          <MenuItem onClick={()=>setAddingPatient(true)}>Manage Patient Lists</MenuItem>
        </Menu>
        <AddContactsToListsDialog open={addingPatient} patient={row} handleClose={handleClose}/>
      </TableRow>
  );
};

export default PatientTableRow;
