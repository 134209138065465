import React, {useContext, useState} from 'react';
import {  Grid, Typography } from "@material-ui/core";
import { Screen } from '../../components';
import { DateRangeFilter, CampaignMetricCards, TotalOpensClicksByCampaign, CampaignTableBreakdown, OpensByDay, PerformanceByHour } from './components/index';
import { makeStyles } from "@material-ui/core/styles";
import Chart from "react-apexcharts";
import { UserContext } from '../../lib/contexts';
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginTop:40,
      marginBottom:40,
      color: theme.palette.primary.main,
      display: 'block'
    },
  },
}));

const ReportsScreen = () => {
  const classes = useStyles();
  
  return (
    <Screen title={'Campaign Reports | SendBoth'} showTopNav={true} showFooter={true}>
          <Grid
            container
            alignItems="center"
            spacing={0}
            className={classes.screen}
          >
            <Grid item xs={8}>
              <Typography variant={'subtitle1'}>
                Campaign Reports
              </Typography>
            </Grid>
            <Grid item xs={4}>
               <DateRangeFilter />
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              className={classes.container}
            >
               <CampaignMetricCards/>
               <TotalOpensClicksByCampaign/>
              <CampaignTableBreakdown/>
              <Grid
                container
                item
                direction="row"
                justifyContent="soace-between"
                alignItems="center"
                spacing={1}
                xs={12}
              >
                 <OpensByDay/>
                 <PerformanceByHour/>
            </Grid>
            </Grid>
          </Grid>
    </Screen>
  );
};
export default ReportsScreen;
