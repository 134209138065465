import React, { useContext, useEffect, useState } from 'react';

import { Box, Grid, Tab, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { PartnerContext, PatientTabsContext } from '../../../lib/contexts';
import {
  PatientAddNotesBox,
  PatientAdminBox, PatientAlertBox,
  PatientBioBox, PatientCheckInHistoryBox, PatientDietQualityTrendBox,
  PatientGoalsBox, PatientLatestAssessmentsBox,
  PatientListsBox,
  PatientPrioritiesBox, PatientTipOfDay,
} from './index';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import PatientLatestResults from './PatientLatestResults';
import { getClientHost } from '../../../lib/constants/constants';
import PatientNotesBox from './PatientNotesBox';
const useStyles = makeStyles(theme => ({
  screen:{
    width:'96%',
    margin:'10px auto auto',
    flexGrow:1,
  },
  screenFull:{
    width:'99%',
    margin:'10px auto 10px',
    flexGrow:1,
  },
  root:{
    flexGrow:1,
    backgroundColor: theme.palette.admin.activeTab,
    marginTop: -50,
    marginLeft: -50,
    marginRight: -50,
    padding: theme.spacing(2)
  },
  tabPanel:{
    margin:'25px 0px 0px',
    padding:0,
    width: '100%'
  },
  tabRoot:{
    color: theme.palette.admin.dark,
    borderColor: theme.palette.admin.dark,
  },
  TabTextColor:{
    color: theme.palette.admin.main,
    borderColor: theme.palette.admin.main,
  }
}));

const PatientTabChart = () => {
  const classes = useStyles();
  const [subtab, setSetSubtab] = useState('0');
  
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.up('sm'));
  const matchMD = useMediaQuery(theme.breakpoints.up('md'));
  const matchLG = useMediaQuery(theme.breakpoints.up('lg'));
  const matchXL = useMediaQuery(theme.breakpoints.up('xl'));
  
  
  
  const a11yProps = (index) => {
    return {
      value: String(index),
      id: `scrollable-tab-${index}`,
      'aria-controls': `scrollable-tabpanel-${index}`,
    };
  }
  return (
    <Box
      className={classes.root}>
          <Grid
            container
            className={matchXL ? classes.screen : classes.screenFull}
            spacing={matchXL || matchLG ? 0 : 2}
          >
          <Grid
            container
            item
            xs={matchXL || matchLG ? 3 : 12}
            spacing={matchXL || matchLG ? 0 : 1}
          >
            <PatientBioBox/>
          </Grid>
          <Grid
            container
            item
            xs={matchXL || matchLG ? 9 : 12}
            spacing={matchXL ? 2 : 1}
          >
            <PatientAlertBox/>
            <Grid
              container
              item
              xs={12}
              justifyContent={'space-around'}
            >
              <Grid
                container
                item
                xs={matchXL || matchLG ? 8 : 12}
                spacing={matchXL ? 2 : 1}
              >
              <PatientPrioritiesBox/>
              <PatientGoalsBox/>
            </Grid>
              <Grid
                container
                item
                xs={matchXL || matchLG ? 4 : 12}
                spacing={matchXL || matchLG ? 2 : 1}
              >
                <PatientAddNotesBox/>
                <PatientListsBox/>
                <PatientAdminBox/>
              </Grid>
            </Grid>
          </Grid>
          </Grid>
      
          <Grid
            container
            alignItems="center"
            spacing={0}
            className={classes.screen}
          >
            <TabContext value={subtab}>
              <TabList
                value={subtab}
                onChange={(event, value)=>setSetSubtab(value)}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="patient sub tabs"
              >
                <Tab label="Summary" {...a11yProps(0)} classes={{root:classes.tabRoot, textColorPrimary: classes.TabTextColor}} />
                <Tab label="Latest Assessments" {...a11yProps(1)} />
                <Tab label='Check-In History' {...a11yProps(2)} />
                <Tab label="Notes" {...a11yProps(3)} />
              </TabList>
  
              <Grid
                container
                item
                xs={12}
              >
              <TabPanel value={'0'} className={classes.tabPanel}>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent={'space-between'}
                  spacing={1}
                >
                <PatientDietQualityTrendBox/>
                <PatientLatestAssessmentsBox summary={true} setSetSubtab={setSetSubtab}/>
                </Grid>
              </TabPanel>
            <TabPanel value={'1'} className={classes.tabPanel}>
              <Grid
                item
                xs={12}
              >
              <PatientLatestAssessmentsBox summary={false}/>
              </Grid>
            </TabPanel>
            <TabPanel value={'2'} className={classes.tabPanel}>
              <PatientCheckInHistoryBox/>
            </TabPanel>
            <TabPanel value={'3'} className={classes.tabPanel}>
              <PatientNotesBox/>
            </TabPanel>

              </Grid>
            </TabContext>
          </Grid>
    </Box>
  );
};

export default PatientTabChart;
