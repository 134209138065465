import React, { useContext, useMemo, useState } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar, Box, Button,
  Grid, Paper, Tooltip, Typography, useMediaQuery, useTheme,
} from '@material-ui/core';
import { PatientTabsContext } from '../../../lib/contexts';
import { ProgressBar } from '../../../components/ProgressBar';
import { Add, Check, Message, Person, Share } from '@material-ui/icons';
import {
  cmToInFt,
  kgToLbs,
  prettyDateLong,
  toCapitalize,
  toCapitalizeFromUnderScore,
} from '../../../lib/helpers/helpers';
import { AdminButton, ShareLinkDialog } from '../../../components';
import AddContactsToListsDialog from '../../../components/AddContactsToListsDialog/AddContactsToListsDialog';
import useMetric from '../../../lib/hooks/useMetric';

const useStyles = makeStyles(theme => ({
  patientChartCard: theme.globalClasses.patientChartCard,
  avatar:{
    height:75,
    width:75,
    margin:'0px auto 10px'
  },
  bottomGroup:{
    width:'100%',
    '& span':{
      display:'block',
    },
    '& div':{
      backgroundColor:theme.palette.admin.main,
      width:'33.333%',
      borderBottomLeftRadius:5,
      borderBottomRightRadius:5,
    },
    '& div:nth-of-type(1)':{
      borderBottomRightRadius:0,
    },
    '& div:nth-of-type(2)':{
      width:'31.333%',
      marginLeft:'1%',
      marginRight:'1%',
      borderBottomLeftRadius:0,
      borderBottomRightRadius:0,
    },
    '& div:nth-of-type(3)':{
      borderBottomLeftRadius:0,
    },
    '& button':{
      fontSize:11,
      height:100,
      width:'100%',
      fontWeight:'bold',
      textAlign: 'center',
      fill:theme.palette.admin.light,
      color:theme.palette.admin.light,
      backgroundColor:theme.palette.admin.main,
      '& svg':{
        display:'block',
        margin:'0px auto 10px',
      }
    }
  },
  bottomGroupDisabled:{
  cursor: 'default',
  backgroundColor:theme.palette.common.grey[600] +' !important',
},
  rowTop:{
    textAlign: 'center',
  },
  row:{
    textAlign: 'center',
    borderWidth:0,
    borderBottomWidth:2,
    borderColor:theme.palette.admin.background,
    borderStyle:'solid',
    marginBottom:20,
    '&:last-of-type':{
      borderBottomWidth:0,
    },
    '& h6':{
      marginBottom:5,
      fontSize:10,
      textTransform:'uppercase'
    },
    '& h3':{
      marginBottom:20,
      fontSize:14,
    },
    '& h5':{
      marginTop:-20,
      fontSize:12,
    },
  },
  longDate:{
    marginTop:20,
    fontSize:12,
  },
  sideBorder:{
    borderWidth:0,
    borderRightWidth:2,
    borderColor:theme.palette.admin.background,
    borderStyle:'solid',
  }
}));

const PatientBioBox = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.up('sm'));
  const matchMD = useMediaQuery(theme.breakpoints.up('md'));
  const matchLG = useMediaQuery(theme.breakpoints.up('lg'));
  const matchXL = useMediaQuery(theme.breakpoints.up('xl'));
  const {
    state: { currentTab },
  } = useContext(PatientTabsContext);
  const [showShareLink, setShowShareLink] = useState(false);
  const [addingList, setAddingList] = useState(false);
  
  let {isLoading, data } = useMetric('bio', currentTab.id);
  data = data ? data.metric.value : data;
  
  const modifiedWorkflowURL = useMemo(()=>{
    if(currentTab.workflowShareUrl){
      const url = currentTab.workflowShareUrl;
      return url.slice(0, url.indexOf('&aggregate=1')) + url.slice(url.indexOf('#'));
    }
  },[currentTab.workflowShareUrl]);
  
  return (
    <Grid item
          xs={matchXL || matchLG ? 11 : 12}>
    <Paper>
    <Box className={classes.patientChartCard}>
          <Grid style={{width:'100%', display:'block'}}
            item
            xs={12}
            container
            justifyContent={'space-around'}>
        {isLoading ?
          <Grid item xs={12} container style={{margin:'260px auto 260px', width:'100%', display:'block'}}>
          <ProgressBar />
          </Grid>
          :
          <>
          <Grid xs={12} container item className={classes.rowTop}>
            <Grid item xs={12}>
              <Avatar className={classes.avatar}>{(currentTab.firstName && currentTab.lastName) ? currentTab.firstName[0] + currentTab.lastName[0] : <Person/>}</Avatar>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h1'}>{(currentTab.firstName && currentTab.lastName) ? currentTab.firstName + ' ' + currentTab.lastName : ""}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Tooltip title={currentTab.username ?? ""}>
                <Typography variant={'body1'} style={{textAlign:'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{currentTab.email ?? ""}</Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <AdminButton style={{margin:'10px auto 30px'}} variant={'outlined'} onClick={()=>setShowShareLink(true)}><Share/>&nbsp;Copy Link</AdminButton>
            </Grid>
          </Grid>
            
            <Grid xs={12} item
                  container
                  className={classes.row} justifyContent={'space-around'} alignItems={'center'}>
              <Grid item
                    xs={6}
                    className={classes.sideBorder}>
                <Typography variant={'subtitle2'}>Last Assessment</Typography>
                <Typography variant={'h3'}
                            className={classes.longDate}>{data && data.createdAt ? prettyDateLong(data.createdAt) : "N/A"}</Typography>
              </Grid>
              <Grid item
                    xs={6}>
                <Typography variant={'subtitle2'}>Last Challenge Check-In</Typography>
                <Typography variant={'h3'}
                            className={classes.longDate}>{data && data.challengeCheckinAt ? prettyDateLong(data.challengeCheckinAt) : 'N/A'}</Typography>
              </Grid>
            </Grid>
            
            <Grid container item
                  xs={12}
                  className={classes.row}
            >
              <Grid item
                    xs={4}>
                <Typography variant={'subtitle2'}>Gender</Typography>
                <Typography variant={'h3'}>{data && data.gender ? toCapitalizeFromUnderScore(data.gender) : "N/A"}</Typography>
              </Grid>
              <Grid item
                    xs={4}>
                <Typography variant={'subtitle2'}>Height</Typography>
                <Typography variant={'h3'}>{data && data.heightCm ? cmToInFt(data.heightCm) : "N/A"}</Typography>
                <Typography variant={'h5'}>{data && data.heightCm ? data.heightCm + ' cm' : "N/A"}</Typography>
              </Grid>
              <Grid item
                    xs={4}>
                <Typography variant={'subtitle2'}>Age</Typography>
                <Typography variant={'h3'}>{data && data.age ? data.age : "N/A"}</Typography>
              </Grid>
            </Grid>
            
            <Grid container item
                  className={classes.row}>
              <Grid item
                    xs={4}>
                <Typography variant={'subtitle2'}>Latest ID</Typography>
                <Typography variant={'h3'}>{data && data.dietQuality ? data.dietQuality : "N/A"}</Typography>
              </Grid>
              <Grid item
                    xs={4}>
                <Typography variant={'subtitle2'}>Weight</Typography>
                <Typography variant={'h3'}>{data && data.weightKg ? kgToLbs(data.weightKg) + ' lbs'  : "N/A"}</Typography>
                <Typography variant={'h5'}>{data && data.weightKg ? data.weightKg + ' kgs' : "N/A"}</Typography>
              </Grid>
              <Grid item
                    xs={4}>
                <Typography variant={'subtitle2'}>BMI</Typography>
                <Typography variant={'h3'}>{data && data.bmi ? data.bmi : "N/A"}</Typography>

              </Grid>
            </Grid>
          </>}
          </Grid>
    </Box>
      {!isLoading && <Grid container className={classes.bottomGroup}>
        <Box><Button className={classes.bottomGroupDisabled} variant={'text'}><><Check />Request Assessment</>
        </Button></Box>
        <Box><Button className={classes.bottomGroupDisabled} variant={'text'}><><Message />Message</>
        </Button></Box>
        <Box><Button variant={'text'} onClick={() => setAddingList(true)}><><Add />Add to List</>
        </Button></Box>
      </Grid>}
    </Paper>
      {showShareLink && currentTab && <ShareLinkDialog link={modifiedWorkflowURL || window.location.href} handleClose={() => setShowShareLink(false)}/>}
      <AddContactsToListsDialog open={addingList} patient={currentTab} handleClose={()=>setAddingList(false)}/>

    </Grid>
  );
};

export default PatientBioBox;
