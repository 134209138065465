import React, { useContext } from 'react';
import { useParams } from 'react-router';
import {Button, Grid, Typography} from "@material-ui/core";
import { Screen } from '../../components';
import { makeStyles } from "@material-ui/core/styles";
import { ListofCampaigns, SpecificCampaign } from './components';
import {Pause} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
  },
}));

const CampaignsScreen = () => {
  const classes = useStyles();
  const {campaignId} = useParams();
  
  return (
    <Screen title={'Campaigns | SendBoth'} showTopNav={true} showFooter={true}>
          <Grid
            container
            alignItems="center"
            spacing={0}
            className={classes.screen}
          >
            {!campaignId && <Typography variant={'subtitle1'}>
              Campaign Management
            </Typography>}
  
            {!campaignId && <Grid
               container
               direction="row"
               justifyContent="space-evenly"
               alignItems="center"
               spacing={0}
               className={classes.container}
            >
              <ListofCampaigns/>
            </Grid>
            }
            {campaignId && <SpecificCampaign/>}
            
            
          </Grid>
    </Screen>
  );
};
export default CampaignsScreen;
