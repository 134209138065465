import * as React from 'react';
import PropTypes from 'prop-types';
import {
  alpha,
  Box,
  IconButton, Paper, Table, TableBody,
  TableCell, TableContainer,
  TableHead, TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { V1DataContext } from '../../../lib/contexts/V1DataContext';
import { makeStyles } from '@material-ui/core/styles';
import { ProgressBar } from '../../../components/ProgressBar';
import { PatientSearchBar } from './index';
import usePatientData from '../../../lib/hooks/usePatientData';
import { Goals } from '../../../lib/helpers/dietIdHelpers';
import PatientTableRow from './PatientTableRow';
import { useParams } from 'react-router';
import { PatientTabsContext } from '../../../lib/contexts';
import { postPatientSearch } from '../../../lib/api/postPatientSearch';

const useStyles = makeStyles(theme => ({
  progressBarContainer:{
    width: '80%',
    margin: 'auto'
  },
  viewReportButton:{
    borderRadius: 25,
    minWidth: 80
  },
  tableHead: theme.globalClasses.adminTableHead,
  tableRow: theme.globalClasses.adminTableRow,
  tableBody: {
    marginTop: 50,
  }
}));


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'workflowShareUrl',
    numeric: true,
    disablePadding: true,
    label: 'Report',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'latestAssessment',
    numeric: true,
    disablePadding: false,
    label: 'Latest Assessment',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'latestID',
    numeric: true,
    disablePadding: false,
    label: 'Latest ID',
  },
  {
    id: 'qualityChange',
    numeric: true,
    disablePadding: false,
    label: 'Quality Change',
  },
  {
    id: 'goals',
    numeric: true,
    disablePadding: false,
    label: 'Goals',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  
  return (
    <TableHead className={classes.tableHead}>
      <TableRow className={classes.tableRow}>
        {/*
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all patients',
            }}
          />
        </TableCell>*/}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 &&
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      }
      
      
      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton>
            <Delete />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const PatientTableResults = () => {
  const {
    state: { openTabs, uuid, listId, allPatientLists },
    actions: { openPatientTab },
  } = useContext(PatientTabsContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('workflowShareUrl');
  const [selected, setSelected] = useState([]);
  const [debounceQuery, setDebounceQuery] = useState("");
  
  const [patientStringFilter, setPatientStringFilter] = useState(undefined)
  const [patientListFilter, setPatientListFilter] = useState( undefined)
  const [viewing, setViewing] = useState( [] )
  const { isLoading, data } = usePatientData(page+1, rowsPerPage, debounceQuery !== undefined ? debounceQuery : '', viewing.join(","));
  const rows = !isLoading && data ? data : [];
  const handlePatientStringFilter = useCallback((event) => {
    setPatientStringFilter(event.target.value);
  },[])
  const handlePatientListFilter = useCallback((value) => setPatientListFilter(value === 'all' ? undefined : value),[])
  
  
  useEffect(() => {
    const timeOutId = setTimeout(() => {
        setDebounceQuery(patientStringFilter);
    }, 1000);
    
    return () => {
      clearTimeout(timeOutId);
    };
  }, [patientStringFilter]);
  
  useEffect(()=>{
      setViewing(uuid !== undefined ? listId !== undefined ? [uuid, ...allPatientLists] : [uuid] : listId !== undefined ? allPatientLists : [])
  },[uuid, allPatientLists, listId])
  
  useEffect(()=>{
    if(rows && uuid){
      const alreadyOpened = openTabs && openTabs.filter((val) => val.uuid === uuid).length
      if(!alreadyOpened){
        const desiredTab = rows.filter((val) => val.uuid === uuid)[0]
        openPatientTab(desiredTab)
      }
    }
  },[uuid, rows, openTabs])
  
  const filteredRows = useMemo(() => {
    if(rows){
      let tempRows = rows;
      const lowerString = patientStringFilter ? patientStringFilter.toLowerCase() : '';
      if(lowerString){
        tempRows = tempRows.filter((row) => (
            row.email && row.email.toLowerCase().includes(lowerString) ||
            row.username && row.username.toLowerCase().includes(lowerString) ||
            row.firstName && row.firstName.toLowerCase().includes(lowerString) ||
            row.lastName && row.lastName.toLowerCase().includes(lowerString) ||
            row.id && String(row.id).includes(lowerString) ||
            row.uuid && String(row.uuid).includes(lowerString) ||
            row.partnerId && String(row.partnerId).includes(lowerString)
          )
        )
      }
      if(patientListFilter){
        tempRows = tempRows.filter((row) => patientListFilter.indexOf(row.uuid) !== -1)
      }
      return tempRows
    } else{
      return []
    }
  },[rows, patientStringFilter, patientListFilter])
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  const handleSelectAllClick = (event) => {
    const {
      state: { data },
    } = useContext(V1DataContext);
    const rows = data.data;
    
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    
    setSelected(newSelected);
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 && filteredRows ? Math.max(0, (1 + page) * rowsPerPage - filteredRows.length) : 0;
  
  return (
    <>
      <PatientSearchBar
        patientStringFilter={patientStringFilter}
        handlePatientStringFilter={handlePatientStringFilter}
        handlePatientListFilter={handlePatientListFilter}
      />
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
{/*
        <EnhancedTableToolbar numSelected={selected.length} />
*/}
        <TableContainer>
          {!isLoading && <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filteredRows ? filteredRows.length : 0}
            />
            <TableBody className={classes.tableBody}>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {filteredRows.slice().sort(getComparator(order, orderBy))
                //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (<PatientTableRow key={'PatientTableRow_' + index} id={'PatientTableRow_' + index} row={row}
                                           handleClick={handleClick} selected={selected} />);
                })}
            </TableBody>
          </Table>}
        </TableContainer>
        
        {isLoading &&
        <Box
          className={classes.progressBarContainer}>
          <ProgressBar style={{margin:'250px auto 250px'}}/>
        </Box>
        }
        
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={-1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
    </>
  );
}
export default PatientTableResults;
